import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import api_url from '../../../utils/apiURL';
import { Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2';


const PeopleComponent = () => {

  let [userData, setUserData] = useState([]);
  let [unitsData, setUnitsData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [pageNum, setPageNum] = useState(2);
  let [totalPages, setTotalPages] = useState();

  // console.log("data from api :: ",userData);
  // console.log("data from units api :: ",unitsData);

  let tableData = userData.length && userData.map((val, ind) => {
   return unitsData.map((itm, i) => {
     if(val.unit === itm.id){
      return(
        <tr key={ind}>
        <td>{val.name}</td>
        <td>{val.email}</td>
        
        <td>{itm.title}</td>
        <td>
        <Link to={"/admin/people/view"} state={{ owner: val, unit: itm }}>
                <button type="button" className="btn btn-outline-info" style={{borderRadius:'5px'}}>
                    View
                </button>
            </Link>
        </td>
      </tr>
      )
     }
// else if(val.unit == 0){
//   return(
//     <tr>
//     <td>{val.name}</td>
//     <td>{val.email}</td>
//     <td>Owner</td>
//     <td>No Unit Assigned</td>
//     <td>
//     <Link to={"/admin/people/view"} state={{ owner: val, unit: itm }}>
//             <button type="button" className="btn btn-outline-info" style={{borderRadius:'5px'}}>
//                 View
//             </button>
//         </Link>
//     </td>
//   </tr>
//   )
// }
    })
  })

  let tableOne = userData.length && userData.map((val,i) => {
    if(val.role == "2" || val.role == 2){
      return (
        <tr key={i}>
          <td>{val.name}</td>
          <td>{val.email}</td>
          <td>{(val.isVerified == 1 || val.isVerified == '1') ? "Verified" : "Not Verified"}</td>
          <td>
     <Link to={"/admin/people/view"} state={{ owner: val }}>
             <button type="button" className="btn btn-outline-info" style={{borderRadius:'5px'}}>
                 View
             </button>
         </Link>
     </td>
        </tr>
      )
    }
  })

  let testTotalPage = Math.ceil(totalPages/10)


  let handleLoadMore = () => {
    setLoading(true)
    if(pageNum > testTotalPage){
      Swal.fire(
        'No Record',
        'No Record found !',
        'info'
      )
    }
    else if(pageNum <= testTotalPage){
      axios.get(api_url+`/api/admin/owner/limited?page=${pageNum}`).then(response => {
        let dataArr = response.data.data.length && response.data.data.map((val,i) => {
          return val
        })
        setUserData((values) => [...values, ...dataArr])
        setPageNum(pageNum + 1)
        setLoading(false)
      }).catch(error => {
        console.log("error :: ",error)

      })
    }
  }

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);

    axios.get(api_url+"/api/admin/owner/count").then(res => {
      setTotalPages(res.data.data[0].total)
    }).catch(err => {
      console.log("error occured in count api :: ",err);
    })

     axios.get(api_url+"/api/admin/owner/limited?page=1").then(response => {
      setUserData(response.data.data);
      axios.get(api_url+"/api/admin/units/allUnits").then((result) => {
        setUnitsData(result.data.data);
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setUnitsData({
          id: 0,
          unit_id: 0,
          owner_id: 0,
          title: 'no lot available',
          address: '',
          city: '',
          state: '',
          zipcode: '',
          statue: '' 
        })
        setLoading(false)
      })
    }).catch(error => {
      if(axios.isCancel(error)){
        console.log("Successfully aborted !!");
      }
      else{
        console.log(error);
        setUserData({
          id: 0,
          uuid: 0,
          email: '',
          name: 'no user available',
          unit: 0,
          phone: '',
          role: ''
        })
        setLoading(false)
      }
    })

    return () => {
      source.cancel()
    }

  },[])

  console.log("userData length :: ",userData )

    return(
        <div className="container-scroller">
        <Header>
            
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">
              {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <><div className="card">
                <div className="card-body">
                  <h4 className="card-title">People</h4>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Name
                          </th>
                          <th>
                            Email
                          </th>

                          <th>
                            Verified
                          </th>
                          <th>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(userData.length > 1) ? <>
                        {tableOne}</> : <tr>
                          <td>No User Found</td>
                          </tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
                      </>}
                  <br />
                  {(pageNum <= testTotalPage) ? <div className="text-center">
                    <button className="btn btn-outline-info" onClick={handleLoadMore} style={{borderRadius: "5px"}}>Load more</button>
                    </div> : null}
                  <br/>
                  </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default PeopleComponent;