import React, { useState } from 'react';
import { Button, Modal, CloseButton } from 'react-bootstrap';
import api from '../../../../utils/apiURL';
import axios from 'axios';
import Swal from 'sweetalert2';

const FilterComponent = (props) => {

  let [charge, setCharge] = useState({
    title: '',
    description: '',
    amount: ''
  })
  let [filter, setFilter] = useState({
    from: '',
    to: ''
  })

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  
  today = yyyy+"-"+mm+"-"+dd;
  console.log("date today :: ",today)

  let handleFrom = (event) => {
    console.log("value of event 'from' :: ",event.target.value)
    props.setFilter((values) => ({
      ...values,
      from: event.target.value
    }))
  }

  let handleTo = (event) => {
      console.log("value of event 'to' :: ",event.target.value)
      props.setFilter((values) => ({
        ...values,
        to: event.target.value
      }))
  }

  let handleSubmit = (event) => {
    event.preventDefault();

    props.setLoading(true);

    if(props.filter.from == ""){
      Swal.fire(
        'Enter Date',
        'Enter "From" date to continue.',
        'warning'
      )
    }
    else if(props.filter.to == ""){
      Swal.fire(
        'Enter Date',
        'Enter "To" date to continue.',
        'warning'
      )
    }
    else if(props.comp == "revenue"){
      console.log("in correct check")
      let data ={
        page: 1,
        from: props.filter.from,
        to: props.filter.to
      }
      axios.post(api+"/api/admin/invoices/from-to",data).then(response => {
        props.setInvoice(response.data.data)
        props.setPage(2)
        props.setShow(false)
        props.setLoading(false)

        let data2 = {
          from: props.filter.from,
          to: props.filter.to
        }
        axios.post(api+"/api/admin/invoices/all-paid/from-to",data2).then(res => {
          props.setPaid(res.data.data)
        }).catch(err => {
          console.log("error occured while getting paid invocies data from pivot table!! ",err);
        })

        axios.post(api+"/api/admin/invoice/all-unpaid/from-to",data2).then(resp => {
          props.setUnpaid(resp.data.data)
        }).catch(er => {
          console.log("error occured :: ",er)
        })

      }).catch(err => {
        console.log("error occured while getting invocies!! ",err)
      })
    }
    else{
      console.log("in wrong check ::")
      let data ={
        page: 1,
        from: props.filter.from,
        to: props.filter.to
      }
      axios.post(api+"/api/admin/invoices/from-to",data).then(response => {
        props.setInvoice(response.data.data)
        props.setPage(2)
        props.setShow(false)
        props.setLoading(false)
      }).catch(err => {
        console.log("error occured while getting invocies!! ",err)
      })
    }
    props.setLoading(false)
    props.setShow(false)
  }


    return(
        <Modal
      size="md"
      show={props.show}
      centered
    >
      <Modal.Header>
        
        <Modal.Title id="contained-modal-title-vcenter">
          Add Charge
        </Modal.Title>
          <CloseButton onClick={() => props.setShow(false)} />
      </Modal.Header>

      <Modal.Body>
        <div className="row">
        <div className="col-md-12">
        <form className="forms-sample">
                    <div className="form-group">
                      <label>From</label>
                      <input type="date" onChange={handleFrom} min={"2021-05-01"} max={today} className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>To</label>
                      <input type="date" onChange={handleTo} min={"2021-05-01"} max={today} className="form-control" />
                    </div>
                  </form>
        </div>
        </div>
        <br />
      
      </Modal.Body>
      <Modal.Footer>
      <button type="submit" style={{borderRadius:'5px'}} onClick={handleSubmit} className=" btn btn-outline-info">Show</button>
        <button style={{borderRadius:'5px'}} className=" btn btn-secondary" onClick={() => props.setShow(false)}>Close</button>
      </Modal.Footer>
    </Modal>
    )
}

export default FilterComponent;