import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api_url from '../../../../utils/apiURL';
import Swal from 'sweetalert2';
import Select from 'react-select';
import _ from'lodash';
import Header from '../../Common/Header';
import { useNavigate, Link } from 'react-router-dom';;


const NewInvoiceComponent = () => {
  let navigate = useNavigate();

    let [charge, setCharge] = useState([]);
    let [selectCharge, setSelectedCharge] = useState([]);
    let [invoiceData, setInvoiceData] = useState({
      title: '',
      depositBank: '',
      amount_dev: 0,
      amount_undev: 0,
    });
    let [totalAmount, setTotal] = useState(0);
    let [totalAmountUndev, setTotalUndev] = useState(0);

    

    let optnsData = charge.length && charge.map((val,i) => {
      return {
        value: val.id,
        label: val.charge_title,
        amount_dev: val.amount_dev,
        amount_undev: val.amount_undev
      }
    })
    

    let handleTitle = (event) => {
      setInvoiceData((values) => ({
        ...values,
        title: event.target.value
      }))
    }

    let handleSelect = (e) => {

      let test = e.map((val,i) => {
        return val.value
      })

      let total_amount = e.map((val,i) => {
        return val.amount_dev
      })

      let total_amount_undev = e.map((itm,i) => {
        return itm.amount_undev
      })

      let total_arr = _.sum(total_amount)
      let total_arr2 = _.sum(total_amount_undev)
      setInvoiceData((values) => ({
        ...values,
        amount_dev: total_arr,
        amount_undev: total_arr2,
      }))
      setTotalUndev(total_arr2)
      setTotal(total_arr)

      console.log("value of e :: ",e)
      setSelectedCharge([...test])
    }

    let handleBank = (e) => {
      setInvoiceData((values) => ({
        ...values,
        depositBank: e.target.value
      }))
    }

    console.log("Inovice details :: ",invoiceData)

    // let handleSubmit = (event) => {
    //   event.preventDefault();
    //   let data = {
    //     title: invoiceData.title,
    //     activeDate: invoiceData.activedate,
    //     depositeBank: invoiceData.depositBank,
    //     dueDate: invoiceData.dueDate,
    //     category: invoiceData.category,
    //     charge: selectCharge,
    //     amount_dev: invoiceData.amount_dev,
    //     description: invoiceData.description
    //   }

    //   let data2 = {
    //     title: invoiceData.title,
    //     activeDate: invoiceData.activedate,
    //     depositeBank: invoiceData.depositBank,
    //     dueDate: invoiceData.dueDate,
    //     category: invoiceData.category,
    //     charge: selectCharge,
    //     amount_undev: invoiceData.amount_undev,
    //     description: invoiceData.description
    //   }

    //   console.log("unit id here :: ",data);
    //   if(invoiceData.title != ''){
    //     if(invoiceData.dueDate == '' || invoiceData.dueDate == null || invoiceData.activedate == '' || invoiceData.activedate == ''){
    //       Swal.fire(
    //         'Fields incomplete',
    //         'Due Date or Active Date not selected!',
    //         'error'
    //       )
    //     }else if(invoiceData.category == '' || invoiceData.depositBank == ''){
    //         Swal.fire(
    //           'Fields incomplete',
    //           'Select Schedule!',
    //           'error'
    //         )
    //     }
    //     else if(invoiceData.amount != ''){
    //         axios.post(api_url+"/api/admin/invoices/newInvoices", data).then((response) => {
    //           axios.post(api_url+"/api/admin/invoices/newInvoice/undev",data2).then((res) => {
    //             Swal.fire(
    //               'Success',
    //               'New Invoice Created.',
    //               'success'
    //             )
    //           navigate('/admin/invoices');
    //           setInvoiceData({
    //             title: '',
    //             status: '',
    //             activedate: '',
    //             depositBank: '',
    //             dueDate: '',
    //             category: '',
    //             amount: '',
    //             description: ''
    //           })
    //           }).catch(err => {
    //             console.log(err);
    //             Swal.fire(
    //               'Failed',
    //               'Failed to create New Invoice for undeveloped lot. Try Later !',
    //               'error'
    //             )
    //           })
    //         }).catch(error => {
    //           console.log(error)
    //           Swal.fire(
    //             'Failed',
    //             "Unable to create New Invoice. Try Later !",
    //             'error'
    //           )
    //         })
    //     }else{
    //       Swal.fire(
    //         'Invalid Data',
    //         'Add Invoice Amount!',
    //         'error'
    //       )
    //     }
        
    //   }
    // }

    let handleNewSubmit = (e) => {
      e.preventDefault();
      if(invoiceData.title == ""){
        Swal.fire(
          'Fields Incomplete',
          'Enter Invocie title to continue.',
          'warning'
        )
      }
      else if(invoiceData.depositBank == ""){
        Swal.fire(
          'Fields Incomplete',
          'Enter Deposit bank to continue!',
          'warning'
        )
      }
      else if(invoiceData.amount_dev == 0){
        Swal.fire(
          'Failed to create invoice',
          'Failed to get amount for Developed lot!',
          'warning'
        )
      }
      else if(invoiceData.amount_undev == 0){
        Swal.fire(
          'Failed to create invoice',
          'Failed to get amount for Undeveloped lot!',
          'error'
        )
      }
      else{
        let data = {
          title: invoiceData.title,
          bank: invoiceData.depositBank,
          ids: JSON.stringify(selectCharge),
          amountDev: invoiceData.amount_dev,
          amountUndev: invoiceData.amount_undev
        }
        axios.post(api_url+"/api/admin/invoices/create",data).then(response => {
          Swal.fire(
            'Success',
            'New Invoice Created',
            'success'
          )
          navigate('/admin/invoices');
        }).catch(error => {
          console.log("Error occured while creating invoice :: ",error);
          Swal.fire(
            'Error',
            'Failed to create Invoice! Try again later!',
            'error'
          )
          navigate('/admin/invoices');
        })
      }
    }


    useEffect(() => {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();

      axios.get(api_url+"/api/admin/invoices/charges").then(response => {
        setCharge(response.data.data);
      }).catch(err => {
        if(axios.Cancel(err)){
          console.log("Aborted successfully");
        }
        else{
          console.log("error occured :: ",err)
        }
      })

      return () => {
        source.cancel()
      }

    },[])
    console.log("charges in state :: ",charge)


    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
        <div className="row">
        <div className="row">

              <div className="col-md-12">
                    <div className="template-demo text-end">
                    <Link to="/admin/invoices"><button type="button" style={{borderRadius:'5px', marginRight:'15px'}} className="btn btn-outline-info">Back</button></Link>
                    </div>
            </div>

                  </div>
            <div className="col-lg-12 grid-margin">
            <div className="card">
            <div className="card-body">
                <h4 className="card-title">Create New Invoice</h4>
                <div className="col-md-12">
        <div className="row">
        <div className="col-md-6">
        <form className="forms-sample">

          {/* <div className="text-start">
            <button type="button" className="btn btn-outline-info" style={{borderRadius:'5px', marginRight:'5px'}}>Specific owners</button>
            <button type="button" className="btn btn-outline-info" style={{borderRadius:'5px', marginLeft:'5px'}}>All owners</button>
          </div> */}

                    <div className="form-group">
                      <label>Invoice Title<code>*</code></label>
                      <input required onChange={handleTitle} type="text" className="form-control" placeholder="Invoice Title" />
                    </div>
                    
                    <div className="form-group">
                      <label>Deposit Bank<code>*</code></label>
                      <input required onChange={handleBank} type="text" className="form-control" placeholder="Invoice Title" />
                    </div>

                    {/* <div className="form-group">
                      <label>Invoice Description</label>
                      <textarea onChange={handleDescription} type="text" className="form-control" placeholder="Invoice Description" />
                    </div> */}

                    {/* <div className="form-group">
                      <label>Invoice Amount for Developed Lots <code>*</code></label>
                      <input required onChange={handleAmountDev} type="number" className="form-control" placeholder="Invoice Amount for Developed Lots" />
                    </div>

                    <div className="form-group">
                      <label>Invoice Amount for Un-Developed Lots <code>*</code></label>
                      <input required onChange={handleAmountUndev} type="number" className="form-control" placeholder="Invoice Amount For Un-Developed Lots" />
                    </div> */}

                    <div className="form-group">
                      <label>Select Charge<code>*</code></label>
                      <Select options={optnsData} onChange={handleSelect} isMulti={true} placeholder="Select charge" />
                    </div>
                    <div className="form-group">
                      <label>Total Amount for Developed Lot</label>
                      <input type="number" readonly={true} disabled value={invoiceData.amount_dev} className="form-control" placeholder="Invoice Amount" />
                    </div>
                    <div className="form-group">
                      <label>Total Amount for UnDeveloped Lot</label>
                      <input type="number" readonly={true} disabled value={invoiceData.amount_undev} className="form-control" placeholder="Invoice Amount" />
                    </div>
                    
                  </form>
        </div>
        </div>
                    <button type="submit" style={{borderRadius:'5px'}} className=" btn btn-outline-info" onClick={handleNewSubmit}>Create Invoice</button>

          {/* {showModal ? <AddCharge show={showModal} setShow={setShowModal} /> : null} */}
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default NewInvoiceComponent;