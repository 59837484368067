import React, { useState } from 'react';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import logo1 from '../../../../assets/img/light1.svg';
import logomini from '../../../../admin_assets/images/logo-mini.svg';
import face1 from '../../../../admin_assets/images/faces/face28.jpg';
import { AiOutlineUser } from 'react-icons/ai';
import Sidebar from '../Sidebar';
import { FiPower } from 'react-icons/fi';

const HeaderComponent = ({children: children, ...props}) => {
  let navigate = useNavigate();
    let [showUserOptn, setShowUserOptn] = useState(false);
    const [sideMenu, setSideMenu] = useState({
      dashBoard: false,
      lots: false,
      people: false,
      invoices: false,
      payments: false
    })
    
    let showDropDown = () => {
        setShowUserOptn(!showUserOptn)
    }
    // console.log("props :: ",props);
    let handleLogout = () => {
      localStorage.setItem('isAuthenticated', false);
      localStorage.setItem('userRole', 0);
      localStorage.setItem('UserName', '');
      localStorage.setItem('Email', '');
      localStorage.setItem('UserID', '');
      navigate('/')
    }
    return(
        <React.Fragment>
            <div className="container-scroller">
            <div>
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <a className="navbar-brand brand-logo mr-5" href="#"><img src={logo1} className="mr-2" alt="logo" /></a>
            <a className="navbar-brand brand-logo-mini" href="#"><img src={logo1} alt="logo" /></a>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
              <span className="icon-menu" />
            </button>
            {/* <ul className="navbar-nav mr-lg-2">
              <li className="nav-item nav-search d-none d-lg-block">
                <div className="input-group">
                  <div className="input-group-prepend hover-cursor" id="navbar-search-icon">
                    <span className="input-group-text" id="search">
                      <BiSearch color="#8C9196" size={22} />
                    </span>
                  </div>
                  <input type="text" className="form-control" id="navbar-search-input" placeholder="Search now" aria-label="search" aria-describedby="search" />
                </div>
              </li>
            </ul> */}
            <ul className="navbar-nav navbar-nav-right">
              {/* <li className="nav-item dropdown">
                <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
                  <BsBell color="#8C9196" size={22} />
                </a>
                <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                  <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="ti-info-alt mx-0" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">Application Error</h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        Just now
                      </p>
                    </div>
                  </a>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="ti-settings mx-0" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">Settings</h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        Private message
                      </p>
                    </div>
                  </a>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-info">
                        <i className="ti-user mx-0" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <h6 className="preview-subject font-weight-normal">New user registration</h6>
                      <p className="font-weight-light small-text mb-0 text-muted">
                        2 days ago
                      </p>
                    </div>
                  </a>
                </div>
              </li> */}
              <li className={ showUserOptn ? "nav-item nav-profile dropdown show" : "nav-item nav-profile dropdown" }>
                <a className="nav-link dropdown-toggle" onClick={() => showDropDown()} data-toggle="dropdown" id="profileDropdown">
                  {/* <img onClick={() => showDropDown()} src={face1} alt="profile" /> */}
                  <div style={{border:'1px solid black', borderRadius:'50%', padding:'7px'}}>
                  <AiOutlineUser color={"#2dca98"} size={25} />
                  </div>
                </a>
                <div className={ showUserOptn ? "dropdown-menu dropdown-menu-right navbar-dropdown show" : "dropdown-menu dropdown-menu-right navbar-dropdown" } aria-labelledby="profileDropdown">
                  {/* <a className="dropdown-item" style={{color:'black'}}>
                    <IoSettingsOutline size={22} />
                    Settings
                  </a> */}
                  <a onClick={handleLogout} className="dropdown-item" style={{color: 'black'}}>
                    <FiPower size={22} />
                    Logout
                  </a>
                </div>
              </li>
            </ul>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
              <span className="icon-menu" />
            </button>
          </div>
        </nav>
      </div>
      <main>
      <div className="container-fluid page-body-wrapper">
        <Sidebar setoptn={setSideMenu} optn={sideMenu} />
        <div className="main-panel">
          {children}
        <Footer />
        </div>
      </div>
      </main>
      </div>
        </React.Fragment>
    )
}

export default HeaderComponent;