import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import api from '../../../../utils/apiURL';
import { Spinner } from 'react-bootstrap';
import EditLot from '../Edit';
import _ from 'lodash';

const ViewUnitComponent = () => {

  let userId = localStorage.getItem('UserID');

    let location = useLocation();
    let { state } = location;
    let [loading, setLoading] = useState(false);

    console.log("user id from unit : : ",state)

    let [OwnerDetails, setOwnerDetails] = useState([]);
    let [unitDetails, setUnitDetails] = useState([]);
    let [lotDetails, setLotDetails] = useState([]);
    let [show, setShow] = useState(false);
    let [chargeData, setChargeData] = useState([]);
    let [invoiceData, setInvoiceData] = useState([]);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      let ownerData = OwnerDetails.length > 0 && OwnerDetails.map((val,i) => {
        return lotDetails.length && lotDetails.map((itm,ind) => {
          if(itm.owner_id == val.id){
            return(
              <tr key={i}>
                <td>{val.name}</td>
                <td>{val.email}</td>
                <td>{(val.isVerified == 1 || val.isVerified == "1") ? "Verified" : "Not-Verified"}</td>
                <td>
                  <button disabled className={(itm.active_owner == 1 || itm.active_owner == "1") ? "btn btn-success" : "btn btn-danger"} style={{borderRadius:'5px'}}>{(itm.active_owner == 1 || itm.active_owner == "1") ? "Active" : "In-Active"}</button>
                </td>
              </tr>
            )
          }
          else{
            return null
          }
        })
      })

let tableInvoice = invoiceData.length > 0 && invoiceData.map((val,ind) => {
  let sumPending;
  let pendingDues = [];
  if(chargeData.length){
    let charge_ids = JSON.parse(val.charge_id);
    console.log("charge ids from invoice details :: ",charge_ids);
    for(let res of charge_ids){
      console.log("res in for loop :: ",res);
      let totalPending = chargeData.map((itm,i) => {
        if(res == itm.charge_id && val.id == itm.invoice_id){
          console.log("if condition here");
          return itm.balance
        }
      })
      pendingDues = [...pendingDues, ...totalPending]
    }
    sumPending = _.sum(pendingDues)

    return(
      <tr key={ind}>
        <td>{val.title}</td>
        <td>{val.deposite_bank}</td>
        <td>${sumPending}</td>
      </tr>
    )

  }
  else{
    return(
      <tr key={ind}>
        <td>{val.title}</td>
        <td>{val.deposite_bank}</td>
        <td>$0</td>
      </tr>
    )
  }
})

    let checkClick = () => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              )
            }
            else{
              Swal.fire(
                'Aborted!',
                'Action canceled',
                'success'
              )
            }
          })
    }

    console.log("state :: ",state)
    console.log("invoice details :: ",invoiceData);
    console.log("charge data :: ",chargeData);
    console.log("unit details :: ",unitDetails);

    useEffect(() => {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      setLoading(true);

      let unitData = {
        id: state.unitId
      }

      let data2 = {
        unitId: state.unitId
      }


    axios.post(api+'/api/admin/units/owners',unitData).then((response) => {
      setOwnerDetails(response.data.data.owners)
      setLotDetails(response.data.data.pivot)
      axios.post(api+"/api/admin/units/getUnit",data2).then((result) => {
        setUnitDetails(result.data.data)

        let data3 = {
          ids: [result.data.data[0].id]
        }

        axios.post(api+"/api/admin/invoices/unpaid",data3).then(row => {
          setInvoiceData(row.data.data.invoice);
          setChargeData(row.data.data.pivot);
        }).catch(eror => {
          console.log("error occured :: ",eror);
        })

        setLoading(false)
      }).catch(error => {
        console.log("error occured in unit api :: ",error)
      })
    }).catch(err => {
      console.log(err);
    })

return () => {
  source.cancel();
}

    },[show])

    return(
        <div className="container-scroller">
        <Header>

        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">

                {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
                    </div> : <div className="card-body">
                  <h4 className="card-title">Lot</h4>
                  <div className="col-md-12">
                    

                      <p className="card-description">Lot Details</p>
                      <div className="template-demo">
                          <div className="text-end">
                              <Link to="/admin/units"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} className="btn btn-outline-info">Back</button></Link>
                          </div>
                          <br/>
                          <h5>
                            Title
                        </h5>
                        <p className="card-description">{unitDetails.length ? <>{unitDetails[0].title}</> : "Unable to get Lot Details !!"}</p>
                        <h5>
                            Address
                        </h5>
                        <p className="card-description">{unitDetails.length ? <>{unitDetails[0].address+", "+unitDetails[0].city+", "+unitDetails[0].state+", "+unitDetails[0].zipcode}</> : "Unable to get Lot address !!"}</p>
                        <h5>Lot Status</h5>
                        <p>{unitDetails.length ? <>{(unitDetails[0].status == "1") ? "Developed" : "Un-Developed"}</> : "Unable to get Lot Status !!"}</p>
                        <h5>
                            Owners
                        </h5>
                        <p className="card-description">{OwnerDetails.length ? <>{OwnerDetails.length}</> : "Unable to get Owner details !!"}</p>
                      </div>
                      <h4 className="card-title">Owner Details</h4>
                      <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Name
                          </th>
                          <th>
                            Email
                          </th>
                          <th>
                            Verified
                          </th>
                          <th>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          {OwnerDetails.length ? <>{ownerData}</> : <tr>
                              <td>
                                  No Owner Assigned to this Lot.
                              </td>
                            </tr>}
                      </tbody>
                    </table>
                  </div>

<><br /><br /><h4 className="card-title">Invoice Details</h4>
<div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Title
                          </th>
                          <th>
                            Deposite Bank
                          </th>
                          <th>
                            Outstanding
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          {invoiceData.length > 0 ? <>{tableInvoice}</> : <tr>
                              <td>
                                  No Invoice availabe for this Lot.
                              </td>
                            </tr>}
                      </tbody>
                    </table>
                  </div></>

                    <br />
                  </div>
                    {show ? null : <div className="text-end">
                  <button className="btn btn-outline-info" style={{borderRadius: "5px"}} type="button" onClick={() => {setShow(!show)}}>Edit</button>
                  </div>} 
                  
                  </div>}
                  {show && <EditLot show={show} setShow={setShow} unitDetails={unitDetails} userDetails={OwnerDetails} pivot={lotDetails} /> }
                  </div>
                  </div>
              </div>
              </div>

        </Header>
        </div>
    )
}

export default ViewUnitComponent;