import React, { useState, useEffect } from 'react';
import { Modal, CloseButton, Spinner } from 'react-bootstrap';
import api_url from '../../../../utils/apiURL';
import axios from 'axios';
import Swal from 'sweetalert2';
import EditOwner from './EditUser';
import AddNewOwner from './AddOwner';

const EditLotComponent = (props) => {

    console.log("Props in edit lot component :: ",props)

    let[show, setShow] = useState(false);
    let [show2, setShow2] = useState(false);
    let[loading, setLoading] = useState(false);

  let [charge, setCharge] = useState({
    title: '',
    description: '',
    amount: ''
  })
  let [unitData, setUnitData] = useState({
      title: '',
      status: '',
  })
  let [ownerDetails, setOwnerDetails] = useState([])
  let [active, setActive] = useState(false)
  let [changes, setChanges] = useState({
    owner_id: '',
    active: ''
  })

  let handleActive = (e) => {

    setLoading(true);

    for(let val of props.pivot){
      if(val.owner_id == e.target.value){
        if(val.active_owner == 1 || val.active_owner == '1'){
          let data = {
            owner: e.target.value,
            lot: val.lot_id
          }
          axios.post(api_url+"/api/admin/owner/deactivate",data).then(response => {
            Swal.fire(
              'Success',
              'This owner is now in-active',
              'success'
              )
              props.setShow(false)
              setLoading(false);
          }).catch(error => {
            Swal.fire(
              'Error',
              'Failed to make this owner in-active',
              'error'
              )
              props.setShow(false)
              setLoading(false);
          })
          break;
        }
        else{
          let data = {
            owner: e.target.value,
            lot: val.lot_id
          }
          axios.post(api_url+"/api/admin/owner/activate",data).then(success => {
            Swal.fire(
              'Success',
              'This owner is now active',
              'success'
              )
              props.setShow(false)
              setLoading(false);
          }).catch(error => {
            Swal.fire(
              'Error',
              'Failed to make this owner active',
              'error'
              )
              props.setShow(false)
              setLoading(false);
          })
          break;
        }
      }
      else{
        Swal.fire(
          'Failed',
          'Owner id not found',
          'error'
        )
        props.setShow(false)
      }
    }
  }

  let tableData = ownerDetails.length && ownerDetails.map((val,ind) => {
   return props.pivot.length && props.pivot.map((itm,ind) => {
    if(itm.owner_id == val.id){
      return(
        <tr key={ind}>
            <td>{val.name}</td>
            <td>{val.email}</td>
            <td>
                <button type="button" value={val.id} className={(itm.active_owner == 1 || itm.active_owner == "1") ? "btn btn-outline-success" : "btn btn-outline-danger"} style={{borderRadius: '5px'}} onClick={handleActive}> {(itm.active_owner == 1 || itm.active_owner == "1") ? "Active" : "In-Active"} </button>
            </td>
        </tr>
    )
  }
    })
  })



  let handleStatus = (e) => {
      setUnitData((values) => ({
          ...values,
          status: e.target.value
      }))
  }

  useEffect(() => {
    setUnitData({
        title: props.unitDetails[0].title,
        status: props.unitDetails[0].status,
    })
    setOwnerDetails(props.userDetails)
  },[])

    return(


        <div className="container-scroller">

        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">

            {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
                    </div> : <div className="card-body">
                  <h4 className="card-title">Edit Lot Details</h4>

                  <div className="col-md-12">
         <form className="forms-sample">
                     <div className="form-group">
                       <label>Lot Title<code>*</code></label>
                       <input disabled type="text" value={unitData.title} className="form-control" placeholder="Charge Title" />
                     </div>
                     <div className="form-group">
                       <label>Lot Status</label>
                       <select onChange={handleStatus} value={unitData.status} className="form-control">
                           <option value="1">Developed</option>
                           <option value="0">Un-Developed</option>
                       </select>
                     </div>

                    <div className="table-responsive">
                        <label>Owner Details</label>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Name
                          </th>
                          <th>
                            Email
                          </th>
                          <th>
                            Owner Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ownerDetails.length ? <>{tableData}</> : <tr>
                            <td>
                                No Owner Found
                            </td></tr>}
                      </tbody>
                    </table>
                  </div>
        <br />

                     <button type="button" style={{borderRadius:'5px', marginRight:'5px'}} onClick={() => setShow2(!show2)} className=" btn btn-outline-info">Add New Owner</button>
                     <button style={{borderRadius:'5px', marginLeft:'5px'}} className=" btn btn-outline-secondary" onClick={() => props.setShow(false)}>Cancel</button>
                   </form>
         </div>
                  {show && <EditOwner show={show} setShow={setShow} />}
                  {show2 && <AddNewOwner show={show2} setShow={setShow2} pivot={props.pivot} />}
                  </div>}

                  </div>
                  </div>
              </div>
              </div>

        
        </div>






    //     <Modal
    //   size="md"
    //   show={props.show}
    //   centered
    // >
    //   <Modal.Header>
        
    //     <Modal.Title id="contained-modal-title-vcenter">
    //       Edit Lot
    //     </Modal.Title>
    //       <CloseButton onClick={() => props.setShow(false)} />
    //   </Modal.Header>

    //   <Modal.Body>
    //     <div className="row">
    //     <div className="col-md-12">
    //     <form className="forms-sample">
    //                 <div className="form-group">
    //                   <label>Lot Title<code>*</code></label>
    //                   <input disabled type="text" value={unitData.title} className="form-control" placeholder="Charge Title" />
    //                 </div>
    //                 <div className="form-group">
    //                   <label>Lot Status</label>
    //                   <select onChange={handleStatus} value={unitData.status} className="form-control">
    //                       <option value="1">Developed</option>
    //                       <option value="0">Un-Developed</option>
    //                   </select>
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Owner</label>
    //                     <input disabled className="form-control" value={unitData.owner} />
    //                 </div>
    //               </form>
    //     </div>
    //     </div>
    //     <br />
      
    //   </Modal.Body>
    //   <Modal.Footer>
    //   <button type="submit" style={{borderRadius:'5px'}} className=" btn btn-outline-info">Add</button>
    //     <button style={{borderRadius:'5px'}} className=" btn btn-secondary" onClick={() => props.setShow(false)}>Close</button>
    //   </Modal.Footer>
    // </Modal>
    )
}

export default EditLotComponent;