import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import api from '../../../../utils/apiURL';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'

const RequestPaymentComponent = () => {
    let user_id = localStorage.getItem('UserID');
    let [invoiceDetails, setInvoiceDetails] = useState([]);
    let [loading, setLoading] = useState(false);
    let [paymentReq, setPaymentReq] = useState([]);
    let [pageNum, setPageNum] = useState(2);
    let [totalPages, setTotalPages] = useState(0);

    let tableData = paymentReq.length && paymentReq.map((val,i) => {
      return invoiceDetails.length && invoiceDetails.map((itm,ind) => {
        if(val.invoice_id === itm.id){
          return(
            <tr key={i}>
              <td>{itm.title}</td>
              <td>{val.amount_paid  }</td>
              <td>{val.total_amount}</td>
              <td>{(val.status == '1') ? 'Pending' : (val.status == '2') ? 'Approved' : 'Declined'}</td>
            </tr>
          )
        }
      })
    })
    
      // console.log("payment request data :: ",paymentReq);
      // console.log("invoice data :: ",invoiceDetails)

    // let tableData = invoiceDetails.map((val, ind) => {
    //   let active = val.active_date.split('T')
    //   let due = val.due_date.split('T')
    //   console.log("invoice data in map :: ",val)
    //   if(val.paid == false){
    //     return(
    //         <tr>
    //             <td>
    //                 {val.title}
    //             </td>
    //             <td>
    //                 {active[0]}
    //             </td>
    //             <td>
    //                 {due[0]}
    //             </td>
    //             <td>
    //                 {val.amount}
    //             </td>
    //         </tr>  
    //     )
    //   }
    // })

    // console.log(tableData)
    // console.log(invoiceDetails);
    let testTotalPage = Math.ceil(totalPages/10)

    let handleLoadMore = () => {

      setLoading(true)
  
      // let testTotalPage = Math.ceil(totalPages/10)
      if(pageNum > testTotalPage){
        Swal.fire(
          'No more records',
          'No more records found!',
          'info'
        )
      }
      else if(pageNum <= testTotalPage){
        let data = {
          id: user_id,
          page: pageNum
        }
        axios.get(api+`/api/u1/payment/owner/pending`,data).then(result => {
          let test = result.data.data.map((val,ind) => {
            return val
          })
          paymentReq((values) => ([...values, ...test]));
          setLoading(false);
          setPageNum(pageNum + 1)
        }).catch(error => {
          console.log(error);
        })
      }

    }

    useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();

      setLoading(true);

        let data = {
            id: user_id,
            page: 1
        }

        let data2 = {
          id: user_id
        }

        // axios.post(api+"/api/u1/payment/owner/pending", data).then((response) => {
        //     setPaymentReq(response.data.data)
        //     axios.post(api+"/api/u1/invoices/owner", data2).then(result => {
        //         setInvoiceDetails(result.data.data)
        //         setLoading(false)

        //       axios.post(api+"/api/u1/payment/owner/count/pending",data2).then(res => {
        //         setTotalPages(res.data.data[0].total)
        //       }).catch(err => {
        //         setTotalPages(0)
        //       })

        //     }).catch(eror => {
        //       console.log(eror)
        //         setInvoiceDetails({
        //             id: 0,
        //             invoice_id: 0,
        //             unit_id: 0,
        //             title: 'Unable to find Invoices !',
        //             description: '',
        //             deposite_bank: '',
        //             category: 0,
        //             amount: 0,
        //             total_amount: 0,
        //             active_date: '',
        //             due_date: '',
        //             paid: 0
        //         })
        //         setLoading(false)
        //     })
        // }).catch((error) => {
        //     console.log(error);
        //     setPaymentReq({
        //       id: 0,
        //       image: '',
        //       invoice_id: '',
        //       note: '',
        //       paydate: '',
        //       reference_id: '',
        //       request_id: '',
        //       status: 0,
        //       total_amount: 0,
        //       unit_id: 0,
        //       user_id: 0
        //     })
        //     setLoading(false);
        // })
        setLoading(false)
        return () => {
          source.cancel()
        }

    },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
                  <br />
                  <div className="card">
                    {loading ? <div className="text-center" style={{padding: '10px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}} />
                </div> : <div className="card-body">
    <h4 className="card-title">Payment Requests</h4>
    <div className="col-md-12">
        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Invoice Title
                          </th>
                          <th>
                            Amount Paid
                          </th>
                          <th>
                            Total Amount
                          </th>
                          <th>
                            Request Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      { paymentReq.length ? <>{tableData}</> : 
                    <tr>
                      <td>No Payment Request Found</td>
                    </tr>  }
                        </tbody>
                        </table>
                        </div>

                </div>
                    <br />
                {(pageNum <= testTotalPage) ? <div className="text-center">
        <button onClick={handleLoadMore} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Load more</button>
      </div> : null}
      
            </div>
            </div>}
            </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default RequestPaymentComponent;