import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../../assets/img/light1.svg';
import axios from 'axios';
import api from '../../../../utils/apiURL';
import Swal from 'sweetalert2';

const ConfirmEmailComponent = (props) => {
  let navigate = useNavigate();
/*
 ----------------
 STATE DECLARATION 
 ----------------
*/
let [userDetails, setUserDetails] = useState({
  email: '',
});

/*
-----------------
FUNCTION'S
-----------------
*/
// console.log("props here :: ",props)
let handleEmail = (event) => {
  // console.log(event)
  setUserDetails((data) => ({
    ...data,
    email: event.target.value
  }))
}

let handleSubmit = (event) => {
  event.preventDefault();

  if(userDetails.email == ""){
    Swal.fire(
      'Error',
      'Enter email to continue',
      'warning'
    )
  }
  else {
    let data = {
      email: userDetails.email
    }
    axios.post(api+"/api/admin/confirm/email",data).then(response => {
      console.log("password reset success :: ",response);
    //   Swal.fire(
    //     'Success',
    //     'Password Reset Success, Login to Continue',
    //     'success'
    //   )
      navigate('/forgot-password/reset', { state:{ id: response.data.data[0].id }})
    }).catch(error => {
      console.log("error occured :: ",error);
      Swal.fire(
        'Error',
        'User not found !',
        'warning'
      )
    })
  }

}

    return(

        <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Enter Email to reset your password.</h6>
                  <form className="pt-3" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Email</label>
                      <input type="email" onChange={handleEmail} className="form-control form-control-lg" placeholder="Enter your Email" />
                    </div>
                    <div className="mt-3">
                      <input type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" value="Continue" />
                    </div>
                    
                    <div className="text-center mt-4 font-weight-light">
                      Don't have an account? <Link to="/admin/signup" className="text-info">Create</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    )
}

export default ConfirmEmailComponent;