import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../../../../utils/apiURL';
import Swal from 'sweetalert2'

const CreateNewComponent = () => {

  let navigate = useNavigate();
  //--------------
  //STATE DECLARATION
  //--------------

  let [invoicesData, setInvoicesData] = useState([]);
  let [chargeData, setChargeData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [pageNum, setPageNum] = useState(2)
  let [totalPages, setTotalPages] = useState(0)
  let [limited, setLimited] = useState(false);
  let [showRecurring, setShowRecurring] = useState(false)

  let [chargeDetails, setChargeDetails] = useState({
    title: '',
    description: '',
    amount: '',
    amountUndev: '',
    category: '',
    schedule: '',
    recurring: 1,
    limit: 0,
    dueDate: ''
  })

  //--------------
  //STATE DECLARATION ENDS
  //--------------
  //--------------
  //FUNCTION DECLARATION
  //--------------

  let tabledata = invoicesData.length && invoicesData.map((itm, i) => {
    let dueDate = itm.due_date.split('T')
    return(
      <tr key={i}>
        <td>{itm.title}</td>

        <td>{dueDate[0]}</td>
        <td>{itm.deposite_bank}</td>
        {(itm.lot_status == 0) ? <td>Un-Developed</td> : <td>Developed</td>}
      </tr>
    )
  })

  let chargeTable = chargeData.length && chargeData.map((val,i) => {
      return(
          <tr key={i}>
              <td>{val.charge_title}</td>
              <td>{val.charge_description}</td>
              <td>{val.charge_amount}</td>
          </tr>
      )
  })

  let testTotalPage = Math.ceil(totalPages/10)

  let handleTitle = (e) => {
    setChargeDetails((values) => ({
      ...values,
      title: e.target.value
    }))
  }

  let handleDescription = (e) => {
    setChargeDetails((values) => ({
      ...values,
      description: e.target.value
    }))
  }

  let handleAmount = (e) => {
    setChargeDetails((values) => ({
      ...values,
      amount: e.target.value
    }))
  }

  let handleAmountUndev = (e) => {
    setChargeDetails((values) => ({
      ...values,
      amountUndev: e.target.value
    }))
  }

  let handleCategory = (e) => {
    setChargeDetails((values) => ({
      ...values,
      category: e.target.value
    }))
  }

  let handleSchedule = (e) => {
    console.log("option value :: ",e.target.value)
    setChargeDetails((values) => ({
      ...values,
      schedule: e.target.value
    }))
    if(e.target.value == '1' || e.target.value == 1){
      setChargeDetails((values) => ({
        ...values,
        recurring: 0,
        limit: 1
      }))
      setShowRecurring(false)
    }
    else{
      setShowRecurring(true)
    }
  }

  let handleLimited = () => {
    setLimited(!limited)
    setChargeDetails((values) => ({
      ...values,
      recurring: 0
    }))
  }

  let handleRecurring = () => {
      setLimited(false)
      setChargeDetails((values) => ({
        ...values,
        recurring: 1,
        limit: 0
      }))
  }

  let handleDuedate = (e) => {
    console.log("Selected date here :: ",e.target.value)
    setChargeDetails((values) => ({
      ...values,
      dueDate: e.target.value
    }))
  }

  let handleLimit = (e) => {
    setChargeDetails((values) => ({
      ...values,
      limit: e.target.value
    }))
  }

  let handleSubmit = (e) => {
    e.preventDefault();
    if(chargeDetails.title == ""){
      Swal.fire(
        'Empty filed',
        'Enter Title of Charge to continue!',
        'warning'
      )
    }

    else if(chargeDetails.description == ""){
      Swal.fire(
        'Empty filed',
        'Enter Description of Charge to continue!',
        'warning'
      )
    }

    else if(chargeDetails.amount == ""){
      Swal.fire(
        'Empty filed',
        'Enter Amount of Charge to continue!',
        'warning'
      )
    }

    else if(chargeDetails.amount == 0){
      Swal.fire(
        'Invalid Data',
        'Charge amount can not be 0!',
        'warning'
      )
    }

    else if(chargeDetails.category == ""){
      Swal.fire(
        'Empty filed',
        'Enter Category of Charge to continue!',
        'warning'
      )
    }

    else if(chargeDetails.schedule == ""){
      Swal.fire(
        'Empty filed',
        'Enter Schedule of Charge to continue!',
        'warning'
      )
    }

    else if(chargeDetails.dueDate == ""){
      Swal.fire(
        'Empty filed',
        'Enter Due Date of Charge to continue!',
        'warning'
      )
    }

    else{
      let data = {
        title: chargeDetails.title,
        description: chargeDetails.description,
        amount_dev: chargeDetails.amount,
        amount_undev: chargeDetails.amountUndev,
        category: chargeDetails.category,
        schedule: chargeDetails.schedule,
        recurring: chargeDetails.recurring,
        limit: chargeDetails.limit,
        dueDate: chargeDetails.dueDate
      }
      axios.post(api+"/api/admin/invoices/create/charge",data).then(response => {
        console.log("Charge created successfully :: ",response);
        Swal.fire(
          'Success',
          'Charge created successfully.',
          'success'
        )
        navigate('/admin/charge')
      }).catch(error => {
        console.log("error occured while creating charge: ",error);
        Swal.fire(
          'Error',
          'Failed to create Charge! Try again later.',
          'error'
        )
      })
    }
  }



  let handleNext = () => {
    setLoading(true);

    if(pageNum > testTotalPage){
      Swal.fire(
        'No more records',
        'No more records found!',
        'info'
      )
    }
    else if(pageNum <= testTotalPage){
      axios.get(api+`/api/admin/invoices/admin/allinvoices?page=${pageNum}`).then(response => {

        let test = response.data.data.map((val,id) => {
          return val
        })

        setInvoicesData((values) => ([...values, ...test]))
        setLoading(false);
        setPageNum(pageNum + 1)
      }).catch(error => {
        console.log("error :: ",error);
        setInvoicesData({
          id: 0,
          invoice_id: 0,
          unit_id: 0,
          title: '',
          description: '',
          deposite_bank: '',
          category: '',
          amount: 0,
          total_amount: 0,
          active_date: '',
          due_date: '',
          paid: ''
        })
        setLoading(false);
      })
    }
  }

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);

    // axios.get(api+"/api/admin/invoices/admin/count").then(response => {
    //   setTotalPages(response.data.data[0].total)
    // }).catch(err => {
    //   setTotalPages(0)
    //   console.error("Failed to get total admin invoices pages",err)
    // })

    // axios.get(api+`/api/admin/invoices/admin/allinvoices?page=${1}`).then(response => {
    //   setInvoicesData(response.data.data)
    //   setLoading(false);
    // }).catch(error => {
    //   console.log("error :: ",error);
    //   setInvoicesData({
    //     id: 0,
    //     invoice_id: 0,
    //     unit_id: 0,
    //     title: '',
    //     description: '',
    //     deposite_bank: '',
    //     category: '',
    //     amount: 0,
    //     total_amount: 0,
    //     active_date: '',
    //     due_date: '',
    //     paid: ''
    //   })
    //   setLoading(false);
    // })

    axios.get(api+"/api/admin/invoices/charges").then(result => {
        setChargeData(result.data.data)
      setLoading(false);
      }).catch(err => {
        console.log("error :: ",err)
      })

    return () => {
      source.cancel()
    }

  },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="text-end">
          <Link to="/admin/charge"><button type="button" style={{borderRadius:'5px', marginRight:'15px'}} className="btn btn-outline-info">Back</button></Link>
              </div>
              <div className="card">
                  <div className="card-body">
                      <h4 className="card-title">Create New Charge</h4>

                      <div className="row">
        <div className="col-md-6">
        <form className="forms-sample">
                    <div className="form-group">
                      <label>Charge Title<code>*</code></label>
                      <input required type="text" onChange={handleTitle} className="form-control" placeholder="Charge Title" />
                    </div>
                    <div className="form-group">
                      <label>Charge Description </label>
                      <textarea type="text" onChange={handleDescription} className="form-control" placeholder="Charge Description" />
                    </div>
                    <div className="form-group">
                        <label>Charge Amount for Developed Lot <code>*</code></label>
                        <input required type="number" onChange={handleAmount} className="form-control" placeholder="Charge Amount for Developed Lot" />
                    </div>
                    <div className="form-group">
                        <label>Charge Amount for UnDeveloped Lot <code>*</code></label>
                        <input required type="number" onChange={handleAmountUndev} className="form-control" placeholder="Charge Amount for UnDeveloped Lot" />
                    </div>
                    <div className="form-group">
                      <label>Category <code>*</code></label>
                      <input type="text" onChange={handleCategory} className="form-control" required placeholder="Enter Category of Charge" /> 
                    </div>
                    <div className="form-group">
                      <label>Due Date <code>*</code></label>
                      <input type="date" onChange={handleDuedate} className="form-control" required /> 
                    </div>
                    <div className="form-group">
                      <label>Schedule <code>*</code></label>
                      <select className="form-control" onChange={handleSchedule} required>
                        <option> -- Select Schedule --</option>
                        <option value="1">One time</option>
                        <option value="2">Monthly</option>
                        <option value="3">Quaterly</option>
                        <option value="4">Semi-annual</option>
                        <option value="5">Annual</option>
                      </select>
                    </div>
                  {showRecurring ? <><div className="form-group">
                        <input type="radio" name="recurringLimited" id="reccurring" onChange={handleRecurring} />
                        <label for="recurring">Recurring</label>
                        </div>
                    <div className="form-group">
                        <input type="radio" name="recurringLimited" id="limited" onChange={handleLimited} />
                        <label for="limited">Limited</label>
                    </div></> : null }
                    {limited ? <div className="form-group">
                        <label>Number of invoices to create</label>
                        <input type="number" onChange={handleLimit} className="form-control" required />
                    </div> : null}

                    <button className="btn btn-outline-info" style={{borderRadius: '5px'}} type="submit" onClick={handleSubmit}>Add New Charge</button>
                  </form>
        </div>
        </div>

                  </div>
              </div>

            </div>
              </div>

              </div>
        </Header>
        </div>
    )
}

export default CreateNewComponent;