import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import { Link } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';
import axios from 'axios';
import api from '../../../utils/apiURL';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'

const ChargeComponent = () => {
  //--------------
  //STATE DECLARATION
  //--------------

  let [invoicesData, setInvoicesData] = useState([]);
  let [chargeData, setChargeData] = useState([]);
  let[loading, setLoading] = useState(false);
  let [pageNum, setPageNum] = useState(2)
  let [totalPages, setTotalPages] = useState(0)

  //--------------
  //STATE DECLARATION ENDS
  //--------------
  //--------------
  //FUNCTION DECLARATION
  //--------------

  let tabledata = invoicesData.length && invoicesData.map((itm, i) => {
    let dueDate = itm.due_date.split('T')
    return(
      <tr key={i}>
        <td>{itm.title}</td>

        <td>{dueDate[0]}</td>
        <td>{itm.deposite_bank}</td>
        {(itm.lot_status == 0) ? <td>Un-Developed</td> : <td>Developed</td>}
      </tr>
    )
  })

  let chargeTable = chargeData.length && chargeData.map((val,i) => {
      return(
          <tr key={i}>
              <td>{val.charge_title}</td>
              <td>{val.charge_description}</td>
              <td>${val.amount_dev}</td>
              <td>${val.amount_undev}</td>
          </tr>
      )
  })

  let testTotalPage = Math.ceil(totalPages/10)

  let handleNext = () => {
    setLoading(true);

    if(pageNum > testTotalPage){
      Swal.fire(
        'No more records',
        'No more records found!',
        'info'
      )
    }
    else if(pageNum <= testTotalPage){
      axios.get(api+`/api/admin/invoices/admin/allinvoices?page=${pageNum}`).then(response => {

        let test = response.data.data.map((val,id) => {
          return val
        })

        setInvoicesData((values) => ([...values, ...test]))
        setLoading(false);
        setPageNum(pageNum + 1)
      }).catch(error => {
        console.log("error :: ",error);
        setInvoicesData({
          id: 0,
          invoice_id: 0,
          unit_id: 0,
          title: '',
          description: '',
          deposite_bank: '',
          category: '',
          amount: 0,
          total_amount: 0,
          active_date: '',
          due_date: '',
          paid: ''
        })
        setLoading(false);
      })
    }
  }

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);

    // axios.get(api+"/api/admin/invoices/admin/count").then(response => {
    //   setTotalPages(response.data.data[0].total)
    // }).catch(err => {
    //   setTotalPages(0)
    //   console.error("Failed to get total admin invoices pages",err)
    // })

    // axios.get(api+`/api/admin/invoices/admin/allinvoices?page=${1}`).then(response => {
    //   setInvoicesData(response.data.data)
    //   setLoading(false);
    // }).catch(error => {
    //   console.log("error :: ",error);
    //   setInvoicesData({
    //     id: 0,
    //     invoice_id: 0,
    //     unit_id: 0,
    //     title: '',
    //     description: '',
    //     deposite_bank: '',
    //     category: '',
    //     amount: 0,
    //     total_amount: 0,
    //     active_date: '',
    //     due_date: '',
    //     paid: ''
    //   })
    //   setLoading(false);
    // })

    axios.get(api+"/api/admin/invoices/charges").then(result => {
        setChargeData(result.data.data)
      setLoading(false);
      }).catch(err => {
        console.log("error :: ",err)
      })

    return () => {
      source.cancel()
    }


  },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">

              <div className="card">

              <div className="card-body">
                  <h4 className="card-title">Charges</h4>
                  <div className="col-md-12">
                    <div className="text-end">
                  <Link to="/admin/charge/new">
                      <button style={{borderRadius:'5px'}} className="btn btn-outline-info">
                  <BsPlusLg style={{marginRight:'5px'}} />Create New Charge    
                      </button>
                  </Link>
                    </div>
                  </div>
                  </div>

                  </div>
                  <br />
                  <div className="card">
                  {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <div className="card-body">
    <h4 className="card-title">Charge Details</h4>
    <div className="col-md-12">

            <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>

                          <th>
                            Title
                          </th>
                          {/* <th>
                            Total Amount
                          </th> */}
                          <th>
                            Description
                          </th>
                          <th>
                            Amount for Developed Lot
                          </th>
                          <th>
                            Amount for UnDeveloped Lot
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          {chargeData.length ? <>{chargeTable}</> : <tr>
                              <td>
                                  No Charge Available
                              </td></tr>}
                        {/* {invoicesData.length ? <>{tabledata}</> : <tr>
                          <td>No Invoice Available</td>
                          </tr>} */}
                        {/* {tabledata} */}
                        </tbody>
                        </table>
                        </div>

        </div>
        <br />
      {(pageNum <= testTotalPage) ? <div className="text-center">
        <button onClick={handleNext} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Load more</button>
      </div> : null}
            </div>
            </div>}

            </div>
            </div>
              </div>

              </div>
        </Header>
        </div>
    )
}

export default ChargeComponent;