import React from 'react';
import { Card, Button, CardGroup } from 'react-bootstrap';
import image1 from '../../../../assets/img/real-estate/bedroom1.jpg'

const BlogPosts = () => {
    return(
        <div className="container" data-aos="fade-up" style={{marginTop: '10px', marginBottom:'10px'}}>
        <div className="row">
          <div className="col-lg-12 content order-lg-1 order-2 text-start">
              <CardGroup>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
</CardGroup>
    <CardGroup style={{marginTop: '10px'}}>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
</CardGroup>
    <CardGroup style={{marginTop: '10px'}}>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card style={{ width: '18rem', marginRight:'10px' }}>
                <Card.Img variant="top" src={image1} />
                <Card.Body>
                    <Card.Title>Lorem Ipsum</Card.Title>
                    <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                    </Card.Text>
                </Card.Body>
                </Card>
</CardGroup>
</div>
</div>
</div>
    )
}

export default BlogPosts;