import React from 'react';
import { IoIosClipboard } from 'react-icons/io';
import { FaHandsHelping } from 'react-icons/fa';

const ServicesComponent = () => {
    return(
        <section id="new-service">
        <div className="container" data-aos="fade-up">
          <div className="row new-service-container">
            <div className="col-lg-6 content order-lg-1 order-2 text-start">
              <h2 className="title">Our Services</h2>
              <p>
              Our products don't require a web designer's assistance or any technical setup.
               We have carefully created the templates, software, and admin portals with information
                to make them easy for you to use. However, we know there will be times you may have
                 a question that needs further instructions, either through our Quick Reference Guide
                  documentation and Support Series Videos.
              </p>
              <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
                <div className="icon"><IoIosClipboard color="#2dc997" size={25} /></div>
                <h4 className="title"><a href>We are experts</a></h4>
                <p className="description">
                We have decades of experience in website design and software from working
                 with thousands of associations across the US.
                </p>
              </div>
              <div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
                <div className="icon"><FaHandsHelping color="#2dc997" size={25} /></div>
                <h4 className="title"><a href>Trust our commitment</a></h4>
                <p className="description">From our deeply personal interest as fellow homeowners,
                 to our passion to create tools and solutions that work for associations and its
                  members, to our decades of website and software experience, you can trust
                   our commitment to you.</p>
              </div>
              {/* <div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
                <div className="icon"><i className="bi bi-binoculars" /></div>
                <h4 className="title"><a href>Dolor Sitema</a></h4>
                <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
              </div> */}
            </div>
            <div className="col-lg-6 background order-lg-2 order-1" data-aos="fade-left" data-aos-delay={100} />
          </div>
        </div>
      </section>
    )
}

export default ServicesComponent;