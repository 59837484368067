import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import axios from 'axios';
import api from '../../../utils/apiURL';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { FaFilter } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import Filter from '../Common/Filter';
import _ from 'lodash';

const StatementComponent = () => {

  let userId = localStorage.getItem('UserID');

  //--------------
  //STATE DECLARATION
  //--------------

  let [invoicesData, setInvoicesData] = useState([]);
  let [payments, setPayments] = useState([]);
  let [loading, setLoading] = useState(false);
  let [unitDetails, setUnitDetails] = useState([])
  let [pageNum, setPageNum] = useState(2);
  let [pivotDetails, setPivotDetails] = useState([]);
  let [chargeDetails, setChargeDetails] = useState([]); 
  let [totalPages, setTotalPages] = useState(0);
  let [filter, setFilter] = useState({
    from: '',
    to: ''
  })
  let [show, setShow] = useState(false);

  //--------------
  //STATE DECLARATION ENDS
  //--------------
  //--------------
  //FUNCTION DECLARATION
  //--------------

  let handleClick = () => {
    setShow(!show)
  }

  let InvoiceTable = invoicesData.length && invoicesData.map((val,i) => {
    return unitDetails.length && unitDetails.map((itm,ind) => {
      if(val.lot_id == itm.id){
        return(
          <tr key={i}>
        <td key={i}>{val.title}</td>
        <td key={i}>${val.total}</td>
        <td>{itm.title}</td>
        <td>
          <Link to="/user/statements/invoices" state={{invoice: val, lot: itm, pivot: pivotDetails, charge: chargeDetails}}>
          <button className="btn btn-outline-info" style={{borderRadius:'5px'}}>View</button>
          </Link>
        </td>
        </tr>
      )
    }
    })
  })

  let paymentTable = payments.length && payments.map((val,i) => {
    return unitDetails.length && unitDetails.map((itm,ind) => {
      if(val.unit_id == itm.id){
        let date = val.paydate.split('T')
        return(
          <tr key={i}>
            <td>{val.payment_id}</td>
            <td>${val.total_amount}</td>
            <td>${val.balance}</td>
            <td>{date[0]}</td>
            <td>{(val.on_account == 1) ? "True" : "False"}</td>
          </tr>
        )
      }
    })
  })

let totalOpen = pivotDetails.length && pivotDetails.map((itm,ind) => {
      return itm.charge_amount
})

let totalClose = pivotDetails.length && pivotDetails.map((itm,ind) => {
      return itm.balance
})

let open = _.sum(totalOpen);
let close = _.sum(totalClose);

console.log("pivot :: ",totalOpen)


//   let tabledata = invoicesData.length && invoicesData.map((itm, i) => {
//     let dueDate = itm.due_date.split('T')
//     return(
//       <tr key={i}>
//         <td>{itm.title}</td>
//         {/* <td>{itm.total_amount}</td> */}
//         <td>{dueDate[0]}</td>
//         <td>{itm.deposite_bank}</td>
//         {(itm.lot_status == 0) ? <td>Un-Developed</td> : <td>Developed</td>}
//         <td>
//           <Link to="/admin/invoices/view" state={{invoice: itm}}>
//             <button type="button" className="btn btn-outline-info" style={{borderRadius:'5px'}}>View</button>
//           </Link>
//         </td>
//       </tr>
//     )
//   })

//   let testTotalPage = Math.ceil(totalPages/10)

//   let handleNext = () => {
//     setLoading(true);

//     if(pageNum > testTotalPage){
//       Swal.fire(
//         'No more records',
//         'No more records found!',
//         'info'
//       )
//     }
//     else if(pageNum <= testTotalPage){
//       axios.get(api+`/api/admin/invoices/admin/allinvoices?page=${pageNum}`).then(response => {

//         let test = response.data.data.map((val,id) => {
//           return val
//         })

//         setInvoicesData((values) => ([...values, ...test]))
//         setLoading(false);
//         setPageNum(pageNum + 1)
//       }).catch(error => {
//         console.log("error :: ",error);
//         setInvoicesData({
//           id: 0,
//           invoice_id: 0,
//           unit_id: 0,
//           title: '',
//           description: '',
//           deposite_bank: '',
//           category: '',
//           amount: 0,
//           total_amount: 0,
//           active_date: '',
//           due_date: '',
//           paid: ''
//         })
//         setLoading(false);
//       })
//     }
//   }

console.log("invoice details :: ",invoicesData);
console.log("owner lot details :: ",unitDetails);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);
    
    let ownerId = {
      id: userId
    }

    axios.post(api+"/api/u1/invoices/owner/one-month",ownerId).then(response => {
      setInvoicesData(response.data.data);
    }).catch(err => {
      console.log("error occured !! ",err);
    })

    axios.post(api+"/api/u1/units/owner/untis",ownerId).then(response => {
      setUnitDetails(response.data.data)
    }).catch(err => {
      console.log("error occured while getting owner unit details !! ",err);
    })

    axios.get(api+"/api/u1/invoices/pivot/all").then(response => {
      setPivotDetails(response.data.data)
    }).catch(err => {
      console.log("error occured while getting pivot data :: ",err)
    })

    axios.get(api+"/api/admin/invoices/charges").then(result => {
      setChargeDetails(result.data.data)
    }).catch(err => {
      console.log("error occured while getting charge details :: ",err)
    })

    axios.post(api+'/api/u1/payment/statement/one-month',ownerId).then(response => {
      setPayments(response.data.data);
      setLoading(false);
    }).catch(err => {
      console.log("failed to get payments :: ",err);
    })

    return () => {
      source.cancel()
    }


  },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
          {show && <Filter show={show} setShow={setShow} setInvoice={setInvoicesData} filter={filter} setFilter={setFilter} setPage={setPageNum} setLoading={setLoading} /> }
                  
    <div className="card">
                  {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <div className="card-body">
    <h4 className="card-title">Statements</h4>
    <div className="col-md-12">
        <div className="text-end">
            <button className="btn btn-outline-info" style={{borderRadius:'5px'}} onClick={handleClick}><FaFilter /></button>
        </div>
        <div className="col-md-6">
            <h4 className="card-title">Opening balance</h4>
            <p className="card-description">${open}</p>
        </div>
        <div className="col-md-6">
            <h4 className="card-title">Closing Balance</h4>
            <p>${close}</p>
        </div>
        <br />
        <br />

        <div className="col-md-8">
        <div className="template-demo">
          <div className="card-title">Invoices</div>
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Invoice Title
                          </th>
                          <th>
                            Invoice Amount
                          </th>
                          <th>Lot</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {invoicesData.length ? <>{InvoiceTable}</>: <tr>
                        <td>No Data available</td>
                        </tr>}

                        </tbody>
                        </table>
                        </div>

                </div>
      
            </div>



            </div>
            </div>}


            </div>


            <br/>
            <div className="card">
              <div className="card-body">

            <h4 className="card-title">Payments</h4>
        <br />
            <div className="row">
                <div className="col-md-12">
                <div className="template-demo">
        <div className="table-responsive">

        <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Reference ID
                          </th>
                          <th>
                            Total Amount
                          </th>
                          <th>Outstanding</th>
                          <th>Paid On</th>
                          <th>On Account</th>
                        </tr>
                      </thead>
                      <tbody>
                      {payments.length ? <>{paymentTable}</>: <tr>
                        <td>No Data available</td>
                        </tr>}

                        </tbody>
                        </table>

        </div>

        </div>    
                </div>	
            </div>
            </div>
              </div>


            </div>

              </div>
              </div>
        </Header>
        </div>
    )
}

export default StatementComponent;