import React, { useState, useEffect } from 'react';
import { Button, Modal, CloseButton, Spinner } from 'react-bootstrap';
import api_url from '../../../../../utils/apiURL';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';

const AddNewOwnerComponent = (props) => {

    let [loading, setLoading] = useState(false)
    let [users, setUsers] = useState([])

    let optnsData = users.length && users.map((val,i) => {
        if(props.pivot.length){
        return props.pivot.length && props.pivot.map((itm,ind) => {
            if(val.id == itm.owner_id){
                return null
            }
            else{
                return {
                    value: val.id,
                    label: val.name
                }
            }
        })
    }
    else{
        return {
            value: val.id,
            label: val.name
        }
    }
    })

    console.log("props in add new owner component :: ",props)

    useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();
        setLoading(true)

        axios.get(api_url+"/api/admin/owner/allusers").then(response => {

            setUsers(response.data.data)
            setLoading(false)

        }).catch(error => {
            console.log("error occured :: ",error)
            setLoading(false)
        })

        return () => {
            source.cancel();
          }
    },[])

    return(
        <div>
        {loading ? <div className="text-center" style={{padding:'20px'}}>
        <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
          </div> : <Modal
      size="md"
      show={props.show}
      centered
    >
      <Modal.Header>
        
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Owner
        </Modal.Title>
          <CloseButton onClick={() => props.setShow(false)} />
      </Modal.Header>

      <Modal.Body>
        <div className="row">
        <div className="col-md-12">
        <form className="forms-sample">
                    <div className="form-group">
                    <label>Users</label>
                    <Select options={optnsData} isMulti={true} />
                    </div>
                  </form>
        </div>
        </div>
        <br />
      
      </Modal.Body>
      <Modal.Footer>
      <button type="submit" style={{borderRadius:'5px'}} className=" btn btn-outline-info">Add</button>
        <button style={{borderRadius:'5px'}} className=" btn btn-secondary" onClick={() => props.setShow(false)}>Close</button>
      </Modal.Footer>
    </Modal>}
    </div>
    )
}

export default AddNewOwnerComponent;