import React, { useState, useEffect } from 'react';
import Header from '../../../Common/Header';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import api from '../../../../../utils/apiURL';
import { Spinner } from 'react-bootstrap'

const ViewOwnerLotComponent = () => {

    let location = useLocation();
    let { state } = location;
    let [loading, setLoading] = useState(false);

    console.log("location state data :: ",state)

    let [OwnerDetails, setOwnerDetails] = useState([]);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

    let checkClick = () => {
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              )
            }
          })
    }

    console.log(OwnerDetails)

    // useEffect(() => {

    //     let CancelToken = axios.CancelToken;
    //     let source = CancelToken.source();

    //     setLoading(true);
    //     if(state.unit.owner_id != 0){
    //     setLoading(true);
    //     let data = {
    //         userId: state.unit.owner_id
    //     }
    //         axios.post(api+'/api/admin/owner/user',data).then((response) => {
    //         setOwnerDetails(response.data.data)
    //         setLoading(false);
    //         }).catch(err => {
    //         console.log(err);
    //         })
    //     }
    //     else{
    //     setOwnerDetails([{
    //         name: 'No Owner for this unit.'
    //     }]
    //     )
    //     setLoading(false);
    //     }

    //     return () => {
    //         source.cancel();
    //     }
    // },[])

    return(
        <div className="container-scroller">
        <Header>

        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">

                {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
                    </div> : <div className="card-body">
                  <h4 className="card-title">Lot</h4>
                  <div className="col-md-12">
                    

                      <p className="card-description">Lot Details</p>
                      <div className="template-demo">
                          <div className="text-end">
                              <Link to="/user/details"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} className="btn btn-outline-info">Back</button></Link>
                          </div>
                          <br/>
                          {/* <h5>
                            Title
                        </h5>
                        <p className="card-description">{state.unit.title}</p>
                        <h5>
                            Address
                        </h5>
                        <p className="card-description">{state.unit.address + ", "+state.unit.city+", "+state.unit.state+", "+state.unit.zipcode}</p>
                        <h5>Lot Status</h5>
                        <p>{(state.unit.status == 1) ? "Developed" : "Un-Developed"}</p>
                        <h5>
                            Owner
                        </h5>
                        <p className="card-description">{OwnerDetails.length && OwnerDetails[0].name}</p> */}
                      </div>
                    <br />
                  </div>
                  </div>}

                  </div>
                  </div>
              </div>
              </div>

        </Header>
        </div>
    )
}

export default ViewOwnerLotComponent;