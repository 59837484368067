import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../../Common/Header';
import api_url from '../../../../../utils/apiURL';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'

const MakePaymentComponent = () => {
  let userId = localStorage.getItem('UserID');
  let navigate = useNavigate();

  let[unitDetails, setUnitDetails] = useState([])
  let[makeInvoice, setMakeInvoice] = useState(false)
  let[makeAccount, setMakeAccount] = useState(false)
  let[showCard, setShowCard] = useState(false)
  let [payData, setPayData] = useState({
    unit_id: '',
    paydate: '',
    amount: '',
    note: '',
    total: '',
    invoice_id: '',
    method: '1',
    bank: ''
  })

  let [invoicesDetails, setInvoicesDetails] = useState([]);
  let [pivot, setPivot] = useState([]);

  // console.log("payment details :: ",payData)

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;
let testDate = yyyy+'-'+mm+'-'+dd

let optionsData = invoicesDetails && invoicesDetails.map((val, ind) => {
  if(val.paid == false){
    return(
      <option value={val.id} > {val.title} </option>
    )
  }
  else{
    return null
  }
})

let unitOptions = unitDetails.length && unitDetails.map((val,ind) => {
  return(
    <option key={ind} value={val.id}>{val.title}</option>
  )
})

let handleInvoice = (event) => {
  if(event.target.value == 0){
    console.log("No invoice Selected !!!")
    setPayData((values) => ({
      ...values,
      total: 0,
      amount: 0
    }))
  }
  else{

    if(payData.unit_id == "" || payData.unit_id == null){
      Swal.fire(
        'Select Lot',
        'Select Lot to continue.',
        'warning'
      )
    }
    else{
      let data = {
        lot: payData.unit_id,
        invoice: event.target.value
      }
      axios.post(api_url+"/api/admin/invoices/pivot/inv-lot",data).then(response => {
        setPivot(response.data.data);
        let total = response.data.data.length && response.data.data.map((val,i) => {
          if(val.paid == 0){
            return val.balance
          }
          else{
            return 0
          }
        })
        let test_total = _.sum(total);
        setPayData((values) => ({
          ...values,
          total: test_total,
          amount: test_total
        }))
      }).catch(err => {
        console.log("error occured in pivot api :: ",err);
      })
      setPayData((values) => ({
        ...values,
        invoice_id: event.target.value,
        paydate: testDate,
      }))
    }
  }
}

let handleAccount = () => {
  setMakeAccount(!makeAccount);
  setMakeInvoice(false);

  if(payData.unit_id == "" || payData.unit_id == null){
    Swal.fire(
      'Select Lot',
      'Select lot to continue.',
      'warning'
    )
  }
  else{
    let data = {
      lot: payData.unit_id
    }
  
    axios.post(api_url+"/api/admin/invoices/unpaid/pivot",data).then(response => {
      setPivot(response.data.data);
      let total = response.data.data.length && response.data.data.map((val,i) => {
        return val.balance
      })
      let test = _.sum(total)
      setPayData((values) => ({
        ...values,
        total: test,
        amount: test
      }))

    }).catch(err => {
      console.log("failed to get pivot details !! ",err);
    })

  }
}

let handleShowInvoice = () => {
  setMakeInvoice(!makeInvoice);
  setMakeAccount(false)
}

let handleCard = () => {
  setShowCard(!showCard);
}

let handleAmount = (event) => {
  setPayData((values) => ({
    ...values,
    amount: event.target.value
  }))
}

let handleNote = (event) => {
  setPayData((values) => ({
    ...values,
    note: event.target.value
  }))
}

let handleTotal = (event) => {
  setPayData((values) => ({
    ...values,
    total: event.target.value
  }))
}

let handleBank = (e) => {
  setPayData((values) => ({
    ...values,
    bank: e.target.value
  }))
}

// console.log("props p data :: ",payData)

let handleSubmit = (event) => {
  event.preventDefault();
  let data = {
    lot: payData.unit_id,
    paydate: testDate,
    amount: payData.amount,
    note: payData.note,
    invoice: payData.invoice_id,
    method: payData.method,
    bank: payData.bank
  }

  if(data.amount == "" || data.amount == 0){
    Swal.fire(
      'Enter Amount',
      "Enter amount to pay.",
      'error'
    )
  }

  else if(data.amount > data.total){
    Swal.fire(
      'Failed',
      'Amount Paid is greater than Total Amount',
      'error'
    )
  }
  
  else{
    axios.post(api_url+"/api/admin/payment/record/admin",data).then((response) => {
      Swal.fire(
        'Success',
        'Payment Successfull.',
        'success'
      )
      console.log(response)
      navigate('/user/payments')
    }).catch((error) => {
      Swal.fire(
        'Failed',
        'Payment Failed!',
        'error'
      )
      console.log(error)
    })
  }
}

let handleSelectUnit = (e) => {
  console.log("value of event e :: ",e)

  setPayData((values) => ({
    ...values,
    unit_id: e.target.value
  }))

  let data4 = {
    id: e.target.value
  }

  axios.post(api_url+"/api/u1/invoices/lot",data4).then((items) => {
    setInvoicesDetails(items.data.data);
  }).catch((err) => {
    console.log(err);
  })

}

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    let data = {
      id: userId
    }

    axios.post(api_url+"/api/u1/units/owner/untis",data).then(response => {
      setUnitDetails(response.data.data)
      if(response.data.data.length > 1){
        console.log("more than 1 unit found");
      }
    }).catch(err => {
      console.log("error occured :: ",err)
    })

    return () => {
      source.cancel()
    }

  },[])

    return(

      <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
        <div className="row">
        <div className="row">
              <div className="col-md-12">
                    <div className="template-demo text-end">
                    <Link to="/user/payments"><button type="button" style={{borderRadius:'5px', marginRight:'15px'}} className="btn btn-outline-info">Back</button></Link>
                    </div>
            </div>
                  </div>
        <div className="col-lg-12 grid-margin">
              <div className="card">
              <div className="card-body">
            <h4 className="card-title">Make Payment</h4>
            <div className="row">
        <div className="col-md-6">
        <form className="forms-sample" autoComplete="off">
                    <div className="form-group">
                        <button type="button" onClick={handleAccount} className="btn btn-outline-info" style={{borderRadius:'5px', marginRight:'5px'}}>Make payment on Account</button>
                        <button type="button" onClick={handleShowInvoice} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Make payment on Invoice</button>
                    </div>

                    {(unitDetails.length > 1) ? <> <div className="form-group">
                      <label>Select Unit<code>*</code></label>
                      <select onChange={handleSelectUnit} className="form-control form-control-sm">
                        <option value="0">-- Select Unit --</option>
                        {unitOptions}
                      </select>
                    </div></> : null}

                    {makeInvoice ? <> <div className="form-group">
                      <label>Invoice<code>*</code></label>
                      <select onChange={handleInvoice} className="form-control form-control-sm">
                        <option value="0">-- Select Invoice --</option>
                        {optionsData}
                      </select>
                    </div></> : null}
                    {makeAccount ? <> <div className="form-group">
                      <label>Deposit Bank<code>*</code></label>
                      <input type="text" onChange={handleBank} className="form-control form-control-sm" placeholder="Enter Deposit bank" />
                    </div></> : null}
                    <div className="form-group">
                      <label>Method</label>
                      <input type="text" disabled className="form-control form-control-sm" value="Online" />
                    </div>
                    <div className="form-group">
                      <label>Payment Date</label>
                      <input type="text" value={testDate} disabled className="form-control form-control-sm" />
                    </div>
                  </form>
        </div>
        <div className="col-md-6">
        <form className="forms-sample">
                    <div className="form-group">
                      <label>Total Amount</label>
                      <input disabled type="text" value={payData.total} className="form-control form-control-sm" placeholder="$0.00" />
                    </div>
                    <div className="form-group">
                      <label>Amount</label>
                      <input onChange={handleAmount} value={payData.amount} type="text" className="form-control form-control-sm" placeholder="$0.00" />
                    </div>
                    <div className="form-group">
                      <label>Note</label>
                      <input onChange={handleNote} type="text" className="form-control form-control-sm" />
                    </div>
                  </form>
        </div>
        </div>
        {showCard ? null : <> <button type="button" onClick={handleCard} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Enter Card Details</button></>}

        {showCard ? <>
          <div className="row">
            <h4><br/>Card Details</h4>
            <div className="col-md-6">
            <form className="forms-sample">
                    <div className="form-group">
                      <label>Card Number<code>*</code></label>
                      <input type="text" className="form-control form-control-sm" placeholder="Card Number" required />
                    </div>
                    <div className="form-group">
                      <label>Expiry Date<code>*</code></label>
                      <input type="month" value="2022-03" className="form-control form-control-sm" required />
                    </div>
                    <div className="form-group">
                      <label>CVV<code>*</code></label>
                      <input type="password" className="form-control form-control-sm" placeholder="CVV" required />
                    </div>
                  </form>
            </div>
        </div></> : null}
          <br /><br />
        <button type="button" className="btn btn-outline-success" style={{borderRadius:'5px'}} onClick={handleSubmit}>Make Payment</button>
        </div>
        </div>
        </div>
        </div>
              </div>
        </Header>
        </div>
    )
}

export default MakePaymentComponent;