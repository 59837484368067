import React, {useState, useEffect } from 'react';
import Header from '../../Common/Header';
import { Link } from 'react-router-dom'
import api_url from '../../../../utils/apiURL';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import EditDetails from './Edit';


const UserDetailsComponent = () => {

    let userId = localStorage.getItem('UserID');
    let [userDetails, setUserDetails] = useState([]);
    let [unitDetails, setUnitDetails] = useState([]);
    let [loading, setLoading] = useState(false);
    let [showModal, setShowModal] = useState(false);

    let handleShowModal = () => {
        setShowModal(!showModal)
    }
    console.log("unit details :: ",unitDetails);

    let tableData = unitDetails.length && unitDetails.map((val,i) => {
        return(
                      <tr key={i}>
                        <td>
                            {val.title}
                        </td>
                        <td>
                            {val.address+", "+val.city+", "+val.state}
                        </td>
                        <td>
                            {val.zipcode}
                        </td>
                        <td>
                            {(val.status == '1') ? "Developed" : "Un-Developed"}
                        </td>
                        {/* <td>
                            <Link to="/user/details/view-lot" state={{ unitDetails: val }}>
                            <button onClick={handleShowModal} className="btn btn-outline-info" style={{borderRadius:'5px'}}>
                                View
                            </button>
                            </Link>
                        </td> */}
                    </tr>
        )
    }) 

    useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();

        setLoading(true);

        let data = {
            id: userId
        }

        axios.post(api_url+"/api/admin/owner/user",data).then((response) => {
            console.log(response)
            setUserDetails(response.data.data)

                axios.post(api_url+"/api/u1/units/owner/untis",data).then(result => {
                    setUnitDetails(result.data.data)
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                })

                setLoading(false);
            
        }).catch(err => {
            console.log(err);
        })

        return () => {
            source.cancel();
        }
        
    },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">
                  {loading ? <div className="text-center" style={{padding:'10px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}} />
                  </div> : <div className="card-body">
                  <h4 className="card-title">Owner Details</h4>
                  {/* <div className="text-end">
                  <button onClick={handleShowModal} className="btn btn-outline-info" style={{borderRadius:'5px'}}>
                      Edit
                  </button>
                  </div> */}
                  <div className="col-md-6">
                    
                      {/* <p className="card-description">All your units will be listed here.</p> */}
                      <div className="template-demo">
                        <h5>
                            Name
                        </h5>
                        <p className="card-description">{userDetails.length && userDetails[0].name}</p>
                        <h5>
                            Email
                        </h5>
                        <p className="card-description">{userDetails.length && userDetails[0].email}</p>
                        <h5>Lots Assigned</h5>
                        <p className="card-description">{unitDetails.length}</p>
                        <h5>
                            Organization
                        </h5>
                        <p className="card-description">Home Owner Association</p>
                        {/* <h5>
                            Lot
                        </h5>
                        <p className="card-description">{unitDetails.length && unitDetails[0].title}<br/>{unitDetails.length && unitDetails[0].address+", "+unitDetails[0].city+", "+unitDetails[0].state+", "+unitDetails[0].zipcode}</p>
                        <h5>
                            Lot status
                        </h5>
                        <p className="card-description">{unitDetails.length && (unitDetails[0].status == '1' ? "Developed" : "Un-Developed")}</p> */}

                      {/* {showModal && <EditDetails show={showModal} setShow={setShowModal} unit={unitDetails} user={userDetails} />} */}
                      </div>
                    
                  </div>
                  <div className="col-md-10">
                  {unitDetails.length && <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Lot Title
                          </th>
                          <th>
                            Address
                          </th>
                          <th>
                            Zipcode
                          </th>
                          <th>
                              Lot status
                          </th>
                          {/* <th>
                              Action
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData}
                      </tbody>
                    </table>
                  </div>}
                  </div>
                  </div>}
                  
              

                  </div>
                  <br />
                  <div className="card">
              </div>
              </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default UserDetailsComponent;