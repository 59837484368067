import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal, CloseButton } from 'react-bootstrap';
import api_url from '../../../../utils/apiURL';
import Swal from 'sweetalert2';

const RecordPaymentComponent = (props) => {

  let [invoiceList, setInvoiceList] = useState([]);

  let optionsData = props.units && props.units.map((val,ind) => {
    return(
      <option value={val.id}>{val.title}</option>
    )
  })

  let invoiceOptions = invoiceList.map((val,i) => {
    return(
      <option value={val.id}>{val.title}</option>
    )
  })

  console.log("invoices list of single unit :: ",invoiceList);

  let handleUnit = async (event) => {
    props.pdata((values) => ({
      ...values,
      unitId: event.target.value
    }))
    let data = {
      unitId: event.target.value
    }
    await axios.post(api_url+"/api/admin/invoices/owner-invoice",data).then((response) => {
      setInvoiceList(response.data.data)
    }).catch((err) => {
      console.log(err);
    })
  }

  let handlePaydate = (event) => {
    props.pdata((values) => ({
      ...values,
      payDate: event.target.value
    }))
  }

  let handleAmount = (event) => {
    props.pdata((values) => ({
      ...values,
      amount: event.target.value
    }))
  }

  let handleBalance = (event) => {
    props.pdata((values) => ({
      ...values,
      balance: event.target.value
    }))
  }

  let handleTotal = (event) => {
    props.pdata((values) => ({
      ...values,
      total: event.target.value
    }))
  }

  let handleMethod = (event) => {
    props.pdata((values) => ({
      ...values,
      method: event.target.value
    }))
  }

  let handleNote = (event) => {
    props.pdata((values) => ({
      ...values,
      note: event.target.value
    }))
  }

  let handleInvoices = (event) => {

    let findAmount = invoiceList.find(element => {
    if(element.id == event.target.value){
      return element.amount
    }
    })

    props.pdata((values) => ({
      ...values,
      invoiceId: event.target.value,
      total: findAmount.amount
    }))


    console.log("array find result :: ",findAmount.amount)
  }

  console.log("props pdetail here :: ",props.pdetail)

  let handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      unit_id: props.pdetail.unitId,
      paydate: props.pdetail.payDate,
      amount: props.pdetail.amount,
      method: props.pdetail.method,
      note: props.pdetail.note,
      total: props.pdetail.total,
      invoice_id: props.pdetail.invoiceId
    }

    console.log("data sent is here :: ",data);

    if(props.pdetail.unitId !== ''){
        if(props.pdetail.invoiceId !== ''){
          if(props.pdetail.payDate !== ''){
            if(props.pdetail.amount !== ''){
              if(props.pdetail.total !== ''){
                if(props.pdetail.method !== ''){
                    axios.post(api_url+"/api/admin/payment/record",data).then(response => {
                      console.log("Response from api :: ",response);
                      Swal.fire(
                        'Success',
                        'Payment Successfull.',
                        'success'
                      )
                      props.pdata({
                        unitId: '',
                        payDate: '',
                        amount: '',
                        method: '',
                        note: '',
                        balance: '',
                        total: '',
                        invoiceId: ''
                      })
                      props.setShow(false)
                    }).catch(err => {
                      Swal.fire(
                        'Failed',
                        'Payment Failed!',
                        'error'
                      )
                      console.log(err);
                    })
                      
                }    
                else{
                  Swal.fire(
                    'Fields incomplete',
                        'Fill all fields to continue.',
                        'error'
                  )
                }
              }    
              else{
                Swal.fire(
                  'Fields incomplete',
                      'Fill all fields to continue.',
                      'error'
                )
              }
            }
            else{
              Swal.fire(
                'Fields incomplete',
                    'Fill all fields to continue.',
                    'error'
                  )
            } 
          } 
          else{
      Swal.fire(
        'Fields incomplete',
            'Fill all fields to continue.',
            'error'
          )
        }
      }    
        else{
          Swal.fire(
            'Fields incomplete',
                'Fill all fields to continue.',
                'error'
          )
        }
      }    
      else{
        Swal.fire(
          'Fields incomplete',
              'Fill all fields to continue.',
              'error'
        )
      }
      
    }

    return(
        <Modal
      size="lg"
      show={props.show}
      centered
    >
      <Modal.Header>
        
        <Modal.Title id="contained-modal-title-vcenter">
          Record Payment
        </Modal.Title>
          <CloseButton onClick={() => props.setShow(false)} />
      </Modal.Header>
      {/* <div className="col-sm-12 text-start">
      <form className="form-inline">
      <div className="form-control" style={{border:'none'}}>
        <label className="form-check-label">
          <input type="radio" className="form-check-input" defaultChecked />
          One-Time
        </label>
      </div>
      <div className="form-control" style={{border:'none'}}>
        <label className="form-check-label">
          <input type="radio" className="form-check-input" />
          Reccuring
        </label>
      </div>
      </form>
      </div> */}

      <Modal.Body>
        <div className="row">
        <div className="col-md-6">
        <form className="forms-sample">
                    <div className="form-group">
                      <label>Payor<code>*</code></label>
                      <select required onChange={handleUnit} className="form-control form-control-sm">
                        <option value="">-- Select Unit --</option>
                        {optionsData}
                      </select>
                    </div>
                    {(invoiceList.length === 0) ? <div className="form-group">
                      <label>Invoice</label>
                      <select disabled onChange={handleInvoices} className="form-control form-control-sm">
                        <option value=""> -- No Invoice Found. Create Invoice to record a Payment. --</option> 
                      </select>
                    </div> : <div className="form-group">
                      <label>Invoice</label>
                      <select required onChange={handleInvoices} className="form-control form-control-sm">
                        <option value="">-- Select Invoice --</option>
                        {invoiceOptions}
                      </select>
                    </div>}
                    {/* <div className="form-group">
                      <label>Owner</label>
                      <input type="text" disabled className="form-control form-control-sm" placeholder="Property Owner" />
                    </div> */}
                    <div className="form-group">
                      <label>Method</label>
                      <select required onChange={handleMethod} className="form-control form-control-sm">
                        <option value="">-- Select Payment Method -- </option>
                        <option value="5">Bank Transfer</option>
                        <option value="3">Cash</option>
                        <option value="4">Cheque</option>
                        <option value="1">Online</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Payment Date</label>
                      <input required onChange={handlePaydate} type="date" className="form-control form-control-sm" />
                    </div>
                  </form>
        </div>
        <div className="col-md-6">
        <form className="forms-sample">
                    <div className="form-group">
                      <label>Total Amount</label>
                      <input disabled required value={props.pdetail.total} type="text" className="form-control form-control-sm" placeholder="$0.00" />
                    </div>
                    <div className="form-group">
                      <label>Amount Paid</label>
                      <input required onChange={handleAmount}  type="number" min={1} max={props.pdetail.total} className="form-control form-control-sm" placeholder="$0.00" />
                    </div>
                    {/* <div className="form-group">
                      <label>Balance</label>
                      <input required onChange={handleBalance} type="text" className="form-control form-control-sm" placeholder="$0.00" />
                    </div> */}
                    <div className="form-group">
                      <label>Note</label>
                      <input onChange={handleNote} type="text" className="form-control form-control-sm" placeholder="Note" />
                    </div>
                  </form>
        </div>
        </div>
        <br />
        {/* {showLateFee ? <> <div className="col-sm-12 text-start">
      <form className="form-inline">
      <div className="form-control" style={{border:'none'}}>
        <label className="form-check-label">
          <input type="radio" className="form-check-input" defaultChecked />
          One-Time Late Fee
        </label>
      </div>
      <div className="form-control" style={{border:'none'}}>
        <label className="form-check-label">
          <input type="radio" className="form-check-input" />
          Reccuring Late Fee
        </label>
      </div>
      </form>
      </div>
        
        <div className="row">
        <div className="col-md-12">
        <div className="col-sm-12">
      <form className="form-inline">
      <div className="form-group">
        <label>Fee Amount</label>
        <input type="text" className="form-control form-control-sm" placeholder="Amount" style={{marginLeft:'5px',marginTop:'-4px'}} />
      </div>
      <div className="form-group" style={{marginLeft: '50px'}}>
        <label>Applies on</label>
        <input type="date" className="form-control form-control-sm" style={{marginLeft:'5px',marginTop:'-4px'}} />
      </div>
      </form>
      </div>
        </div>
        </div> </> : <Button variant="info" onClick={() => handleShowLateFee()}>Add Late Fee</Button>} */}
        
      
      </Modal.Body>
      <Modal.Footer>
        <Button style={{borderRadius:'5px'}} onClick={handleSubmit}>Add Payment</Button>
      </Modal.Footer>
    </Modal>
    )
}

export default RecordPaymentComponent;