import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import { Link, useLocation } from 'react-router-dom';
import api from '../../../utils/apiURL';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import _ from 'lodash';

const ViewPeopleComponent = () => {
    let location = useLocation();
    let { state } = location;
    console.log("state :: ",state);

    let [details, setDetails] = useState([]);
    let [allInvoices, setAllInvoices] = useState([])
    let [loading, setLoading] = useState(false);
    let [chargeData, setChargeData] = useState([]);

let unitData = details.length && details.map((val,i) => {

  let sumPending;
if(chargeData.length){

  let pending = chargeData.length && chargeData.map((itm,ind) => {
    if(val.id == itm.lot_id){
      return itm.balance
    }
  })
  sumPending = _.sum(pending)
  return(
    <tr key={i}>
        <td>{val.title}</td>
        <td>{val.address+", "+val.state+", "+val.city}</td>
        <td>{val.zipcode}</td>
        <td>${sumPending}</td>
    </tr>
)
}
else{
  return(
    <tr key={i}>
        <td>{val.title}</td>
        <td>{val.address+", "+val.state+", "+val.city}</td>
        <td>{val.zipcode}</td>
        <td>$0</td>
    </tr>
)
}
})

console.log("details asdfadsf :: ",details)
                
    useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();
        setLoading(true);

        let data = {
            id: state.owner.uuid
        }

        axios.post(api+"/api/admin/units/owner/pivot",data).then(response => {
            setDetails(response.data.data)
            // console.log("response success", response)
            let getIds = response.data.data.map((val,ind) => {
              return val.id
            })

            let data2 = {
              ids: getIds
            }

            axios.post(api+"/api/admin/invoices/unpaid",data2).then(result => {
                setAllInvoices(result.data.data.invoice)
                setChargeData(result.data.data.pivot)
                setLoading(false);
            }).catch(error => {
                console.log("error occured in invoice api :: ",error)
            })
            
            setLoading(false)
        }).catch(err => {
            if(axios.isCancel(err)){
              console.log("Successfully aborted !!");
            }
            else{
              console.log(err);
            }
          })

        return(() => {
            source.cancel()
        })
    },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">
              {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <div className="card-body">
                  <h4 className="card-title">People</h4>
                  <div className="col-md-12">
                      <p className="card-description">User Details</p>
                      <div className="template-demo">
                          <div className="text-end">
                              <Link to="/admin/people"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} className="btn btn-outline-info">Back</button></Link>
                          </div>
                          <br/>
                          <h5>
                            Name
                        </h5>
                        <p className="card-description">{Object.keys(state.owner).length && state.owner.name}</p>
                        <h5>
                            Email
                        </h5>
                        <p className="card-description">{Object.keys(state.owner).length && state.owner.email}</p>
                        <h5>
                            Lots Assigned
                        </h5>
                        <p>{details.length && details.length}</p>
                        <br />
                        <h5>
                            Lot Details
                        </h5>
                        <br />
                        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Lot Title
                          </th>
                          <th>
                            Address
                          </th>
                          <th>
                            Zipcode
                          </th>
                          <th>
                            Pending Payment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          {details.length ? <>{unitData}</> : <tr>
                              <td>
                                  No Lot Assigned to this owner
                              </td>
                            </tr>}
                      </tbody>
                    </table>
                  </div>
                      </div>
                    <br />
                  </div>
                  </div>}

                  </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default ViewPeopleComponent;