import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import api from '../../../../utils/apiURL';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { FaFilter } from 'react-icons/fa';
import _ from 'lodash';
import Filter from '../../Common/Filter';


const RevenueReportComponent = () => {

  let navigate = useNavigate();

  let [showModal, setShowModal] = useState(false);
  let [unitDetails, setUnitDetails] = useState([]);
  let [recentPayment, setRecentPayment] = useState([]);
  let [loading, setLoading] = useState(false);
  let [pageNum, setPageNum] = useState(2);
  let [totalPages, setTotalPages] = useState(0);
  let [pivotDetails, setPivotDetails] = useState([]);
  let [invoicesData, setInvoicesData] = useState([]);
  let [chargeData, setChargeData] = useState([]);
  let [unpaidPivot, setUnpaidPivot] = useState([]);
  let [paidPivot, setPaidPivot] = useState([]);
  let [filter, setFilter] = useState({
    from: '',
    to: ''
  })
  let [show, setShow] = useState(false);

  console.log("Pivot Details here :: ",pivotDetails)

  let test = [];
  let test2 = []; 
  let invoiceTotal = [];

  let invTotal = invoicesData.length && invoicesData.map((val,i) => {
    invoiceTotal.push(val.total);
  })

  let TotalAmount = pivotDetails.length && pivotDetails.map((val,ind) => {
    test.push(val.charge_amount)
  })

  let TotalAmount2 = unpaidPivot.length && unpaidPivot.map((val,i) => {
    test.push(val.charge_amount)
  })

  let PaidAmount = pivotDetails.length && pivotDetails.map((val,i) => {
     test2.push(val.charge_amount)
  })

  let PaidAmount2 = unpaidPivot.length && unpaidPivot.map((val,i) => {
    test2.push(val.charge_amount - val.balance)
  })

  let BalAmount = unpaidPivot.length && unpaidPivot.map((val,i) => {
    return val.balance
  })

  let totalGenerated = _.sum(invoiceTotal);
  let totalPaid = _.sum(test2);
  let totalBal = _.sum(BalAmount);

  console.log("invoice details in revenue report :: ",invoicesData)

  let newTableData = invoicesData.length && invoicesData.map((val,i) => {
    return(
      <tr key={i}>
        <td>{val.invoice_title}</td>
        <td>{val.lot_title}</td>
        <td>${val.total}</td>
        <td>{(val.paid == 1) ? "Paid" : "Not-Paid"}</td>
        <td>
          <Link to="/admin/report/revenue/view" state={{invoice: val, charge: chargeData}}>
            <button className="btn btn-outline-info" style={{borderRadius: '5px'}}>View</button>
          </Link>
        </td>
      </tr>
    )
  })

  let tableData = recentPayment.length && recentPayment.map((val, ind) => {
    let getUnit = unitDetails.find((value) => value.id === val.unit_id);
    let paydate = val.paydate.split('T')
    // console.log("Get unit test :: ",getUnit)
    let method1 = "Online";
    let method2 = "Offline";
    let method3 = "Cash";
    let method4 = "Cheque";
    let method5 = "Bank Transfer";
    return(
      <tr key={ind}>
        <td>{getUnit.title || 'Unable to fetch Lot Title'}</td>
        <td>{paydate[0]}</td>
        <td>{val.amount_paid}</td>
        <td>{val.total_amount}</td>
        <td>{val.balance}</td>
        <td>{(val.method == 1) ? method1 : (val.method == 2 ? method2 : (val.method == 3 ? method3 : (val.method == 4 ? method4 : method5)))}</td>
        <td>{val.note}</td>
      </tr>
    )
  })

  // console.log("payment details from api :: ",totalPages)

  let handleShowModal = () => {
    setShow(!show)
  }

  let testTotalPage = Math.ceil(totalPages/10)
console.log("Total pages in api response",testTotalPage)

  let handleLoadMore = () => {

    setLoading(true)

    if(filter.from != "" && filter.to != ""){
      if(pageNum > testTotalPage){
        Swal.fire(
          'No more records',
          'No more records found!',
          'info'
        )
      }
      else if(pageNum <= testTotalPage){
        let data = {
          page: pageNum,
          from: filter.from,
          to: filter.to
        }

        console.log("in correct check !!")

        axios.get(api+`/api/admin/invoices/from-to`,data).then(response => {
  
          let test = response.data.data.map((val,id) => {
            return val
          })
  
          setInvoicesData((values) => ([...values, ...test]))
          setLoading(false);
          setPageNum(pageNum + 1)

          let data2 = {
            from: filter.from,
            to: filter.to
          }
          axios.post(api+"/api/admin/invoices/all-paid/from-to",data2).then(res => {
            setPivotDetails(res.data.data)
          }).catch(err => {
            console.log("error occured while getting paid invocies data from pivot table!! ",err);
          })

          axios.post(api+"/api/admin/invoice/all-unpaid/from-to",data2).then(resp => {
            setUnpaidPivot(resp.data.data)
          }).catch(er => {
            console.log("error occured :: ",er)
          })


        }).catch(error => {
          console.log("error :: ",error);
          setInvoicesData({
            id: 0,
            invoice_id: 0,
            unit_id: 0,
            title: '',
            description: '',
            deposite_bank: '',
            category: '',
            amount: 0,
            total_amount: 0,
            active_date: '',
            due_date: '',
            paid: ''
          })
          setLoading(false);
        })
      }
    }

    else  if(filter.from == ""){
      if(pageNum > testTotalPage){
        Swal.fire(
          'No more records',
          'No more records found!',
          'info'
        )
      }
      else if(pageNum <= testTotalPage){
        console.log("in filter.from == null check")
        axios.get(api+`/api/admin/invoices/units?page=${pageNum}`).then(response => {
  
          let test = response.data.data.map((val,id) => {
            return val
          })
  
          setInvoicesData((values) => ([...values, ...test]))
          setLoading(false);
          setPageNum(pageNum + 1)
        }).catch(error => {
          console.log("error :: ",error);
          setInvoicesData({
            id: 0,
            invoice_id: 0,
            unit_id: 0,
            title: '',
            description: '',
            deposite_bank: '',
            category: '',
            amount: 0,
            total_amount: 0,
            active_date: '',
            due_date: '',
            paid: ''
          })
          setLoading(false);
        })
      }
    }

    else{
      console.log("in else check ::")
      if(pageNum > testTotalPage){
        Swal.fire(
          'No more records',
          'No more records found!',
          'info'
        )
      }
      else if(pageNum <= testTotalPage){
        axios.get(api+`/api/admin/invoices/units?page=${pageNum}`).then(response => {
  
          let test = response.data.data.map((val,id) => {
            return val
          })
  
          setInvoicesData((values) => ([...values, ...test]))
          setLoading(false);
          setPageNum(pageNum + 1)
        }).catch(error => {
          console.log("error :: ",error);
          setInvoicesData({
            id: 0,
            invoice_id: 0,
            unit_id: 0,
            title: '',
            description: '',
            deposite_bank: '',
            category: '',
            amount: 0,
            total_amount: 0,
            active_date: '',
            due_date: '',
            paid: ''
          })
          setLoading(false);
        })
      }
    }

  }

console.log("filter : ",filter)

  useEffect(() => {

    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);
    let isAuthenticated = localStorage.getItem('isAuthenticated');

    if(isAuthenticated == 'false' || isAuthenticated == false) {
      console.log("inside if condition useEffect...  ",isAuthenticated);
      return navigate("/admin/login")
    }

    else {
      console.log("inside else condition useEffect... ",isAuthenticated);
    axios.get(api+"/api/admin/payment/count").then(res => {
      setTotalPages(res.data.data[0].total)
    }).catch(err => {
      console.log("something went wrong :: ",err)
    })

    axios.get(api+"/api/admin/invoices/all-paid").then(res => {
      setPivotDetails(res.data.data)
    }).catch(err => {
      console.log("error occured while getting paid invocies data from pivot table!! ",err);
    })

    axios.get(api+"/api/admin/invoices/all-unpaid").then(resp => {
      setUnpaidPivot(resp.data.data)
    }).catch(er => {
      console.log("error occured :: ",er)
    })

        setLoading(false);
  }

  axios.get(api+"/api/admin/invoices/admin/count").then(response => {
    setTotalPages(response.data.data[0].total)
  }).catch(err => {
    setTotalPages(0)
    console.error("Failed to get total admin invoices pages",err)
  })

  axios.get(api+`/api/admin/invoices/units?page=${1}`).then(response => {
    setInvoicesData(response.data.data)

    axios.get(api+"/api/admin/invoices/charges").then(result => {
      setChargeData(result.data.data)
    }).catch(err => {
      console.log("error :: ",err)
    })

    setLoading(false);
  }).catch(error => {
    console.log("error :: ",error);
    setInvoicesData({
      id: 0,
      title: '',
      description: '',
      deposite_bank: '',
      total_amount: '',
      category: '',
      charge_id: [],
      lot_status: ''
    })
    setLoading(false);
  })

    return () => {
      source.cancel()
    }

  },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">

          <div className="col-lg-12 grid-margin">
          {show && <Filter show={show} setShow={setShow} setInvoice={setInvoicesData} filter={filter} setFilter={setFilter} setPage={setPageNum} setLoading={setLoading} setPaid={setPivotDetails} setUnpaid={setUnpaidPivot} comp={"revenue"}  /> }

                  {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <><div className="card">
              <div className="card-body">
                  <h4 className="card-title">Revenue Reports</h4>
                  <h4 className="title">Summary</h4>
                  <h6 className="title">Total Invoice Generated</h6>
                  <p className="card-description">${totalGenerated}</p>
                  <h6 className="title">Amount Received</h6>
                  <p className="card-description">${totalPaid}</p>
                  <h6 className="title">Outstanding</h6>
                  <p className="card-description">${totalBal}</p>
                  <div className="col-md-12">

                      <div className="template-demo">

                        <div className="text-end">
                          
                            <button type="button" style={{borderRadius:'5px', marginRight:'4px'}} onClick={handleShowModal} className="btn btn-outline-info">
                                <FaFilter style={{marginRight:'5px'}} />
                            </button>
                        </div>

                      </div>
                    
                  </div>
                  </div>

                  </div>
                  <br />
                  <div className="card">
 <div className="card-body">
    <h4 className="card-title">Report Details</h4>
    <div className="col-md-8">
        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Invoice Title
                          </th>
                          <th>
                            Lot Status
                          </th>
                          <th>
                            Total Amount
                          </th>
                            <th>
                              Status
                            </th>
                            <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoicesData.length ? <>{newTableData}</> : <tr>
                          <td>No Recent Transactions found.</td>
                          </tr>}
                        </tbody>
                        </table>
                        </div>

        </div>
        <br />
      {(pageNum <= testTotalPage) ? <div className="text-center">
        <button onClick={handleLoadMore} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Load more</button>
      </div> : null}
    </div>
    </div>
    </div> </> }



                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default RevenueReportComponent;