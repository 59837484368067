import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../../assets/img/light1.svg';
import axios from 'axios';
import api from '../../../utils/apiURL';
import Swal from 'sweetalert2';

const VerifyOTPComponent = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  let { state } = location;
  console.log("state in verify account hook :: ",state)
/*
 ----------------
 STATE DECLARATION 
 ----------------
*/
let [userDetails, setUserDetails] = useState({
  id: '',
  otp: ''
});

/*
-----------------
FUNCTION'S
-----------------
*/
  let handleOtp = (e) => {
    setUserDetails((values) => ({
      ...values,
      otp: e.target.value
    }))
  }

let handleSubmit = (event) => {
  event.preventDefault();

let data = {
  id: userDetails.id,
  otp: userDetails.otp
}

axios.post(api+"/api/admin/owner/verify",data).then((response) => {
  if(response.data.status == true || response.data.status == 'true'){
    Swal.fire(
      'Account Verified',
      'Login to continue',
      'success'
    )
    navigate('/admin/login')
  }
  else if(response.data.status == false || response.data.status == 'false'){
    Swal.fire(
      'Account not verified',
      'Login to verify account again',
      'warning'
    )
    navigate('/admin/login')
  }
}).catch((error) => {
  Swal.fire(
    'Failed to verify account',
    'Something went wrong! try again later.',
    'error'
  )
})

  alert("Account Verified !!");
  navigate("/admin/login")
}

 useEffect(() => {
  setUserDetails({
    id: state.id
  })
 },[])

    return(

        <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Enter OTP to continue.</h6>
                  <form className="pt-3" onSubmit={handleSubmit}>

                    <div className="form-group">
                      <input type="number" onChange={handleOtp} className="form-control form-control-lg" placeholder="Enter OTP" />
                    </div>
                    <div className="mt-3">
                      <input type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" value="VERIFY" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    )
}

export default VerifyOTPComponent;