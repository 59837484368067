import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import { BsPlusLg } from 'react-icons/bs';
import RecordPayment from './recordPayment';
import api from '../../../utils/apiURL';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const PaymentComponent = () => {

  let navigate = useNavigate();

  let [showModal, setShowModal] = useState(false);
  let [unitDetails, setUnitDetails] = useState([]);
  let [paymentDetails, setPaymentDetails] = useState({
    unitId: '',
    payDate: '',
    amount: '',
    method: '',
    note: '',
    balance: '',
    total: '',
    invoiceId: ''
  })
  let [recentPayment, setRecentPayment] = useState([]);
  let [loading, setLoading] = useState(false);
  let [pageNum, setPageNum] = useState(2);
  let [totalPages, setTotalPages] = useState(0);



  console.log("recent payments :: ",recentPayment)

  let tableData = recentPayment.length && recentPayment.map((val, ind) => {
    let getUnit = unitDetails.find((value) => value.id === val.unit_id);
    let paydate = val.paydate.split('T')
    // console.log("Get unit test :: ",getUnit)
    let method1 = "Online";
    let method2 = "Offline";
    let method3 = "Cash";
    let method4 = "Cheque";
    let method5 = "Bank Transfer";
    return(
      <tr key={ind}>
        <td>{getUnit.title || 'Unable to fetch Lot Title'}</td>
        <td>{paydate[0]}</td>
        <td>${val.amount_paid}</td>
        <td>${val.total_amount}</td>
        <td>${val.balance}</td>
        <td>{(val.method == 1) ? method1 : (val.method == 2 ? method2 : (val.method == 3 ? method3 : (val.method == 4 ? method4 : method5)))}</td>
        <td>{val.note}</td>
      </tr>
    )
  })

  // console.log("payment details from api :: ",totalPages)

  let handleShowModal = () => {
    setShowModal(!showModal)
  }

  let testTotalPage = Math.ceil(totalPages/10)
console.log("Total pages in api response",testTotalPage)

  let handleLoadMore = () => {

    setLoading(true)

    // let testTotalPage = Math.ceil(totalPages/10)
    if(pageNum > testTotalPage){
      Swal.fire(
        'No more records',
        'No more records found!',
        'info'
      )
    }
    else if(pageNum <= testTotalPage){
      axios.get(api+`/api/admin/payment/recent?page=${pageNum}`).then(result => {
        let test = result.data.data.map((val,ind) => {
          return val
        })
        setRecentPayment((values) => ([...values, ...test]));
        setLoading(false);
        setPageNum(pageNum + 1)
      }).catch(error => {
        console.log(error);
      })
    }
console.log(pageNum)

  }
  console.log("outside function",pageNum)



  useEffect(() => {

    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);
    let isAuthenticated = localStorage.getItem('isAuthenticated');

    if(isAuthenticated == 'false' || isAuthenticated == false) {
      console.log("inside if condition useEffect...  ",isAuthenticated);
      return navigate("/admin/login")
    }

    else {
      console.log("inside else condition useEffect... ",isAuthenticated);
    axios.get(api+"/api/admin/payment/count").then(res => {
      setTotalPages(res.data.data[0].total)
    }).catch(err => {
      console.log("something went wrong :: ",err)
    })

    axios.get(api+'/api/admin/units/allUnits').then(response => {
      console.log(response)
      setUnitDetails(response.data.data)
      axios.get(api+`/api/admin/payment/recent?page=${1}`).then(result => {
        setRecentPayment(result.data.data);
        setLoading(false);
      }).catch(error => {
        console.log(error);
        setRecentPayment({
          id: 0,
          payment_id: 0,
          unit_id: 0,
          owner_id: 0,
          invoice_id: 0,
          paydate: '',
          amount_paid: 0,
          total_amount: 0,
          balance: 0,
          method: '',
          reference_id: '',
          note: ''
        })
        setLoading(false);

      })
    }).catch(err => {
      if(axios.isCancel(err)){
        console.log("Successfully aborted !!")
      }
      else{
        console.log("error occured in api :: ",err);
        setUnitDetails({
          id: 0,
          unit_id: 0,
          owner_id: 0,
          title_id: 'No Unit found.',
          address: '',
          city: '',
          state: '',
          zipcode: '',
          status: ''
        })
        setLoading(false);

      }
    })
  }

    return () => {
      source.cancel()
    }

  },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">

          <div className="col-lg-12 grid-margin">
              <div className="card">
              <div className="card-body">
                  <h4 className="card-title">Payments</h4>
                  <div className="col-md-12">
                    
                      <p className="card-description">All your Payments will be listed here.</p>
                      <div className="template-demo">
                        {/* <h5>
                            Funds Collected
                        </h5>
                        <p className="card-description">$43,098</p> */}
                        <div className="text-end">
                          <Link to="/admin/payments/record">
                            <button type="button" style={{borderRadius:'5px', marginRight:'4px'}} className="btn btn-outline-info">
                                <BsPlusLg style={{marginRight:'5px'}} />
                                Record Payment
                            </button>
                          </Link>
                            {/* <Link to="/admin/payments/approve">
                            <button onClick={() => handleShowModal()} type="button" style={{borderRadius:'5px', marginLeft:'4px'}} className="btn btn-outline-info">
                                View Payment Requests
                            </button>
                            </Link> */}
                        </div>
                            
                      {showModal ? <RecordPayment pdetail={paymentDetails} pdata={setPaymentDetails} units={unitDetails} show={showModal} setShow={setShowModal} /> : null}
                      </div>
                    
                  </div>
                  </div>

                  </div>
                  <br />
                  <div className="card">
                  {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <div className="card-body">
    <h4 className="card-title">Payments Details</h4>
    <div className="col-md-12">
        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Payor
                          </th>
                          <th>
                            Pay Date
                          </th>
                          <th>
                            Amount Paid
                          </th>
                          <th>
                            Total Amount
                          </th>
                          <th>
                            Net Amount
                          </th>
                          <th>
                            Type
                          </th>
                          <th>
                            Note
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        {recentPayment.length ? <>{tableData}</> : <tr>
                          <td>No Recent Transactions found.</td>
                          </tr>}
                        {/* {tableData} */}
                        </tbody>
                        </table>
                        </div>

        </div>
        <br />
      {(pageNum <= testTotalPage) ? <div className="text-center">
        <button onClick={handleLoadMore} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Load more</button>
      </div> : null}
    </div>
    </div> }


    </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default PaymentComponent;