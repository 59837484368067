import React from 'react';

const HeroComponent = () => {
    return(
      <section id="hero-blogs">
      <div className="hero-blogs-container" data-aos="zoom-in" data-aos-delay={100}>
        
        <h1>HOA Association</h1>
        
        <h2>The All-in-One Software <br/> To Manage Your HOA 100% Online.</h2>
        <a href="#about" className="btn-get-started">Get Started</a>
      </div>
    </section>
    )
}

export default HeroComponent;