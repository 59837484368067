import React, { useState, useEffect } from 'react';
import Header from '../../../Common/Header';
import { Link, useLocation } from 'react-router-dom';
// import Edit from './Edit';
import _ from 'lodash';
import axios from 'axios';
import api from '../../../../../utils/apiURL';

const ViewRevenueComponent = () => {
    let location = useLocation();
    let { state } = location;

    console.log("state in view invoice component :: ",state)

    let [show, setShow] = useState(false);
    let [unitDetails, setUnitDetails] = useState([]);
    let [pivot, setPivot] = useState([]);

    console.log("unit details in vier component :: ",unitDetails);

  let pivotData = pivot.length && pivot.map((val,i) => {
    return val.balance
  })

  let total_bal = _.sum(pivotData)

    let total_arr2 = Object.keys(state.charge).length && state.charge.map((val,i) => {
      return val.amount_undev
    })

    let ids = JSON.parse(state.invoice.charge);

    let total_arr = [];
    let total_test = Object.keys(state.charge).length && state.charge.map((val,i) => {
      return ids.map((itm,ind) => {
        if(val.id == itm){
          total_arr.push(val.amount_dev)
        }
      })
  })

    let charge_details = Object.keys(state.charge).length && state.charge.map((val,ind) => {
      return pivot.length && pivot.map((value, index) => {
        return ids.map((itm,i) => {
          if(val.id == itm && value.charge_id == itm){
              return(
                <tr key={i}>
                <td>
                    {val.charge_title}
                </td>
                {/* <td>
                    {val.charge_description}
                </td> */}
                <td>
                  {(state.invoice.lot_status == 1) ? <> ${val.amount_dev} </> : <> ${val.amount_undev} </>}
                </td>
                <td>
                  ${value.balance}
                </td>
                <td>
                    {(val.schedule == 1 || val.schedule == '1') ? "Monthly Charge" : ((val.schedule == 2 || val.schedule == '2') ? "Quaterly Charge" : ((val.schedule == 3 || val.schedule == '3') ? "Semi-Annual Charge" : ((val.schedule == 4 || val.schedule == '4') ? "Annual" : "One Time")))}
                </td>
                <td>
                    {(val.recurring == 1 || val.recurring == '1') ? "True" : "False"}
                </td>
                <td>
                    {(val.limit_recurring == 0 || val.limit_recurring == '0') ? 'Infinite' : val.limit_recurring }
                </td>
                <td>
                  {(state.invoice.lot_status == 1) ? <> ${val.amount_dev} </> : <> ${val.amount_undev} </>}
                </td>
            </tr>
              )
          }
      })
      })
    })

    let total_amount = _.sum(total_arr);
    let total_amount2 = _.sum(total_arr2);
    console.log("total amount :: ",total_amount)
    console.log("state in view invoice component :: ",state);

    useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();

        let data = {
            unitId: state.invoice.lot_id
        }
        let data2 = {
          lot: state.invoice.lot_id
        }
        axios.post(api+"/api/admin/units/getUnit",data).then(response => {
            setUnitDetails(response.data.data);
        }).catch(err => {
            console.log("unable to get unit details :: ",err);
        })

        axios.post(api+"/api/admin/invoices/unpaid/pivot",data2).then(result => {
          setPivot(result.data.data)
        }).catch(err => {
          console.log("failed to get pivot details :: ",err)
        })

        return () => {
            source.cancel()
          }
    },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Invoice</h4>
                  <div className="col-md-12">

                      <p className="card-description">Invoice Details</p>
                      <div className="template-demo">
                          <div className="text-end">
                              <Link to="/admin/report/revenue"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} className="btn btn-outline-info">Back</button></Link>
                          </div>
                          <br/>
                          <h5>
                           Invoice Title
                        </h5>
                        <p className="card-description">{Object.keys(state.invoice).length ? state.invoice.invoice_title : "Unable to get invoice details"}</p>
                        <h5>
                            Lot
                        </h5>
                        <p className="card-description">{Object.keys(state.invoice).length ? state.invoice.lot_title : "Lot not found !!"}</p>
                        {/* <h5>Total Amount</h5>
                        <p className="card-description">{ Object.keys(state.invoice).length ? state.invoice.total_amount : "Unable to get invoice details"}</p>
                        <h5>
                            Deposit Bank
                        </h5>
                        <p className="card-description">{ Object.keys(state.invoice).length ? state.invoice.deposite_bank : "Unable to get invoice details"}</p> */}
                        <h5>
                            Total Amount of Invoice
                        </h5>
                        <p className="card-description">
                           ${Object.keys(state.invoice).length ? state.invoice.total : "0"}
                        </p>
                        <h5>Outstanding</h5>
                        <p className="card-description">
                          {pivot.length ? <>${total_bal}</> : "$0"}
                        </p>

                        <div className="template-demo">
                        <br />
        <div className="table-responsive">
                          <h4 className="card-title">Charge Details</h4>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Title
                          </th>
                          {/* <th>
                            Description
                          </th> */}
                          {/* <th>
                            Amount for Developed Lot
                          </th> */}
                          <th>
                            Charge Amount
                          </th>
                          <th>
                            Outstanding
                          </th>
                          <th>
                            Schedule
                          </th>
                          <th>
                            Recurring 
                          </th>
                          <th>
                            Limit
                          </th>
                          <th>
                            Summary
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(state.charge).length ? <>{charge_details}</> : <tr>
                          <td>No Charge Available</td>
                          </tr>}
                        {/* {tabledata} */}
                        </tbody>
                        </table>
                        </div>

        </div>

                      </div>
                    <br />
                  </div>
                  {/* <div className="text-end">
                      <button type="button" className="btn btn-outline-info" style={{borderRadius:'5px', marginRight:'5px'}} onClick={() => setShow(!show)}>Edit</button>
                  </div>
                    {show ? <Edit setShow={setShow} invoice={state.invoice} total={total_amount} charge={state.charge} /> : null} */}
                  </div>
                  </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default ViewRevenueComponent;