import React, { useEffect, useState } from 'react';
import Header from '../../Common/Header';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import api from '../../../../utils/apiURL';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';

const AddUnitsComponent = () => {
  
  const navigate = useNavigate();
  
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
//--------------
//STATE DECLARATION
//--------------
    const [selectedFile, setSelectedFile] = useState('');
    const [selectCheck, setSelectCheck] = useState('');
    const [apiUnitDetails, setApiUnitDetails] = useState([]);
    const [ownerDetails, setOwnerDetails] = useState({
      name: '',
      email: '',
      phone: '',
      password: '',
      unit: ''
    });
    const [unitDetails, setUnitDetails] = useState({
      title: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      status: '1'
    });
    let [selectedOwner, setSelectedOwner] = useState();
    const [apiOwnerDetails, setApiOwnerDetails] = useState([]);
//--------------
//STATE DECLARATION ENDS
//--------------
//--------------
//FUNCTION DECLARATION
//--------------
    // let handleImageUpload = (data) => {
    //     console.log(data);
    //     setSelectedFile(data.target.files[0])
    // }

    let handleUnitTitle = (event) => {
      setUnitDetails((values) => ({
        ...values,
        title: event.target.value
      }))
    }

    let handleUnitAddress = (event) => {
      setUnitDetails((values) => ({
        ...values,
        address: event.target.value
      }))
    }

    let handleUnitCity = (event) => {
      setUnitDetails((values) => ({
        ...values,
        city: event.target.value
      }))
    }

    let handleUnitState = (event) => {
      setUnitDetails((values) => ({
        ...values,
        state: event.target.value
      }))
    }

    let handleUnitZipcode = (event) => {
      setUnitDetails((values) => ({
        ...values,
        zipcode: event.target.value
      }))
    }

    let handleCheckbox = (e) => {
      setSelectCheck(e.target.value);
    }
    // console.log("value of checkbox :: ", selectCheck);

    let handleEmail = (e) => {
      setOwnerDetails((values) => ({
        ...values,
        email: e.target.value
      }))
    }

    let handleName = (e) => {
      setOwnerDetails((values) => ({
        ...values,
        name: e.target.value
      }))
      // console.log(e);
    }

    let handlePhone = (e) => {
      setOwnerDetails((values) => ({
        ...values,
        phone: e.target.value
      }))
    }

    let handlePass = (e) => {
      setOwnerDetails((values) => ({
        ...values,
        password: e.target.value
      }))
    }

    let handleOwner = (e) => {
      console.log("e :: ",e)
      console.log("test :: ",e.value)
      let test = e.map((val,i) => {
        return val.value
      })
      setSelectedOwner([...test])
      // setSelectedOwner(e.value)
      // console.log("inside handle owner :: ",e)
      // console.log("unit details here :: ",ownerDetails.unit);
    }
    // console.log(selectedOwner);

    let handleUnitStatus = (e) => {
      setUnitDetails((values) => ({
        ...values,
        status: e.target.value
      }))
      console.log(unitDetails)
    }

    console.log("selected owner :: ",selectedOwner)

    let handleSubmitUnit = async (event) => {
      event.preventDefault();
      let data = {
        title: unitDetails.title,
        address: unitDetails.address,
        city: unitDetails.city,
        state: unitDetails.state,
        zipcode: unitDetails.zipcode,
        status: unitDetails.status,
        ids: selectedOwner
      }

      console.log("data in submit :: ",data)

      if(unitDetails.title == ''){
        Swal.fire(
          'Failed',
          'Lot Title is required !',
          'error'
        )
      }
      else if(unitDetails.address == ''){
        Swal.fire(
          'Failed',
          'Lot Address is required !',
          'error'
        )
      }
      else if(unitDetails.state == ''){
        Swal.fire(
          'Failed',
          'Lot State is required !',
          'error'
        )
      }
      else if(unitDetails.city == ''){
        Swal.fire(
          'Failed',
          'Lot City is required !',
          'error'
        )
      }
      else if(unitDetails.zipcode == ''){
        Swal.fire(
          'Failed',
          'Zipcode is required !',
          'error'
        )
      }
      else if(unitDetails.status == ''){
        Swal.fire(
          'Failed',
          'Select your lot status !',
          'error'
        )
      }
      else{
        await axios.post(api+'/api/admin/units/add',data).then((response) => {
          Swal.fire(
            'Success',
            'New unit added Successfully.',
            'success'
          )
          // Swal.fire({
          //   toast: true,
          //   icon: 'success',
          //   title: 'Posted successfully',
          //   animation: false,
          //   position: 'center',
          //   showConfirmButton: false,
          //   timer: 3000,
          //   timerProgressBar: true,
          //   didOpen: (toast) => {
          //     toast.addEventListener('mouseenter', Swal.stopTimer)
          //     toast.addEventListener('mouseleave', Swal.resumeTimer)
          //   }
          // })
          navigate('/admin/units')
          console.log("response from add unit query :: ",response);
        }).catch((error) => {
          Swal.fire(
            'Failed',
            'Failed to add new Unit!',
            'error'
          )
          console.log("error :: ",error);
        })
      }

    }

    let handleSubmitOwner = async (event) => {
      event.preventDefault();
      let data = {
        email: ownerDetails.email,
        name: ownerDetails.name,
        password: ownerDetails.password,
      }

      await axios.post(api+'/api/admin/signup',data).then((response) => {
        console.log(response);
        Swal.fire(
          'Success',
          'New Owner added Successfully.',
          'success'
        )
        navigate('/admin/units');
      }).catch(err => {
        console.log(err);
        Swal.fire(
          'Failed',
          'Failed to add new Owner!',
          'error'
        )
      })
    }

    let selectOptions = apiUnitDetails.map((val,ind) => {
      return(
        <option key={ind} value={val.id}>{val.title}</option>
      )
    })

    let optionsData = apiOwnerDetails.length && apiOwnerDetails.map((val,ind) => {
      // console.log("value in map function :: ",val)
      return {
        value: val.id,
        label: val.name+" "+"("+val.email+")"
      }
    })
    // console.log(apiOwnerDetails);

    useEffect(() => {
      axios.get(api+'/api/admin/units/allUnits').then((response) => {
        // console.log("response from useEffect :: ",response);
        setApiUnitDetails(response.data.data);
        axios.get(api+"/api/admin/owner/allusers").then(result => {
          setApiOwnerDetails(result.data.data)
        }).catch(error => {
          console.log("error :: ",error)
        })
      }).catch((err) => {
        console.log("Error occured :: ",err);
      })
    },[])

//--------------
//FUNCTION DECLARATION ENDS
//--------------

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="row">
              <div className="col-md-12">
                    <div className="template-demo text-end">
                    <Link to="/admin/units"><button type="button" style={{borderRadius:'5px', marginRight:'15px'}} className="btn btn-outline-info">Back</button></Link>
                    </div>
            </div>
                  </div>
          <div className="col-12 grid-margin stretch-card">
              <div className="card">
                  <Tabs defaultActiveKey="Unit" className="mb-3">
                      <Tab eventKey="Unit" title="Add New Lot">
                      <div className="card">
                      <div className="row">
                  <div className="col-md-4">
                    <div className="card-body">
                      <h4 className="card-title">Lot Information</h4>
                      <p className="card-description">Add information for new Lot.</p>
                      <div className="template-demo">
                      <form className="forms-sample" onSubmit={handleSubmitUnit}>
                    <div className="form-group">
                      <label>Title of Lot<code>*</code></label>
                      <input required type="text" onChange={handleUnitTitle} className="form-control" placeholder="Title ..." />
                    </div>
                    <div className="form-group">
                      <label>Address<code>*</code></label>
                      <input required type="text" onChange={handleUnitAddress} className="form-control" placeholder="Address ..." />
                    </div>
                    <div className="form-group">
                      <label >City<code>*</code></label>
                      <input required type="text" onChange={handleUnitCity} className="form-control" placeholder="City ..." />
                    </div>
                    <div className="form-group">
                    <label>State<code>*</code></label>
                    <input required type="text" onChange={handleUnitState} className="form-control" placeholder="State ..." />
                    </div>
                    <div className="form-group">
                    <label>ZipCode<code>*</code></label>
                    <input required type="text" onChange={handleUnitZipcode} className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="ZipCode" />
                    </div>
                    <div className="form-group">
                    <label>Status<code>*</code></label>
                    <select onChange={handleUnitStatus} className="form-control mb-2 mr-sm-2">
                      <option value={1}>Developed</option>
                      <option value={0}>Un-Developed</option>
                    </select>
                    </div>
                    <button type="submit" className="btn btn-outline-info mr-2" style={{borderRadius: '5px'}}>Submit</button>
                    <button className="btn btn-light">Cancel</button>
                  </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card-body">
                      <h4 className="card-title">Owner</h4>
                      <p className="card-description">Select Owner</p>
                      <div className="template-demo">
                      <div className="col-sm-12 text-start">
                        <Select
                        onChange={handleOwner}
                        options={optionsData}
                        isMulti={true}
                      />
                      </div>
                      </div>
                    </div>
                  </div> 

                </div>
                </div>
                      </Tab>
                      <Tab eventKey="User" title="Add Owners">
                      <div className="card">
                      <div className="row">
                  <div className="col-md-4">
                    <div className="card-body">
                      <h4 className="card-title">Owner Information</h4>
                      <div className="template-demo">
                      <form className="forms-sample" onSubmit={handleSubmitOwner}>
                    <div className="form-group">
                      <label>Owner Name<code>*</code></label>
                      <input required onChange={handleName} type="text" className="form-control" placeholder="Name" />
                    </div>
                    <div className="form-group">
                      <label>Email<code>*</code></label>
                      <input required onChange={handleEmail} type="email" className="form-control" placeholder="Email" />
                    </div>
                    {/* <div className="form-group">
                      <label>Phone<code>*</code></label>
                      <input required onChange={handlePhone} minLength={10} type="text" className="form-control" placeholder="Phone" />
                    </div> */}
                    <div className="form-group">
                      <label>Password<code>*</code></label>
                      <input required onChange={handlePass} minLength={8} type="password" className="form-control" placeholder="Password" />
                    </div>
                    <input type="submit" value="Submit" className="btn btn-outline-info mr-2" style={{borderRadius: '5px'}} />
                    <button className="btn btn-light">Cancel</button>
                  </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card-body">

                      <div className="template-demo">

                      </div>
                    </div>
                  </div>

                </div>
                </div>
                      </Tab>
                  </Tabs>
              </div>
            </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default AddUnitsComponent;