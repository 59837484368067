import React from 'react';
import CountUp from 'react-countup';

const FactsComponent = () => {
    return (
        <section id="facts">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h3 className="section-title">Facts</h3>
            <p className="section-description">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p>
          </div>
          <div className="row counters">
            <div className="col-lg-3 col-6 text-center">
              <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter">
                  <CountUp start={0} delay={5} end={232} duration={3} useEasing={true} />
              </span>
              <p>Clients</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span data-purecounter-start={0} data-purecounter-end={534} data-purecounter-duration={1} className="purecounter">
                  <CountUp end={534} delay={5} duration={4} useEasing={true} />
              </span>
              <p>Projects</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span data-purecounter-start={0} data-purecounter-end={1463} data-purecounter-duration={1} className="purecounter">
                  <CountUp end={24} delay={5} duration={3} useEasing={true} />
              </span>
              <p>Hours Of Support</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span data-purecounter-start={0} data-purecounter-end={42} data-purecounter-duration={1} className="purecounter">
                  <CountUp end={42} delay={5} duration={3} useEasing={true} />
              </span>
              <p>Hard Workers</p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default FactsComponent;