import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';

const DocumentsComponent = () => {
    return(
        <div className=" container-scroller">
  <div className="container-fluid page-body-wrapper full-page-wrapper">
    <div className="main-panel w-100  documentation">
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pt-5">
              <Link to="/admin" className="btn btn-primary"><AiOutlineHome size={20} className="ti-home mr-2" />Back to home</Link>
            </div>
          </div>
          <div className="row pt-5 mt-5">
            <div className="col-12 pt-5 text-center">
              <i className="text-primary mdi mdi-file-document-box-multiple-outline display-1" />
              <h3 className="text-primary font-weight-light mt-5">
                The detailed documentation of Skydash Admin Template is provided at 
                <a href="http://bootstrapdash.com/demo/skydash/docs/documentation.html" target="_blank" className="text-danger d-block text-truncate">
                  http://bootstrapdash.com/demo/skydash/docs/documentation.html
                </a>
              </h3>
              <h4 className="mt-4 font-weight-light text-primary">
                In case you want to refer the documentation file, it is available at 
                <span className="text-danger">/docs/documentation.html</span> 
                in the downloaded folder
              </h4>
            </div>
          </div>
        </div>
      </div>
      {/* partial:../../partials/_footer.html */}
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021.  Premium <a href="https://www.bootstrapdash.com/" target="_blank">Bootstrap admin template</a> from BootstrapDash. All rights reserved.</span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted &amp; made with <i className="ti-heart text-danger ml-1" /></span>
        </div>
      </footer>
      {/* partial */}
    </div>
  </div>
</div>
    )
}

export default DocumentsComponent;