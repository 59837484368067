import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import { BsPlusLg } from 'react-icons/bs';

import axios from 'axios';
import api_url from '../../../../utils/apiURL';
import{ Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import _ from 'lodash';

const PendingPaymentComponent = () => {
  let unit_id = localStorage.getItem('Unit');
  let userId = localStorage.getItem('UserID')

    let [showModal, setShowModal] = useState(false);
    let [showModal2, setShowModal2] = useState(false);
    let [invoicesDetails, setInvoicesDetails] = useState([]);
    let [payData, setPayData] = useState({
      unit_id: unit_id,
      paydate: '',
      amount: '',
      note: '',
      total: '',
      invoice_id: '',
      method: '1'
    })
    let [loading, setLoading] = useState(false);
    let [recData, setRecData] = useState({
      invoiceId: '',
      amountPaid: '',
      total: '',
      paydate: '',
      refId: '',
      note: '',
      method: ''
    })
    let [paymentDetails, setPaymentDetails] = useState([]);
    let [pivotData, setPivotData] = useState([]);

    let tableData = invoicesDetails.length && invoicesDetails.map((val,ind) => {
      let bal = pivotData.length && pivotData.map((itm,i) => {
        if(itm.paid == 0 && itm.invoice_id == val.id && itm.lot_id == val.lot_id){
          return itm.balance
        }
        else{
          return 0
        }
      })
      let totalBal = _.sum(bal)
      if(val.paid == 0){
      return(
      <tr key={ind}>
        <td>{val.title}</td>
        <td>${val.total}</td>
        <td>${totalBal}</td>
      </tr>
    )
      }
    })

    console.log("pivot details in user page of payment :: ",invoicesDetails)
    // let totalPending = invoicesDetails.length && invoicesDetails.map((val,i) => {
    //   if(val.paid == 0){
    //     return val.amount
    //   }
    // })

    let totalPending = pivotData.length && pivotData.map((val,i) => {
      if(val.paid == 0){
        return val.balance
      }
      else{
        return 0
      }
    })

    let totalAmount = pivotData.length && pivotData.map((val,i) => {
      return val.charge_amount
    })

    let total_paid = pivotData.length && pivotData.map((val,i) => {
      if(val.paid == 1){
        return val.charge_amount
      }
      else if(val.paid == 0){
        if(val.balance < val.amount){
          return val.charge_amount - val.balance
        }
        else{
          return 0
        }
      }
    })

    let pendingPay = _.sum(totalPending);
    let TotalInvAmount = _.sum(totalAmount);
    let tPaid = _.sum(total_paid)

    let handleShowModal = () => {
        setShowModal(!showModal);
    }

    let handleShowModal2 = () => {
      setShowModal2(!showModal2);
    }


    useEffect(() => {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      setLoading(true);


            let data = {
              id: userId
          }

                  axios.post(api_url+"/api/u1/invoices/owner/all",data).then(result => {
                    setInvoicesDetails(result.data.data)
                      setLoading(false);
                  }).catch((error) => {
                      console.log(error);
                  })

                  axios.post(api_url+"/api/u1/invoices/owner/unpaid",data).then(result => {
                    setPivotData(result.data.data)
                  }).catch((error) => {
                    console.log("unable to get Pivot Data :: ",error)
                  })



                  setLoading(false);


              // let data4 = {
              //   id: userId
              // }
              // axios.post(api_url+"/api/u1/invoices/owner-invoice",data4).then((items) => {
              //   setInvoicesDetails(items.data.data);
              //   setLoading(false);
              // }).catch((err) => {
              //   console.log(err);
              // })


      return () => {
        source.cancel()
      }

  },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">
              <div className="card-body">
                  <h4 className="card-title">Payments</h4>
                  <div className="col-md-12">
                    <br/>
                    <h5>
                      Total Outstanding Payment
                    </h5>
                    <p>${pendingPay}</p>
                     <br/>
                      <div className="template-demo">
                        <div className="text-start">
                          <Link to="/user/payments/make-payment">
                            <button type="button" style={{borderRadius:'5px', marginRight:'4px'}} className="btn btn-outline-info">
                                <BsPlusLg style={{marginRight:'5px'}} />
                                Make Payment
                                </button>
                                </Link>
                              <Link to="/user/payments/record-payment">
                                <button type="button" style={{borderRadius:'5px', marginLeft:'4px'}} className="btn btn-outline-info">
                                <BsPlusLg style={{marginRight:'5px'}} />
                                Record Offline Payment
                                </button>
                                </Link>
                        </div>
                        {/* {showModal2 && <RecordPayment show={showModal2} setShow={setShowModal2} setImage={setImageData} image={imageData} inData={invoicesDetails} pdata={recData} setpdata={setRecData} setImageN={setImageName} />} */}
                            
                            {/* {showModal && <MakePayment pdata={payData} setpdata={setPayData} inData={invoicesDetails} show={showModal} setShow={setShowModal} />} */}
                      </div>
                    
                  </div>
                  </div>

                  </div>
                  <br />
                  <div className="card">
                    {loading ? <div className="text-center" style={{padding:'10px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}} />
                  </div> : <div className="card-body">
    <h4 className="card-title">Outstanding Invoices</h4>
    <div className="col-md-6">
        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Title
                          </th>
                          <th>
                            Total Amount
                          </th>
                          <th>
                            Outstanding
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoicesDetails.length ? <>{tableData}</> : <tr>
                          <td>No Pending Payments.</td>
                          </tr>}
                        
                        </tbody>
                        </table>
                        </div>

        </div>
      
    </div>
    </div>}
                  
    </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default PendingPaymentComponent;