import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Hero from './HeroComponent';
import Blogs from './BlogPosts';

const BlogsComponent = () => {
    return(
        <Header>
            <Hero />
            <Blogs />
            <Footer />
        </Header>
    )
}

export default BlogsComponent;