import React, { useState, useEffect } from 'react';
import Header from '../../../Common/Header';
import { BsPlusLg } from 'react-icons/bs';
import axios from 'axios';
import api from '../../../../../utils/apiURL';
import Swal from 'sweetalert2';
import { Spinner } from 'react-bootstrap';

const OwnerHistoryComponent = () => {
    let [loading, setLoading] = useState(false);
    let [paymentDetails, setPaymentDetails] = useState([])
    let [userDetails, setUserDetails] = useState([])
    let [invoiceDetails, setInvoiceDetails] = useState([])
    let [unitDetails, setUnitDetails] = useState([])

    let userId = localStorage.getItem('UserID');

    // let tableData = paymentDetails.length && paymentDetails.map((val,ind) => {
    //     return invoiceDetails.length && invoiceDetails.map((itm,i) => {
    //         if(itm.id == val.invoice_id){
    //             return(
    //                 <tr key={ind}>
    //                     <td>{itm.title}</td>
    //                     <td>{val.created_at.split('T')[0]}</td>
    //                     <td>{val.total_amount}</td>
    //                     <td>{val.amount_paid}</td>
    //                     <td>{val.balance}</td>
    //                     <td>{val.reference_id}</td>
    //                 </tr>
    //             )
    //         }
    //     })
    // })

    let tableData = paymentDetails.length && paymentDetails.map((val,i) => {
        return unitDetails.length && unitDetails.map((itm,ind) => {
            if(itm.id == val.unit_id){
                return(
                    <tr key={i}>
                        <td>{itm.title}</td>
                        <td>{val.paydate.split('T')[0]}</td>
                        <td>${val.total_amount}</td>
                        <td>${val.amount_paid}</td>
                        <td>${val.balance}</td>
                        <td>{(val.method == 1) ? "Online" : ((val.method == 2) ? "Offline" : ((val.method == 3) ? "Cash" : ((val.method == 4) ? "Cheque" : "Bank Transfer")))}</td>
                        <td>{val.reference_id}</td>
                    </tr>
                )
            }
        })
    })

    // useEffect(() => {
    //     let CancelToken = axios.CancelToken;
    //     let source = CancelToken.source();
    //     setLoading(true);
  
    // let user_details_id = localStorage.getItem('UserID');
          
    //     let data = {
    //         id: user_details_id,
    //     }

    //     axios.post(api+"/api/admin/owner/user",data).then(response => {
    //         if(response) {
    //             setUserDetails(response.data.data)
    //         let data2 = {
    //             userId: response.data.data[0].id,
    //             unitId: response.data.data[0].unit
    //         }
    //         axios.post(api+"/api/admin/payment/owner/details",data2).then(result => {
    //             if(result) {
    //             setPaymentDetails(result.data.data)
    //             let data3 = {
    //                 unitId: response.data.data[0].unit
    //             }
    //             axios.post(api+"/api/admin/invoices/owner-invoice",data3).then(row => {
    //                 if(row) {
    //                     setInvoiceDetails(row.data.data)
    //                     setLoading(false)
    //                 }
    //                 else{
    //                     setInvoiceDetails({
    //                         id: '',
    //                         title: 'No Outstanding Invoice',
    //                         amount: '',
    //                         total_amount: '',
    //                         unit_id: ''
    //                     })
    //                 }
    //             }).catch(eror => {
    //                 console.log(eror)
    //             })
    //         }
    //         else{
    //             setPaymentDetails({
    //                 unit_id: '',
    //                 owner_id: '',
    //                 invoice_id: '',
    //                 amount_paid: '0',
    //                 total_amount: '0',
    //                 balance: '0'
    //             })
    //         }
    //         }).catch(error => {
    //             console.log("error :: ",error)
    //         })
    //     }
    //     else{
    //         setUserDetails({
    //             name: 'Unable to get User Details.',
    //             email: '',
    //             phone: '',
    //             unit: ''
    //         })
    //     }
    //     }).catch(err => {
    //         if(axios.isCancel(err)){
    //             console.log("Successfully aborted !!")
    //           }
    //           else{
    //             console.log("error occured in api :: ",err);
    //           }
    //     })

    //     return (() => {
    //         source.cancel()
    //     })

    // },[])

    useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();
        setLoading(true);
  
  
              let data = {
                id: userId
            }
  
                    axios.post(api+"/api/u1/payment/details",data).then(result => {
                      setPaymentDetails(result.data.data)
                        setLoading(false);
                    }).catch((error) => {
                        console.log(error);
                    })

                    axios.post(api+"/api/u1/units/owner/untis",data).then(response => {
                        setUnitDetails(response.data.data)
                    }).catch(err => {
                        console.log("error occured while getting unit details :: ",err);
                    })
  
                    // axios.post(api_url+"/api/u1/invoices/owner/unpaid",data).then(result => {
                    //   setPivotData(result.data.data)
                    // }).catch((error) => {
                    //   console.log("unable to get Pivot Data :: ",error)
                    // })
  
  
  
                    setLoading(false);
  
  
                // let data4 = {
                //   id: userId
                // }
                // axios.post(api_url+"/api/u1/invoices/owner-invoice",data4).then((items) => {
                //   setInvoicesDetails(items.data.data);
                //   setLoading(false);
                // }).catch((err) => {
                //   console.log(err);
                // })
  
  
        return () => {
          source.cancel()
        }
  
    },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
                  <div className="card">
                    {loading ? <div className="text-center" style={{padding:'10px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}} />
                  </div> : <div className="card-body">
    <h4 className="card-title">Your Recent Payments</h4>
    <div className="col-md-12">
        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Lot Title
                          </th>
                          <th>
                            Paid on
                          </th>
                          <th>
                            Total Amount
                          </th>
                          <th>
                            Amount Paid
                          </th>
                          <th>
                            Outstanding
                          </th>
                          <th>Payment Method</th>
                          <th>
                            Reference ID
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentDetails.length ? <>{tableData}</> : <tr>
                          <td>No Payment Record Found.</td>
                          </tr>}
                        
                        </tbody>
                        </table>
                        </div>

        </div>
      
    </div>
    </div>}
                  
    </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default OwnerHistoryComponent;