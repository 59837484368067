import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiBuildingHouse } from 'react-icons/bi';
import { AiOutlineUnorderedList, AiFillFileAdd } from 'react-icons/ai';
import { FaMoneyCheck, FaFileInvoice, FaFilter } from 'react-icons/fa';
import { ImStatsBars } from 'react-icons/im';
import { FiUser } from 'react-icons/fi';
import { BiTime } from 'react-icons/bi';
import { MdOutlineRequestQuote } from 'react-icons/md';
import { BsFilter } from 'react-icons/bs';
import { RiFolderReceivedFill } from 'react-icons/ri';
import { GiReceiveMoney } from 'react-icons/gi';

const Sidebar = (props) => {
// console.log("sidebar props :: ",props)
//-----------------
//CONSTANTS
//-----------------

let UserRole = localStorage.getItem('userRole');

//-----------------
//CONSTANTS ENDS
//-----------------

//---------------
//FUNCTIONS
//---------------

let handlePeople = () => {
  props.setoptn({
    dashBoard: false,
    lots: false,
    people: true,
    invoices: false,
    payments: false
  })
}

let handleLots = () => {
  props.setoptn({
    dashBoard: false,
    lots: true,
    people: false,
    invoices: false,
    payments: false
  })
}

let handleInvoices = () => {
  props.setoptn({
    dashBoard: false,
    lots: false,
    people: false,
    invoices: true,
    payments: false
  })
}

let handlePayments = () => {
  props.setoptn({
    dashBoard: false,
    lots: false,
    people: false,
    invoices: false,
    payments: true
  })
}

let handleDash = () => {
  props.setoptn({
    dashBoard: true,
    lots: false,
    people: false,
    invoices: false,
    payments: false
  })
}

    return(
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          {(UserRole === '1') ? <> <li onClick={handleDash} className={ "nav-item"}>
            <Link className={"nav-link collapsed"} to="/admin">
              <ImStatsBars size={22} className="icon-grid menu-icon" />
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className={"nav-item"}>
            <Link onClick={handleLots} className={"nav-link"} to="/admin/units">
              <BiBuildingHouse size={22} className="icon-grid menu-icon" />
              <span className="menu-title">Lots</span>
            </Link>
          </li>
          <li className={"nav-item"}>
            <Link onClick={handlePeople} className={"nav-link"} to="/admin/people">
              <FiUser size={22} className="icon-grid menu-icon" />
              <span className="menu-title">People</span>
            </Link>
          </li>
          <li onClick={handleInvoices} className={"nav-item"}>
            <Link className={"nav-link"} to="/admin/invoices">
              <FaFileInvoice size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Invoices</span>
            </Link>
          </li>
          <li onClick={handleInvoices} className={"nav-item"}>
            <Link className={"nav-link"} to="/admin/charge">
              <AiFillFileAdd size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Charge</span>
            </Link>
          </li>

          <li className={"nav-item"}>
            <Link onClick={handlePayments} className={"nav-link"} to="/admin/payments">
              <FaMoneyCheck size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Payments</span>
            </Link>
          </li>
          {/* <li className={"nav-item"}>
            <Link className={"nav-link"} to="/admin/invoices/pending">
              <FaMoneyCheck size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Pending Payments</span>
            </Link>
          </li> */}
          <li className={"nav-item"}>
            <Link className={"nav-link"} to="/admin/payments/approve">
              <AiOutlineUnorderedList size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Payment Requests</span>
            </Link>
          </li>
          <li className={"nav-item"}>
            <Link className={"nav-link"} to="/admin/report/receiveable">
              <RiFolderReceivedFill size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Receiveable Reports</span>
            </Link>
          </li>
          <li className={"nav-item"}>
            <Link className={"nav-link"} to="/admin/report/revenue">
              <GiReceiveMoney size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Revenue Reports</span>
            </Link>
          </li>
          
          </> :  <>
          
          <li className="nav-item">
            <Link className="nav-link" to="/user/details">
              <ImStatsBars size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Details</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/user/payments">
              <FaMoneyCheck size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Payments</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/user/invoices">
              <FaFileInvoice size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Invoices</span>
            </Link>
          </li>
          <li onClick={handleInvoices} className={"nav-item"}>
            <Link className={"nav-link"} to="/user/statements">
              <BsFilter size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Statements</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/user/owner-history">
              <BiTime size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Owner History</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/user/request/payment">
              <MdOutlineRequestQuote size={22} style={{marginRight:'2px'}} className="icon-grid menu-icon" />
              <span className="menu-title">Payment Requests</span>
            </Link>
          </li>
          
          </> }
        </ul>
      </nav>
    )
}

export default Sidebar;