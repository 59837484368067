import React, { useEffect } from 'react';
import Header from '../Common/Header';
import logo from '../.././../assets/img/light1.svg';

const HomePageComponent = () => {

  let UserRole = localStorage.getItem('userRole');

    return(
<div className="container-scroller">
        <Header>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row">
                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                      
                      <h3 className="font-weight-bold"> {(UserRole === '1') ? 'Welcome Admin' : 'Welcome User'} </h3>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card tale-bg">
                    <div style={{padding:'35px'}} className="card-people mt-auto">
                      <img src={logo} alt="people" />
                      <div className="weather-info">
                        <div className="d-flex">

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

        </Header>
      </div>
    )
}

export default HomePageComponent;