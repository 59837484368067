import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from '../Components/Home/HomePage';
import Blogs from '../Components/Home/Blogs';
import AdminHome from '../Components/Admin/Home';
import LoginComp from '../Components/Admin/UserPages/Login';
import RegisterComp from '../Components/Admin/UserPages/Register';
import ErrorOne from '../Components/Admin/Error/404';
import ErrorTwo from '../Components/Admin/Error/500';
import Document from '../Components/Admin/Documentation';
import Units from '../Components/Admin/Units';
import AddUnit from '../Components/Admin/Units/AddUnits';
import People from '../Components/Admin/People';
import Login from '../Components/Admin/Login';
import Payment from '../Components/Admin/Payments';
import Invoice from '../Components/Admin/Invoices';
import NewInvoice from '../Components/Admin/Invoices/NewInvoice';
import Signup from '../Components/Admin/Signup';
import UserDetails from '../Components/Admin/UserPages/UserDetails';
import PendingPay from '../Components/Admin/UserPages/Payments';
import View from '../Components/Admin/Units/ViewUnit';
import ViewPeople from '../Components/Admin/People/viewPeople';
import ViewInvoice from '../Components/Admin/Invoices/viewInvoice';
import UserInvoice from '../Components/Admin/UserPages/Invoices';
import Approve from '../Components/Admin/Payments/ApprovePayment';
import ViewApprove from '../Components/Admin/Payments/ApprovePayment/viewApprovePayment';
import OwnerHistory from '../Components/Admin/UserPages/Payments/OwnerHistory';
import PendingInvoices from '../Components/Admin/Invoices/Pending';
import ViewOwnerLot from '../Components/Admin/UserPages/UserDetails/ViewLot';
import PaymentReq from '../Components/Admin/UserPages/RequestPayment';
import RecordPayment from '../Components/Admin/Payments/recordNewPayment';
import RecordUserPayment from '../Components/Admin/UserPages/Payments/MakePayment';
import RecordUserOfflinePayment from '../Components/Admin/UserPages/Payments/RecordPayment';
import Charges from '../Components/Admin/Charge';
import NewCharge from '../Components/Admin/Charge/NewCharge';
import VerifyUser from '../Components/Admin/Verify';
import ConfirmEmail from '../Components/Admin/Forgot/email';
import Statement from '../Components/Admin/Statement';
import Receive from '../Components/Admin/Reports/Receivable';
import Revenue from '../Components/Admin/Reports/Revenue';
import Reset from '../Components/Admin/Forgot/reset';
import ViewReceivable from '../Components/Admin/Reports/Receivable/View';
import ViewRevenue from '../Components/Admin/Reports/Revenue/View';
import ViewOwnerInvoice from '../Components/Admin/UserPages/Invoices/View';
import StatementInvoice from '../Components/Admin/Statement/viewInvoice';

const AllRoutes = () => {
    let user = localStorage.getItem('isAuthenticated');

    return(
        <BrowserRouter>
            <Routes>
                {(user == 'false' || user == false) && <Route path="/admin/login" element={<Login />} />}
                {(user == 'true' || user == true) && <>
                <Route path="/admin" element={<AdminHome  />} />

                <Route path="/admin/view-unit" element={<View />} />
                <Route path="/admin/units" element={<Units />} />
                <Route path="/admin/add-unit" element={<AddUnit />} />
                
                <Route path="/admin/report/receiveable" element={<Receive />} />
                <Route path="/admin/report/revenue" element={<Revenue />} />
                <Route path="/admin/report/revenue/view" element={<ViewRevenue />} />
                <Route path="/admin/report/receiveable/view" element={<ViewReceivable />} />

                <Route path="/admin/charge" element={<Charges />} />
                <Route path="/admin/charge/new" element={<NewCharge />} />

                <Route path="/admin/invoices" element={<Invoice />} />
                <Route path="/admin/invoices/view" element={<ViewInvoice />} />
                <Route path="/admin/new-invoice" element={<NewInvoice />} />
                <Route path="/admin/invoices/pending" element={<PendingInvoices />} />

                <Route path="/admin/people" element={<People />} />
                <Route path="/admin/people/view" element={<ViewPeople />} />

                <Route path="/admin/payments" element={<Payment />} />
                <Route path="/admin/payments/record" element={<RecordPayment />} />
                <Route path="/admin/payments/approve" element={<Approve />} />
                <Route path="/admin/payments/approve/view" element={<ViewApprove />} />




                <Route path="/user/statements" element={<Statement />} />
                <Route path="/user/statements/invoices" element={<StatementInvoice />} />
                <Route path="/user/details" element={<UserDetails />} />

                <Route path="/user/payments" element={<PendingPay />} />
                <Route path="/user/owner-history" element={<OwnerHistory />} />
                <Route path="/user/payments/make-payment" element={<RecordUserPayment />} />
                <Route path="/user/payments/record-payment" element={<RecordUserOfflinePayment />} />

                <Route path="/user/invoices" element={<UserInvoice />} />
                <Route path="/user/invoices/view" element={<ViewOwnerInvoice />} />

                <Route path="/user/details/view-lot" element={<ViewOwnerLot />} />

                <Route path="/user/request/payment" element={<PaymentReq />} />

                </> }
                
                <Route path="*" element={<Navigate to={"/admin/login"} />} />
                <Route path="/" element={<Home />} />
                <Route path="/blogs" element={<Blogs />} />

                <Route path="/admin/login" element={<Login />} />
                <Route path="/admin/signup" element={<Signup />} />

                <Route path="/verify/account" element={<VerifyUser />} />
                <Route path="/forgot-password/confirm-email" element={<ConfirmEmail />} />
                <Route path="/forgot-password/reset" element={<Reset />} />
 
                <Route path="/login-test" element={<LoginComp />} />
                <Route path="/register-test" element={<RegisterComp />} />
                <Route path="/error-404" element={<ErrorOne />} />
                <Route path="/error-500" element={<ErrorTwo />} />
                <Route path="/documentation" element={<Document />} />

            </Routes>
        </BrowserRouter>
    )
}

export default AllRoutes;