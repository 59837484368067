import React, { useState } from 'react';
import { Button, Modal, CloseButton } from 'react-bootstrap';
import api_url from '../../../../../utils/apiURL';
import axios from 'axios';
import Swal from 'sweetalert2';

const EditOwnerComponent = (props) => {

  let [charge, setCharge] = useState({
    title: '',
    description: '',
    amount: ''
  })

//   let handleTitle = (event) => {
//     setCharge((values) => ({
//       ...values,
//       title: event.target.value
//     }))
//   }

//   let handleDescription = (event) => {
//     setCharge((values) => ({
//       ...values,
//       description: event.target.value
//     }))
//   }

//   let handleAmount = (event) => {
//     setCharge((values) => ({
//       ...values,
//       amount: event.target.value
//     }))
//   }

//   let handleSubmit = (event) => {
//     event.preventDefault();
    
//     let data = {
//       title: charge.title,
//       description: charge.description,
//       amount: charge.amount
//     }

//     axios.post(api_url+"/api/admin/invoices/create/charge",data).then(response => {
//       Swal.fire(
//         'Charge Added.',
//         '',
//         'success'
//       )
//       console.log("Success ! charge created!! ",response)
//     }).catch(err => {
//       Swal.fire(
//         'Failed to Create Charge!',
//         '',
//         'error'
//       )
//       console.log("failed to create charge !! ",err); 
//     })
//     props.setShow(false)
//   }


    return(
        <Modal
      size="md"
      show={props.show}
      centered
    >
      <Modal.Header>
        
        <Modal.Title id="contained-modal-title-vcenter">
          Add Charge
        </Modal.Title>
          <CloseButton onClick={() => props.setShow(false)} />
      </Modal.Header>

      <Modal.Body>
        <div className="row">
        <div className="col-md-12">
        <form className="forms-sample">
                    <div className="form-group">
                    <label>Owner Status</label>
                    <select className="form-control">
                        <option>Active</option>
                        <option>In-active</option>
                    </select>
                    </div>
                  </form>
        </div>
        </div>
        <br />
      
      </Modal.Body>
      <Modal.Footer>
      <button type="submit" style={{borderRadius:'5px'}} className=" btn btn-outline-info">Add</button>
        <button style={{borderRadius:'5px'}} className=" btn btn-secondary" onClick={() => props.setShow(false)}>Close</button>
      </Modal.Footer>
    </Modal>
    )
}

export default EditOwnerComponent;