import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import apiURL from '../../../../utils/apiURL';
import Swal from 'sweetalert2';
import { Spinner, Image } from 'react-bootstrap';

const AllPaymentRequestsComponent = () => {

  let[loading, setLoading] = useState(false);
  let[reqDetails, setReqDetails] = useState([]);
  let[userDetails, setUserDetails] = useState([]);
  let[unitDetails, setUnitDetails] = useState([]);
  let[invoiceDetails, setInvoiceDetails] = useState([]);
  let [pageNum, setPageNum] = useState(2);
  let [totalPages, setTotalPages] = useState();

  console.log("request details :: ",reqDetails)

  let tableData = reqDetails.length && reqDetails.map((val, i) => {
    let findUnit = unitDetails.length && unitDetails.find(unit => unit.id == val.unit_id);
    let findInvoice = invoiceDetails.length && invoiceDetails.find(inv => inv.id == val.invoice_id);
    let paydate = val.paydate.split('T')
    if(val.status == '1'){
      return(
        <tr key={i}>
          <td>{i+1}</td>
        <td>{findUnit.title}</td>
        <td>{paydate[0]}</td>
        <td>${val.amount_paid}</td>
        <td>${val.total_amount}</td>
        {/* <td><Image fluid={true} src={val.image} /></td> */}
        <td>
          {(val.bank == "" || val.bank == null) ? <>
          <Link to="/admin/payments/approve/view" state={{ unit: findUnit, invoice: findInvoice, payReq: val}} >
            <button className="btn btn-outline-info" style={{borderRadius:'5px'}}>View</button>
          </Link>
          </> : <>
          <Link to="/admin/payments/approve/view" state={{ unit: findUnit, payReq: val}} >
            <button className="btn btn-outline-info" style={{borderRadius:'5px'}}>View</button>
          </Link>
          </> }
        </td>
      </tr>
      )
    }
  })

  let testTotalPage = Math.ceil(totalPages/10)

  let handleLoadmore = () => {
    setLoading(true)
    if(pageNum > testTotalPage){
      Swal.fire(
        'No Record',
        'No Record found !',
        'info'
      )
    }
    else if(pageNum <= testTotalPage){
      axios.get(apiURL+`/api/admin/payment/request/limited?page=${pageNum}`).then(response => {
        let dataArr = response.data.data.length && response.data.data.map((val,i) => {
          return val
        })
        setReqDetails((values) => [...values, ...dataArr])
        setPageNum(pageNum + 1)
        setLoading(false)
      }).catch(error => {
        console.log("error :: ",error)
      })
    }
  }

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);
    axios.get(apiURL+"/api/admin/payment/request/limited?page=1").then(response => {
      setReqDetails(response.data.data)
      axios.get(apiURL+"/api/admin/owner/allusers").then(res => {
        setUserDetails(res.data.data)
        axios.get(apiURL+"/api/admin/units/allUnits").then(result => {
          setUnitDetails(result.data.data)
          axios.get(apiURL+"/api/admin/invoices/allinvoices").then(row => {
            setInvoiceDetails(row.data.data)
            setLoading(false)
          }).catch(er => {
            console.log("er",er)
            setInvoiceDetails({
              id: 0,
              invoice_id: 0,
              unit_id: 0,
              title: 'No invoice available.',
              description: '',
              deposite_bank: '',
              category: '',
              amount: 0,
              total_amount: 0,
              active_date: '',
              due_date: '',
              paid: ''
            })
            setLoading(false)

          })
        }).catch(err => {
          console.log("err", err);
          setUnitDetails({
            id: 0,
            unit_id: 0,
            owner_id: 0,
            title: 'No Unit Available',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            status: ''
          })
          setLoading(false)

        })
      }).catch(eror => {
        console.log("eror :: ",eror)
        setUserDetails({
          id: 0,
          uuid: 0,
          email: '',
          name: 'no user found',
          phone: '',
          role: '',
          unit: ''
        })
        setLoading(false)

      })
    }).catch(error => {
      console.log("error :: ",error);
      setReqDetails({
        id: 0,
        request_id: 0,
        user_id: 0,
        unit_id: 0,
        invoice_id: 0,
        paydate: '',
        amount_paid: '',
        total_amount: '',
        image: '',
        note: '',
        reference_id: '',
        status: '',
        method: ''
      })
      setLoading(false)
    })

    return () => {
      source.cancel()
    }
  },[])

    return (
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
                  <div className="card">
                  <div className="card-body">
                  <h4 className="card-title">Payment Requests</h4>
                  {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
                  </div> : <div className="col-md-12">
        <br/>
        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Sno.
                          </th>
                          <th>
                            Lot
                          </th>
                          <th>
                            Paydate
                          </th>
                          <th>
                            Amount Paid
                          </th>
                          <th>
                            Total Amount
                          </th>
                          <th>
                            Action
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        {reqDetails.length ? <> {tableData} </> : <tr>
                          <td>No Pending Requests</td>  
                        </tr>}
                        </tbody>
                        </table>
                        </div>

    </div>
    <br />
    {(pageNum <= testTotalPage) ? <div classNAme="text-center">
      <button className="btn btn-outline-info" onClick={handleLoadmore}>Load More</button>
    </div> : null}
    </div>}

    </div> 

    </div>
    </div>
    </div>
    </div>
        </Header>
        </div>
    )
}

export default AllPaymentRequestsComponent;