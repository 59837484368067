import React from 'react';
import About from './About';
import Facts from './Facts';
import Services from './Services';
import Hero from './HeroComponent';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const HomePage = () => {
    return(
        <Header>
            <Hero />
            <About />
            <Facts />
            <Services />
            <Footer />
        </Header>
    )
}

export default HomePage;