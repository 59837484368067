import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import { Link } from 'react-router-dom';
import api from '../../../utils/apiURL';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';

const UnitsComponent = () => {
//-----------
//STATE DECLARATION
//-----------
    let [unitDetails, setUnitDetails] = useState([]);
    let [loading, setLoading] = useState(false);
    let [pageNum, setPageNum] = useState(2);
    let [totalPages, setTotalPages] = useState()
    // console.log(unitDetails)
//-----------
//STATE DECLARATION ENDS
//-----------
//-----------
//FUNCTION DECLARATION
//-----------
let testTotalPage = Math.ceil(totalPages/10)
console.log("unit details :: ",unitDetails)

    let handleLoadmore = () => {

        setLoading(true);
  
        if(pageNum > testTotalPage) {
          Swal.fire(
            'No invoice available',
            'No invoice found !',
            'info'
          )
        }
        else if(pageNum <= testTotalPage) {
          axios.get(api+`/api/admin/units/limited?page=${pageNum}`).then(response => {
            let dataArr = response.data.data.length && response.data.data.map((val,ind) => {
              return val
            })
            setPageNum(pageNum + 1)
            setUnitDetails((values) => [...values, ...dataArr])
            setLoading(false)
          }).catch(err => {
            Swal.fire(
              'Failed',
              'Failed to get Invoices !',
              'error'
            )
          })
        }
    }
//-----------
//FUNCTION DECLARATION ENDS
//-----------

let tableRow = unitDetails.length && unitDetails.map((val,ind) => {
     return(
         <tr key={ind}>
             <td>
                 {val.title}
             </td>
             <td>
                 {val.address}, {val.city}, {val.state}
             </td>
               <td>
                 {(val.status == 1) ? "Developed" : "Un-Developed"}
               </td>
               <td>
              <Link to="/admin/view-unit" state={{unitId: val.unit_id}}>
                <button type="button" className="btn btn-outline-info" style={{borderRadius: '5px'}} > View </button>
              </Link>
               </td>
         </tr>
     )
})

//-----------
//EFFECTS
//-----------

    useEffect(() => {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      setLoading(true);

      axios.get(api+"/api/admin/units/count").then(res => {
        setTotalPages(res.data.data[0].total)
      }).catch(err => {
        console.log("count api failed :: ",err)
      })

        axios.get(api+'/api/admin/units/limited?page=1').then((response) => {
            setUnitDetails(response.data.data)
            console.log("response from api :: ",response);
            setLoading(false);
        }).catch((error) => {
          if(axios.isCancel(error)){
            console.log("Successfully aborted !!");
          }
          else{
            console.log("error occured : : : ",error)
            setUnitDetails({
              id: 0,
              unit_id: 0,
              owner_id: '',
              title: 'no unit available',
              address: '',
              city: '',
              state: '',
              zipcode: '',
              status: ''
            })
          }
          setLoading(false);
        })

        return () => {
          source.cancel();
        }

    },[])

//-----------
//EFFECTS ENDS
//-----------

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">

          <div className="col-lg-12 grid-margin">
            
              <div className="card">
                {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
                    </div> : <div className="card-body">
                  <h4 className="card-title">Lots</h4>
                  <div className="col-md-12">

                      <p className="card-description">All your lots will be listed here.</p>
                      <div className="template-demo text-end">
                        <Link to="/admin/add-unit"><button style={{borderRadius:'5px'}} type="button" className="btn btn-outline-info">Add new Owner/Lot</button></Link>

                      </div>
                    <br />
                  </div>
                    <div className="col-md-12">
                    <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Title
                          </th>
                          <th>
                            Address
                          </th>
                          <th>
                            Status
                          </th>
                          <th>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {unitDetails.length ? <>{tableRow}</> : <tr>
                          <td>No Unit Available</td>
                          </tr>}        
                      </tbody>
                    </table>
                  </div>
                    </div>
                    <br />
                    {(pageNum <= testTotalPage) ? <div className="text-center">
                      <button className="btn btn-outline-info" onClick={handleLoadmore} style={{borderRadius: '5px'}}>Load More</button>
                    </div> : null}
                  </div>}
              

                  </div>
                  </div>
              </div>
              </div>

        </Header>
        </div>
    )
}

export default UnitsComponent;