import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api_url from '../../../../../utils/apiURL';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../Common/Header';
import _ from 'lodash';

const RecordPaymentComponent = (props) => {

  let navigate = useNavigate();

  let[makeAccount, setMakeAccount] = useState(false);
  let[makeInvoice, setMakeInvoice] = useState(false);
  let [unitsData, setUnitsData] = useState([]);
  let [imageDetails, setImageDetails] = useState();
  let [imageData, setImageData] = useState();
  let [recData, setRecData] = useState({
    lot: '',
    invoiceId: '',
    amountPaid: '',
    total: '',
    paydate: '',
    refId: '',
    note: '',
    method: '',
    bank: ''
  })
  let [invoiceDetails, setInvoicesDetails] = useState([]);
  let [pivot, setPivot] = useState([]);

let userId = localStorage.getItem('UserID');
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;
let testDate = yyyy+'-'+mm+'-'+dd

let optionsData = invoiceDetails && invoiceDetails.map((val, ind) => {
  if(val.paid == 0){
    return(
      <option value={val.id}>{val.title}</option>
    )
  }
  else{
    return null
  }
})

let unitOptns = unitsData.length && unitsData.map((val,i) => {
 
    return(
      <option value={val.id}>{val.title}</option>
    )
})

let handleInvoice = (event) => {
  setRecData((values) => ({
    ...values,
    invoiceId: event.target.value,
  }))

  if(recData.lot == "" || recData.lot == null){
    Swal.fire(
      'Select Lot',
      'Select lot to continue.',
      'warning'
    )
  }
  else{
    let data = {
      lot: recData.lot,
      invoice: event.target.value
    }
    axios.post(api_url+"/api/admin/invoices/pivot/inv-lot",data).then(response => {
      setPivot(response.data.data);
      let total_bal = response.data.data.length && response.data.data.map((val,i) => {
        if(val.paid == 0){
          return val.balance
        }
        else{
          return 0
        }
      })

      let total = _.sum(total_bal)
      setRecData((values) => ({
        ...values,
        amountPaid: total,
        total: total,
      }))

    }).catch(err => {
      console.log("failed to get pivot details :: ",err)
    })
  }

}

let handleAccount = () => {
  setMakeAccount(!makeAccount);
  setMakeInvoice(false);

  if(recData.lot == "" || recData.lot == null){
    Swal.fire(
      'Select Lot',
      'Select lot to continue.',
      'warning'
    )
  }
  else{
    let data = {
      lot: recData.lot
    }
  
    axios.post(api_url+"/api/admin/invoices/unpaid/pivot",data).then(response => {
      setPivot(response.data.data);
      let total = response.data.data.length && response.data.data.map((val,i) => {
        return val.balance
      })
      let test = _.sum(total)
      setRecData((values) => ({
        ...values,
        amountPaid: test,
        total: test,
      }))

    }).catch(err => {
      console.log("failed to get pivot details !! ",err);
    })

  }

}

let handleShowInvoice = () => {
  setMakeInvoice(!makeInvoice);
  setMakeAccount(false);
}

let handleAmount = (event) => {
  setRecData((values) => ({
    ...values,
    amountPaid: event.target.value
  }))
}

let handleImage = (e) => {
    setImageDetails(URL.createObjectURL(e.target.files[0]))
    setImageData(e.target.files[0]);
}

// console.log("image here :: ",imageData)

// console.log("props p data :: ",recData)

let handleDate = (e) => {
    setRecData((values) => ({
        ...values,
        paydate: e.target.value
    }))
}

let handleMethod = (e) => {
  setRecData((values) => ({
    ...values,
    method: e.target.value
  }))
}

let handleRef = (e) => {
  setRecData((values) => ({
    ...values,
    refId: e.target.value
  }))
}

let handleNote = (e) => {
  setRecData((values) => ({
    ...values,
    note: e.target.value
  }))
}

let handleBank = (e) => {
  setRecData((values) => ({
    ...values,
    bank: e.target.value
  }))
}

let handleSubmit = (event) => {
  event.preventDefault();

  let formData = new FormData();

  formData.append("image",imageData);
  formData.append("userId",userId);
  formData.append("invoiceId", recData.invoiceId);
  formData.append("amount", recData.amountPaid);
  formData.append("total", recData.total)
  formData.append("paydate", recData.paydate)
  formData.append("refId", recData.refId)
  formData.append("note", recData.note)
  formData.append("method", recData.method)
  formData.append("bank", recData.bank)
  formData.append("lot", recData.lot)

  if(imageData == "" || imageData == null){
    Swal.fire(
      'Receipt is Required',
      'Upload payment receipt to continue',
      'error'
    )
  }
  // else if(recData.invoiceId == "" || recData.invoiceId == null){
  //   Swal.fire(
  //     'Select Invoice',
  //     'Select Invoice to continue',
  //     'error'
  //   )
  // }
  else if(recData.paydate == "" || recData.paydate == null){
    Swal.fire(
      'Pay date required',
      'Select Pay date to continue',
      'error'
    )
  }
  else if(recData.refId == "" || recData.refId == null){
    Swal.fire(
      'Reference Id required',
      'Enter Reference ID to continue',
      'error'
    )
  }
  else if(recData.amountPaid == '' || recData.amountPaid == null){
    Swal.fire(
      'Amount Paid required',
      'Enter amount paid to continue',
      'error'
    )
  }
  else if(recData.method == "" || recData.method == null){
    Swal.fire(
      'Payment method required',
      'Select Payment method to continue',
      'error'
    )
  }

  else{  
  try{
      console.log("form data here :: ",formData)
      axios.post(api_url+"/api/admin/payment/owner/request",formData).then(response => {
          if(response){
              Swal.fire(
                  'Request successfull',
                  'Payment Request submited Successfully.',
                  'success'
              )
              navigate('/user/payments')
          }
          else{
              Swal.fire(
                  'Failed',
                  'Unable to upload image',
                  'error'
              )
          }
      }).catch(error => {
          console.log("error occured :: ",error)
          Swal.fire(
            'Error',
            'Failed to Request Payment!',
            'error'
          )
      })
  }catch(err){
      console.log("err :: ",err)
      Swal.fire(
          'Error',
          "Something went wrong!!",
          'error'
      )
  }
}

}

let handleUnit = (e) => {
  setRecData((values) => ({
    ...values,
    lot: e.target.value
  }))

  let data4 = {
    id: e.target.value
  }

  axios.post(api_url+"/api/u1/invoices/lot",data4).then((items) => {
    setInvoicesDetails(items.data.data);
  }).catch((err) => {
    console.log(err);
  })

}

useEffect(() => {
  let CancelToken = axios.CancelToken;
  let source = CancelToken.source();

  let data = {
    id: userId
  }

  axios.post(api_url+"/api/u1/units/owner/untis",data).then(response => {
    setUnitsData(response.data.data)
    if(response.data.data.length > 1){
      console.log("more than 1 unit found");
    }
  }).catch(err => {
    console.log("error occured :: ",err)
  })

  return () => {
    source.cancel()
  }
},[])

    return(

      <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
        <div className="row">
        <div className="row">
              <div className="col-md-12">
                    <div className="template-demo text-end">
                    <Link to="/user/payments"><button type="button" style={{borderRadius:'5px', marginRight:'15px'}} className="btn btn-outline-info">Back</button></Link>
                    </div>
            </div>
                  </div>
        <div className="col-lg-12 grid-margin">
              <div className="card">
              <div className="card-body">
            <h4 className="card-title">Record Payment</h4>
            <div className="row">
        <div className="col-md-6">
        <form className="forms-sample" autoComplete="off">
          <div className="form-group">
                    <select onChange={handleUnit} className="form-control form-control-sm">
                      <option value={0}>-- Select Lot --</option>
                      {unitOptns}
                    </select>
          </div>
                    <div className="form-group">
                        <button type="button" onClick={handleAccount} className="btn btn-outline-info" style={{borderRadius:'5px', marginRight:'5px'}}>Make payment on Account</button>
                        <button type="button" onClick={handleShowInvoice} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Make payment on Invoice</button>
                    </div>

                    {makeInvoice ? <> <div className="form-group">
                        <label>Select Invoice<code>*</code></label>
                        <select onChange={handleInvoice} className="form-control form-control-sm">
                            <option>-- Select Invoice --</option>
                            {optionsData}
                        </select>
                    </div></> : null}

                    {makeAccount ? <> <div className="form-group">
                    <label>Deposit Account</label>
                      <input type="text" onChange={handleBank} className="form-control form-control-sm" placeholder="Enter Deposit account" />
                    </div></> : null}

                    <div className="form-group">
                      <label>Payment Receipt<code>*</code></label>
                      <input type="file" onChange={handleImage} className="form-control form-control-sm" />
                      {imageData && <img src={imageDetails} style={{width:'30%', height: '100px'}} />}
                    </div>
                    <div className="form-group">
                      <label>Payment Date</label>
                      <input type="date" onChange={handleDate} className="form-control form-control-sm" />
                    </div>
                    <div className="form-group">
                    <label>Reference ID</label>
                      <input type="text" onChange={handleRef} className="form-control form-control-sm" placeholder="Enter your Reference ID" />
                    </div>
                  </form>
        </div>
        <div className="col-md-6">
        <form className="forms-sample">
                    <div className="form-group">
                      <label>Total Amount</label>
                      <input 
                      value={(recData.total !== '') ? recData.total : null}
                       type="text" disabled className="form-control form-control-sm" placeholder="$0.00" />
                    </div>
                    <div className="form-group">
                      <label>Amount Paid</label>
                      <input onChange={handleAmount} 
                      value={(recData.amountPaid !== '') ? recData.amountPaid : null}
                       type="text" className="form-control form-control-sm" placeholder="$0.00" />
                    </div>
                    <div className="form-group">
                    <label>Method<code>*</code></label>
                      <select onChange={handleMethod} className="form-control form-control-sm">
                        <option value="">-- Select Your Payment Method --</option>
                        <option value={3}>Cash</option>
                        <option value={4}>Cheque</option>
                        <option value={5}>Bank Transfer</option>
                      </select>
                    </div>
                    <div className="form-group">
                    <label>Note</label>
                      <input type="text" onChange={handleNote} className="form-control form-control-sm" placeholder="Enter a note" />
                    </div>

                  </form>
        </div>
        </div>    
        <button type="button" className="btn btn-outline-success" style={{borderRadius:'5px'}} onClick={handleSubmit}>Send Request</button>  
        </div>
        </div>
        </div>
        </div>
              </div>
        </Header>
        </div>
    )
}

export default RecordPaymentComponent;