import React, { useState, useEffect } from 'react';
import api_url from '../../../../utils/apiURL';
import axios from 'axios';
import Swal from 'sweetalert2';

const EditInvoiceComponent = (props) => {

    console.log("Props in edit lot component :: ",props)

    let[show, setShow] = useState(false);

  let [charge, setCharge] = useState({
    title: '',
    description: '',
    amount: ''
  })

  let [invoiceData, setInvoiceData] = useState({
      total_Amount: '',
  })

  let charge_ids = JSON.parse(props.invoice.charge)

  let charge_details = props.charge.length && props.charge.map((val,ind) => {
    return charge_ids.map((itm,i) => {
      if(itm == val.id){
      return (
        <tr>
          <td>{val.charge_title}</td>
          <td>${(props.invoice.lot_status = 1) ? val.amount_dev : val.amount_undev}</td>
          {/* <td>{val.category}</td> */}
          <td>
              {(val.schedule == 1 || val.schedule == '1') ? "Monthly Charge" : ((val.schedule == 2 || val.schedule == '2') ? "Quaterly Charge" : ((val.sc== 3 || val.schedule == '3') ? "Semi-Annual Charge" : ((val.schedule == 4 || val.schedule == '4') ? "Annual" : "One Time")))}
          </td>
          <td>
              {(val.recurring == 1 || val.recurring == '1') ? "True" : "False"}
          </td>
          <td>
              {(val.limit_recurring == 0 || val.limit_recurring == '0') ? 'Infinite' : val.limit_recurring }
          </td>
          {/* <td>
            <button type="button" className="btn btn-outline-danger" style={{borderRadius: '5px'}}>Cancel Charge</button>
          </td> */}
        </tr>
      )
      }
    })
  })

console.log("props here :: ",props)

  let handleTitle = (event) => {
    setCharge((values) => ({
      ...values,
      title: event.target.value
    }))
  }

  let handleDescription = (event) => {
    setCharge((values) => ({
      ...values,
      description: event.target.value
    }))
  }

  let handleAmount = (event) => {
    setCharge((values) => ({
      ...values,
      amount: event.target.value
    }))
  }

//   let handleStatus = (e) => {
//       setUnitData((values) => ({
//           ...values,
//           status: e.target.value
//       }))
//   }

  let handleSubmit = (event) => {
    event.preventDefault();
    // alert("charge added !!")
    
    let data = {
      title: charge.title,
      description: charge.description,
      amount: charge.amount
    }

    axios.post(api_url+"/api/admin/invoices/create/charge",data).then(response => {
      Swal.fire(
        'Charge Added.',
        '',
        'success'
      )
      console.log("Success ! charge created!! ",response)
    }).catch(err => {
      Swal.fire(
        'Failed to Create Charge!',
        '',
        'error'
      )
      console.log("failed to create charge !! ",err); 
    })
    props.setShow(false)
  }


  useEffect(() => {
    setInvoiceData({
        total_Amount: props.total,
    })
    // setOwnerDetails(props.userDetails)
  },[])



    return(

        <div className="container-scroller">

        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">

            <div className="card-body">
                  <h4 className="card-title">Edit Invoice Details</h4>

                  <div className="col-md-12">
         <form className="forms-sample">
                     <div className="form-group">
                       <label>Invoice Amount</label>
                       <input readOnly={true} type="text" value={invoiceData.total_Amount} className="form-control" placeholder="Charge Title" />
                     </div>

                     <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Title
                          </th>
                          <th>
                            Charge Amount
                          </th>
                          {/* <th>
                            Category
                          </th> */}
                          <th>
                            Schedule
                          </th>
                          <th>
                            Recurring 
                          </th>
                          <th>
                            Limit
                          </th>
                          {/* <th>
                            Action
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {props.charge.length ? <>{charge_details}</> : <tr>
                          <td>No Charge Available</td>
                          </tr>}
                        {/* {tabledata} */}
                        </tbody>
                        </table>
                        </div>

        </div>

        <br />
                     {/* <button type="submit" style={{borderRadius:'5px', marginRight:'5px'}} className=" btn btn-outline-info">Save Changes</button> */}
                     <button style={{borderRadius:'5px', marginLeft:'5px'}} className="btn btn-outline-danger" onClick={() => alert("Invoice canceled")}>Cancel Invoice</button>
                     <button style={{borderRadius:'5px', marginLeft:'5px'}} className=" btn btn-outline-secondary" onClick={() => props.setShow(false)}>Back</button>
                   </form>
         </div>
                  </div>


                  </div>
                  </div>
              </div>
              </div>

        
        </div>






    //     <Modal
    //   size="md"
    //   show={props.show}
    //   centered
    // >
    //   <Modal.Header>
        
    //     <Modal.Title id="contained-modal-title-vcenter">
    //       Edit Lot
    //     </Modal.Title>
    //       <CloseButton onClick={() => props.setShow(false)} />
    //   </Modal.Header>

    //   <Modal.Body>
    //     <div className="row">
    //     <div className="col-md-12">
    //     <form className="forms-sample">
    //                 <div className="form-group">
    //                   <label>Lot Title<code>*</code></label>
    //                   <input disabled type="text" value={unitData.title} className="form-control" placeholder="Charge Title" />
    //                 </div>
    //                 <div className="form-group">
    //                   <label>Lot Status</label>
    //                   <select onChange={handleStatus} value={unitData.status} className="form-control">
    //                       <option value="1">Developed</option>
    //                       <option value="0">Un-Developed</option>
    //                   </select>
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Owner</label>
    //                     <input disabled className="form-control" value={unitData.owner} />
    //                 </div>
    //               </form>
    //     </div>
    //     </div>
    //     <br />
      
    //   </Modal.Body>
    //   <Modal.Footer>
    //   <button type="submit" style={{borderRadius:'5px'}} className=" btn btn-outline-info">Add</button>
    //     <button style={{borderRadius:'5px'}} className=" btn btn-secondary" onClick={() => props.setShow(false)}>Close</button>
    //   </Modal.Footer>
    // </Modal>
    )
}

export default EditInvoiceComponent;