import React, { useState, useEffect } from 'react';
import { BiX } from 'react-icons/bi';
import { FaAlignJustify } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Logo from '../../../../assets/img/logoNew1.png'
import newLogo from '../../../../assets/img/dark_1.svg'

const HeaderComponent = ({children: children, ...props}) => {
  let [showNav, setShowNav] = useState(false);
  let [activeLink, setActiveLink] = useState({
    home: true,
    about: false,
    journey: false,
    services: false,
    portfolio: false,
    team: false,
    contact: false,
    blogs: false
  })

  let show_mobile_toggle = () => {
    setShowNav(!showNav)
  }

  let CheckURL = window.location.href;


  let changeActiveClass = (data) => {
    setActiveLink(data)
    setShowNav(false)
    console.log("state data :: ",activeLink);
  }

  let handleScroll = () => {
    
    if (window.pageYOffset < 500) {
      return setActiveLink({
        home: true,
        about: false,
        journey: false,
        services: false,
        portfolio: false,
        team: false,
        contact: false,
        blogs: false
      })
    } else if (window.pageYOffset > 500  && window.pageYOffset < 900) {
      return setActiveLink({
        home: false,
        about: true,
        journey: false,
        services: false,
        portfolio: false,
        team: false,
        contact: false,
        blogs: false
      })
    }
    else if(window.pageYOffset > 1100){
      return setActiveLink({
        home: false,
        about: false,
        journey: false,
        services: true,
        portfolio: false,
        team: false,
        contact: false,
        blogs: false
      })
    } 
  }

  useEffect(() => {
    // window.addEventListener('scroll', handleScroll);
    if(CheckURL === 'http://localhost:3000/blogs' || CheckURL === 'http://192.168.1.76:3000/blogs' || CheckURL === 'https://dev13.invitocreatives.com/blogs' ){

        setActiveLink({
          home: false,
          about: false,
          journey: false,
          services: false,
          portfolio: false,
          team: false,
          contact: false,
          blogs: true
        })
      
    }
    else{
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  },[]);

    return (
        <React.Fragment>
            <header id="header" className="fixed-top d-flex align-items-center header-scrolled">
        <div className="container d-flex justify-content-between align-items-center">
          <div id="logo">
            <Link to="/"><img className="logoNew" src={newLogo} alt="" /></Link>
            {/* <h1>
            <a style={{textDecoration:'none'}} href="index.html">HOA</a>
            </h1> */}
          </div>
          <nav id="navbar" className={ showNav ? "navbar-mobile" : "navbar" }>
            <ul className="text-start">
              <li
              onClick={() => changeActiveClass({
                home: true,
                about: false,
                journey: false,
                services: false,
                portfolio: false,
                team: false,
                contact: false
              })}
              ><Link to="/" className={activeLink.home ? "nav-link scrollto active" : "nav-link scrollto"}
               href="#hero">Home</Link></li>
              <li
              onClick={() => changeActiveClass({
                home: false,
                about: true,
                journey: false,
                services: false,
                portfolio: false,
                team: false,
                contact: false
              })}
              ><a className={activeLink.about ? "nav-link scrollto active" : "nav-link scrollto"}
               href="#about">About</a></li>
               
               <li
              onClick={() => changeActiveClass({
                home: false,
                about: false,
                journey: false,
                services: false,
                portfolio: false,
                team: false,
                contact: false,
                blogs: true
              })}
              ><Link className={activeLink.blogs ? "nav-link scrollto active" : "nav-link scrollto"}
               to="/blogs">Blogs</Link></li>
              {/* <li
              onClick={() => changeActiveClass({
                home: false,
                about: false,
                journey: true,
                services: false,
                portfolio: false,
                team: false,
                contact: false
              })}
              ><a className={activeLink.journey ? "nav-link scrollto active" : "nav-link scrollto"}
               href="#journey">Journey</a></li> */}
              <li
              onClick={() => changeActiveClass({
                home: false,
                about: false,
                journey: false,
                services: true,
                portfolio: false,
                team: false,
                contact: false
              })}
              ><a className={activeLink.services ? "nav-link scrollto active" : "nav-link scrollto"}
               href="#new-service">Services</a></li>
                   <Link style={{textDecoration:'none'}} to="/admin/login">
               <li className="nav-link scrollto">
                 <button className="mb-2 nav-link scrollto btn btn-outline-info" style={{color:"white"}}>
                   LOGIN
                   </button>
               </li>
                   </Link>
              {/* <li
              onClick={() => changeActiveClass({
                home: false,
                about: false,
                journey: false,
                services: false,
                portfolio: true,
                team: false,
                contact: false
              })}
              ><a className={activeLink.portfolio ? "nav-link scrollto active" : "nav-link scrollto"}
               href="#portfolio">Portfolio</a></li>
              <li
              onClick={() => changeActiveClass({
                home: false,
                about: false,
                journey: false,
                services: false,
                portfolio: false,
                team: true,
                contact: false
              })}
              ><a className={activeLink.team ? "nav-link scrollto active" : "nav-link scrollto"}
               href="#team">Team</a></li>
              <li
              onClick={() => changeActiveClass({
                home: false,
                about: false,
                journey: false,
                services: false,
                portfolio: false,
                team: false,
                contact: true
              })}
              ><a className={activeLink.contact ? "nav-link scrollto active" : "nav-link scrollto"}
               href="#contact">Contact</a></li> */}
            </ul>
            <div onClick={() => show_mobile_toggle()} className={ showNav ? "bi mobile-nav-toggle bi-x" : "bi mobile-nav-toggle bi-list"}>
            { showNav ? <BiX color="white" size={25} /> : <FaAlignJustify color="white" size={25} />}
            </div>
          </nav>{/* .navbar */}
        </div>
      </header>{/* End Header */}

      <main id="main">
          {children}
      </main>
        </React.Fragment>
    )
}

export default HeaderComponent;