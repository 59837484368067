import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import logo from '../../../assets/img/light1.svg';
import api_url from '../../../utils/apiURL';

const SignupComponent = () => {

  let navigate = useNavigate();

  let [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    password: ''
  })

  let handleName = (event) => {
    setUserDetails((values) => ({
      ...values,
      name: event.target.value
    }))
  }

  let handleEmail = (event) => {
    setUserDetails((values) => ({
      ...values,
      email: event.target.value
    }))
  }

  let handlePassword = (event) => {
    setUserDetails((values) => ({
      ...values,
      password: event.target.value
    }))
  }

  let handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      name: userDetails.name,
      email: userDetails.email,
      password: userDetails.password
    }
    axios.post(api_url+"/api/admin/signup",data).then((response) => {
      console.log(response);
      Swal.fire(
        'Success',
        'Signup SuccessFull',
        'success'
      )
      navigate('/verify/account', { state: { id: response.data.data.insertId }})
    }).catch((error) => {
      console.log(error);
      Swal.fire(
        'Failed',
        'Signup Failed',
        'error'
      )
    })
  }

    return(
        <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <h4>New here?</h4>
                  <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6>
                  <form className="pt-3" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input onChange={handleName} type="text" className="form-control form-control-lg" id="exampleInputUsername1" placeholder="Username" />
                    </div>
                    <div className="form-group">
                      <input onChange={handleEmail} type="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email" />
                    </div>
                    {/* <div className="form-group">
                      <select className="form-control form-control-lg" id="exampleFormControlSelect2">
                        <option>Country</option>
                        <option>United States of America</option>
                        <option>United Kingdom</option>
                        <option>India</option>
                        <option>Germany</option>
                        <option>Argentina</option>
                      </select>
                    </div> */}
                    <div className="form-group">
                      <input onChange={handlePassword} type="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" />
                    </div>
                    {/* <div className="mb-4">
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          I agree to all Terms &amp; Conditions
                        </label>
                      </div>
                    </div> */}
                    <div className="mt-3">
                      <input type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" value="SIGN UP"/>
                    </div>
                    <div className="text-center mt-4 font-weight-light">
                      Already have an account? <Link to="/admin/login" className="text-info">Login</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default SignupComponent;