import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import api from '../../../../utils/apiURL';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { FaFilter } from 'react-icons/fa'
import _ from 'lodash';
import Filter from '../../Common/Filter';


const ReceiveableReportComponent = () => {
  //--------------
  //STATE DECLARATION
  //--------------

  let [invoicesData, setInvoicesData] = useState([]);
  let [chargeData, setChargeData] = useState([]);
  let[loading, setLoading] = useState(false);
  let [pageNum, setPageNum] = useState(2)
  let [totalPages, setTotalPages] = useState(0);
  let [pivotDetails, setPivotDetails] = useState([]);
  let [show, setShow] = useState(false);
  let [unitDetails, setUnitDetails] = useState([]);
  let [filter, setFilter] = useState({
    from: '',
    to: ''
  });

  //--------------
  //STATE DECLARATION ENDS
  //--------------
  //--------------
  //FUNCTION DECLARATION
  //--------------


  let tableData = invoicesData.length && invoicesData.map((val,ind) => {
    return unitDetails.length && unitDetails.map((itm,i) => {
      if(val.lot_id == itm.id){
        return(
          <tr key={ind}>
            <td>{val.title}</td>
            <td>{itm.title}</td>
            <td>{val.deposite_bank}</td>
          </tr>
        )
      }
      else{
        return null;
      }
    })
  })

  let table2 = invoicesData.length && invoicesData.map((val,i) => {
    return unitDetails.length && unitDetails.map((itm,ind) => {
      let totalBal = pivotDetails.length && pivotDetails.map((value, index) => {
        if(value.lot_id == itm.id && value.invoice_id == val.id){
          return value.balance
        }
      })

      let sumTotalBal = _.sum(totalBal)

      if(val.lot_id == itm.id){
        if(val.paid == 0){
          return(
            <tr key={i}>
            <td>
              {val.title}
            </td>
            <td>{itm.title}</td>
            <td>
              ${val.total}
            </td>
            <td>${sumTotalBal}</td>
          </tr>
        )
      }
      }
    })
  })

  let outstandingDues = pivotDetails.length && pivotDetails.map((val,i) => {
    return val.balance;
  })

  let totalOutstanding = _.sum(outstandingDues);

  console.log("pivot details :: ",pivotDetails)

  let testTotalBal = unitDetails.length && unitDetails.map((val,ind) => {
    let total_bal = pivotDetails.length && pivotDetails.map((itm,i) => {
      if(val.id == itm.lot_id){
          return itm.balance
      }
    })
    console.log("value of total_bal :: ",total_bal)

    let sumTotalBal = _.sum(total_bal);
    console.log("value of sumTotalBal :: ",sumTotalBal)
    if(sumTotalBal == undefined || sumTotalBal == null || sumTotalBal == ""){
      return(
        <tr key={ind}>
        <td>{val.title}</td>
        <td>$0</td>
      </tr>
      )
    }
    else{
      return(
        <tr key={ind}>
        <td>{val.title}</td>
        <td>${sumTotalBal}</td>
      </tr>
      )
    }
  })


  let handleShow = () => {
    setShow(!show)
  }

  let testTotalPage = Math.ceil(totalPages/10)

  let handleNext = () => {
    setLoading(true);

    if(filter.from == ""){
      if(pageNum > testTotalPage){
        Swal.fire(
          'No more records',
          'No more records found!',
          'info'
        )
      }
      else if(pageNum <= testTotalPage){
        axios.get(api+`/api/admin/invoices/limited?page=${pageNum}`).then(response => {
  
          let test = response.data.data.map((val,id) => {
            return val
          })
  
          setInvoicesData((values) => ([...values, ...test]))
          setLoading(false);
          setPageNum(pageNum + 1)
        }).catch(error => {
          console.log("error :: ",error);
          setInvoicesData({
            id: 0,
            invoice_id: 0,
            unit_id: 0,
            title: '',
            description: '',
            deposite_bank: '',
            category: '',
            amount: 0,
            total_amount: 0,
            active_date: '',
            due_date: '',
            paid: ''
          })
          setLoading(false);
        })
      }
    }

    else if(filter.from != "" && filter.to != ""){
      if(pageNum > testTotalPage){
        Swal.fire(
          'No more records',
          'No more records found!',
          'info'
        )
      }
      else if(pageNum <= testTotalPage){
        let data = {
          page: pageNum,
          from: filter.from,
          to: filter.to
        }
        axios.get(api+`/api/admin/invoices/from-to`,data).then(response => {
  
          let test = response.data.data.map((val,id) => {
            return val
          })
  
          setInvoicesData((values) => ([...values, ...test]))
          setLoading(false);
          setPageNum(pageNum + 1)
        }).catch(error => {
          console.log("error :: ",error);
          setInvoicesData({
            id: 0,
            invoice_id: 0,
            unit_id: 0,
            title: '',
            description: '',
            deposite_bank: '',
            category: '',
            amount: 0,
            total_amount: 0,
            active_date: '',
            due_date: '',
            paid: ''
          })
          setLoading(false);
        })
      }
    }

    else{
      if(pageNum > testTotalPage){
        Swal.fire(
          'No more records',
          'No more records found!',
          'info'
        )
      }
      else if(pageNum <= testTotalPage){
        axios.get(api+`/api/admin/invoices/limited?page=${pageNum}`).then(response => {
  
          let test = response.data.data.map((val,id) => {
            return val
          })
  
          setInvoicesData((values) => ([...values, ...test]))
          setLoading(false);
          setPageNum(pageNum + 1)
        }).catch(error => {
          console.log("error :: ",error);
          setInvoicesData({
            id: 0,
            invoice_id: 0,
            unit_id: 0,
            title: '',
            description: '',
            deposite_bank: '',
            category: '',
            amount: 0,
            total_amount: 0,
            active_date: '',
            due_date: '',
            paid: ''
          })
          setLoading(false);
        })
      }
    }
  }

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);

    axios.get(api+"/api/admin/invoices/all-unpaid").then(result => {
      setPivotDetails(result.data.data);
    }).catch(err => {
       console.log("error occured in getting unpaid invoices!! ",err);
    })

    axios.get(api+"/api/admin/invoices/admin/count").then(response => {
      setTotalPages(response.data.data[0].total)
    }).catch(err => {
      setTotalPages(0)
      console.error("Failed to get total admin invoices pages",err)
    })

    axios.get(api+"/api/admin/units/allUnits").then(res => {
      setUnitDetails(res.data.data)
    }).catch(er => {
      console.log("error occured while getting lot details :: ",er)
    })

    axios.get(api+`/api/admin/invoices/limited?page=${1}`).then(response => {
      setInvoicesData(response.data.data)

      axios.get(api+"/api/admin/invoices/charges").then(result => {
        setChargeData(result.data.data)
      }).catch(err => {
        console.log("error :: ",err)
      })
      setLoading(false);
    }).catch(error => {
      console.log("error :: ",error);
      setInvoicesData({
        id: 0,
        invoice_id: 0,
        unit_id: 0,
        title: '',
        description: '',
        deposite_bank: '',
        category: '',
        amount: 0,
        total_amount: 0,
        active_date: '',
        due_date: '',
        paid: ''
      })
      setLoading(false);
    })

    return () => {
      source.cancel()
    }


  },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
          {show && <Filter show={show} setShow={setShow} setInvoice={setInvoicesData} filter={filter} setFilter={setFilter} setPage={setPageNum} setLoading={setLoading} /> }
              <div className="card">

              <div className="card-body">
                  <h4 className="card-title">Receiveable Reports</h4>
                  <h6 className="title">Total Outstanding Balance</h6>
                  <p className="card-description">${totalOutstanding}</p>
                  <div className="col-md-12">
                    <div className="text-end">
                  
                      <button style={{borderRadius:'5px'}} onClick={handleShow} className="btn btn-outline-info">
                  <FaFilter style={{marginRight:'5px'}} />  
                      </button>

                    </div>
                  </div>
                  </div>

                  </div>
                  <br />
                  <div className="card">
                  {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <div className="card-body">
    <h4 className="card-title">Report Details</h4>
    <p className="card-description">Outstanding Invoices</p>
    <div className="col-md-8">

            <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>

                          <th>
                            Invoice Title
                          </th>
                          <th>Lot Title</th>
                          <th>Total Invoice Amount</th>
                          <th>Outstanding</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoicesData.length ? <>{table2}</> : <tr>
                          <td>No Invoice Available</td>
                          </tr>}
                        </tbody>
                        </table>
                        </div>

        </div>
        <br />
      {(pageNum <= testTotalPage) ? <div className="text-center">
        <button onClick={handleNext} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Load more</button>
      </div> : null}
            </div>
            </div>}


            </div>
            </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default ReceiveableReportComponent;