import React from 'react';
import { BiUser, BiGroup } from "react-icons/bi";

const AboutComponent = () => {
    return(
        <section id="about">
        <div className="container" data-aos="fade-up">
          <div className="row about-container">
            <div className="col-lg-6 content order-lg-1 order-2 text-start">
              <h2 className="title">Few Words About Us</h2>
              <p>
              We help customers to manage their HOA online. We provide customers with our software which is 
              easy to use and can manage your HOA from any where online.
              </p>
              <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
                <div className="icon"><BiUser color="#2dc997" size={25} /></div>
                <h4 className="title"><a href>Our Mission</a></h4>
                <p className="description">
                Our mission is to make life easier for HOA managers and homeowners.
                </p>
              </div>
              <div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
                <div className="icon"><BiGroup color="#2dc997" size={25} /></div>
                <h4 className="title"><a href>Professional</a></h4>
                <p className="description">HOA is the single portal where homeowners can make online payments, view payment history, important documents, submit requests, and update contact information.</p>
              </div>
              {/* <div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
                <div className="icon"><i className="bi bi-binoculars" /></div>
                <h4 className="title"><a href>Dolor Sitema</a></h4>
                <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
              </div> */}
            </div>
            <div className="col-lg-6 background order-lg-2 order-1" data-aos="fade-left" data-aos-delay={100} />
          </div>
        </div>
      </section>
    )
}

export default AboutComponent;