import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import api from '../../../../utils/apiURL';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const UserInvoiceComponent = () => {
    let user_id = localStorage.getItem('UserID');
    let [userDetails, setUserDetails] = useState([]);
    let [invoiceDetails, setInvoiceDetails] = useState([]);
    let [loading, setLoading] = useState(false);
    let [unitDetails, setUnitDetails] = useState([]);
    let [pivotDetails, setPivotDetails] = useState([]);
    let [chargeDetails, setChargeDetails] = useState([]);

    // let tableData;

    let optnsData = unitDetails.length && unitDetails.map((val,ind) => {
      return {
        value: val.id,
        label: val.title,
      }
    })

    console.log("unitDetails :: ",unitDetails)

    let handleSelect = (e) => {

      // console.log(" value of e :: ",e.value)

      tableData = invoiceDetails.length && invoiceDetails.map((val,i) => {
        if(val.lot_id == e.value){

            return(
              <tr key ={i}>
                <td>{val.title}</td>
                <td>{val.deposite_bank}</td>
                <td>{val.charge_id}</td>
                <td>{val.total}</td>
                <td>{val.lot_id}</td>
              </tr>
            )
          
        }
        else{
          return(
            <tr key={i}>
              <td>No Invoice Found</td>
            </tr>
          )
        }
      })
    }

  let tableData = invoiceDetails.length && invoiceDetails.map((val, ind) => {
      return unitDetails.length && unitDetails.map((itm,i) => {
        if(val.paid == 0){
          if(val.lot_id == itm.id){
            let totalCharge = JSON.parse(val.charge_id)
            return(
                <tr key={ind}>
                    <td>
                        {val.title}
                    </td>
                    {/* <td>
                        {val.deposite_bank}
                    </td> */}
                    {/* <td>
                        {totalCharge.length}
                    </td> */}
                    <td>
                        ${val.total}
                    </td>
                    <td>{itm.title}</td>
                    <td>
                      <Link to="/user/invoices/view" state={{invoice: val, lot: itm, pivot: pivotDetails, charge: chargeDetails}}>
                      <button className="btn btn-outline-info" style={{borderRadius:'5px'}}>View</button>
                      </Link>
                    </td>
                </tr>  
            )
          }
          else{
            return null
          }
        }
      })
    })

    console.log("invoice details :: ",invoiceDetails);
    console.log("unit details :: ",unitDetails);
    console.log("pivot details :: ",pivotDetails)


    useEffect(() => {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      setLoading(true);

        let data = {
            id: user_id
        }

        axios.post(api+"/api/u1/units/owner/untis",data).then(response => {
          setUnitDetails(response.data.data)
          axios.post(api+"/api/u1/invoices/owner/all",data).then(result => {
            setInvoiceDetails(result.data.data)
            setLoading(false);
          }).catch(err => {
            console.log("error occured while getting owner invoice details :: ",err)
          })
        }).catch(error => {
          console.log("error occured while getting owner lot details :: ",error)
        })

        axios.get(api+"/api/u1/invoices/pivot/all").then(response => {
          setPivotDetails(response.data.data)
        }).catch(err => {
          console.log("error occured while getting pivot data :: ",err)
        })

        axios.get(api+"/api/admin/invoices/charges").then(result => {
          setChargeDetails(result.data.data)
        }).catch(err => {
          console.log("error occured while getting charge details :: ",err)
        })

        setLoading(false)
        return () => {
          source.cancel()
        }

    },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
                  <br />
                  <div className="card">
                    {loading ? <div className="text-center" style={{padding: '10px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}} />
                </div> : <div className="card-body">
    <h4 className="card-title">Outstanding Invoices</h4>
     
    {/* <div className="col-md-4">
      <div className="form-group">
      <label>Select Unit<code>*</code></label>
      <Select options={optnsData} onChange={handleSelect} placeholder="Select Unit" />
    </div>
    </div> */}
    <div className="col-md-8">
        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Invoice Title
                          </th>
                          {/* <th>
                            Bank
                          </th> */}
                          {/* <th>
                            Total Charges
                          </th> */}
                          <th>
                            Invoice Amount
                          </th>
                          <th>Lot</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {invoiceDetails.length ? <>{tableData}</>: <tr>
                        <td>No invoice available</td>
                        </tr>}

                        </tbody>
                        </table>
                        </div>

                </div>
      
            </div>

            </div>}
            </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default UserInvoiceComponent;