import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import api from '../../../../utils/apiURL';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';

const PendingInvoicesComponent = () => {
  let [invoicesData, setInvoicesData] = useState([]);
  let [unitsData, setUnitsData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [pageNum, setPageNum] = useState(2);
  let [totalPages, setTotalPages] = useState();
  let [chargeData, setChargeData] = useState([]);
  let [icDetails, setICdetails] = useState([]);

console.log("lot details :: ",unitsData)

  let tabledata = unitsData.length && unitsData.map((itm, i) => {
          return invoicesData.length && invoicesData.map((val,ind) => {
            if(itm.id == val.lot_id){
              return(
                <tr key={i}>
                  <td>{itm.title}</td>
                  <td>{val.title}</td>
                  <td>{val.deposite_bank}</td>
                  <td>
                    {(val.lot_id === itm.id) ? <Link to="/admin/invoices/view" state={{ invoice: val, unit: itm, charge: chargeData }}>
                        <button type="button" className="btn btn-outline-info btn-sm" style={{borderRadius:'5px'}}>
                            View
                        </button>
                    </Link> : <Link to="/admin/invoices/view" state={{ invoice: val, charge: chargeData }}>
                        <button type="button" className="btn btn-outline-info btn-sm" style={{borderRadius:'5px'}}>
                            View
                        </button>
                    </Link>}
          
                  </td>
                </tr>
              )
            }
          })
    })

    let testTable = unitsData.length && unitsData.map((val,i) => {
      return(
        <tr key={i}>
          <td>{val.title}</td>
          <td>{val.address}</td>
          <td>{val.city}</td>
          <td>
            <button type="button">View</button>
          </td>
        </tr>
      )
    })

    console.log("page number :: ",pageNum)
    
    let testTotalPage = Math.ceil(totalPages/10)
    
    console.log("total pages :: ",testTotalPage);
    let handleLoadMore = () => {

      setLoading(true);

      if(pageNum > testTotalPage) {
        Swal.fire(
          'No invoice available',
          'No invoice found !',
          'info'
        )
      }
      else if(pageNum <= testTotalPage) {
        axios.get(api+`/api/admin/invoices/limited?page=${pageNum}`).then(response => {
          let dataArr = response.data.data.length && response.data.data.map((val,ind) => {
            return val
          })
          setPageNum(pageNum + 1)
          setInvoicesData((values) => [...values, ...dataArr])
          setLoading(false)
        }).catch(err => {
          Swal.fire(
            'Failed',
            'Failed to get Invoices !',
            'error'
          )
        })
      }
    }

    useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();
        setLoading(true);

        axios.get(api+"/api/admin/invocies/count").then(resp => {
          setTotalPages(resp.data.data[0].total)
        }).catch(err => {
          console.log("unable to get total invoices ... ",err)
        })

        axios.get(api+"/api/admin/invoices/charges").then(result => {
          setChargeData(result.data.data)
        }).catch(err => {
          console.log("error :: ",err)
        })

        axios.get(api+"/api/admin/invoices/get-details").then(res => {
          setICdetails(res.data.data)
        }).catch(err => {
          console.log("error occured :: ",err)
        })
    
        axios.get(api+'/api/admin/invoices/limited?page=1').then(response => {
          setInvoicesData(response.data.data)
          axios.get(api+"/api/admin/units/allUnits").then(response => {
            setUnitsData(response.data.data)
            setLoading(false);
          }).catch(error => {
            console.log(error);
            setUnitsData({
              id: 0,
              unit_id: 0,
              owner_id: 0,
              title: 'No lot available',
              address: '',
              city: '',
              state: '',
              zipcode: '',
              status: ''
            })
            setLoading(false);

          })
        }).catch(err => {
          if(axios.isCancel(err)) {
            console.log("Successfully aborted !!");
          }
          else{
            console.log(err);
            setInvoicesData({
              id: 0,
              invoice_id: 0,
              unit_id: 0,
              title: 'No invoice available',
              description: '',
              deposite_bank: '',
              category: '',
              amount: 0,
              total_amount: 0,
              active_date: '',
              due_date: '',
              paid: ''
            })
            setLoading(false);

          }
        })
    
        return () => {
          source.cancel()
        }
    
    
      },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
                  <br />
                  <div className="card">
                  {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <div className="card-body">
    <h4 className="card-title">Pending Payments</h4>
    <div className="col-md-6">
            <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Lot
                          </th>
                          <th>
                            Invoice Title
                          </th>
                          <th>
                            Deposit Account
                          </th>
                          <th>
                            View
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoicesData.length ? <>{tabledata}</> : <tr>
                          <td>No Pending Payments</td>
                          </tr>}
                        {/* {tabledata} */}
                        </tbody>
                        </table>
                        </div>

        </div><br />      
        {(pageNum <= testTotalPage) ? <div className="text-center">
          <button className="btn btn-outline-info" onClick={handleLoadMore} style={{borderRadius:'5px'}}>Load more</button>
        </div> : null}
            </div>
            </div>}


            </div>
            </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default PendingInvoicesComponent