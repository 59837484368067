import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/img/light1.svg';
import axios from 'axios';
import api from '../../../utils/apiURL';
import Swal from 'sweetalert2';

const LoginComponent = (props) => {
  let navigate = useNavigate();
/*
 ----------------
 STATE DECLARATION 
 ----------------
*/
let [userDetails, setUserDetails] = useState({
  email: '',
  password: ''
});

/*
-----------------
FUNCTION'S
-----------------
*/
// console.log("props here :: ",props)
let handleEmail = (event) => {
  // console.log(event)
  setUserDetails((data) => ({
    ...data,
    email: event.target.value
  }))
}

let handlePass = (event) => {
  setUserDetails((data) => ({
    ...data,
    password: event.target.value
  }))
}

let handleSubmit = (event) => {
  event.preventDefault();
  let data = {
    email: userDetails.email,
    password: userDetails.password
  }

  axios.post(api+'/api/admin/login',data).then((response) => {
    console.log(response);
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('userRole', response.data.data.role);
    localStorage.setItem('UserID', response.data.data.user_id);
    localStorage.setItem('UserName', response.data.data.username);
    localStorage.setItem('Email', response.data.data.email);
    console.log("response from api :: ",response)
    if(response.data.data.isVerified == '1' || response.data.data.isVerified == 1){
      Swal.fire(
        'Login Success',
        '',
        'success'
      )
      navigate('/admin')
    }
    else if(response.data.data.isVerified == '0' || response.data.data.isVerified == 0){
      let data = {
        id: response.data.data.id,
        email: response.data.data.email
      }
      axios.post(api+"/api/admin/owner/send-otp",data).then(result => {
        if(result.data.status == true || result.data.status == 'true'){
          Swal.fire(
            'Login Failed',
            'User not Verified!',
            'warning'
          )
          navigate('/verify/account', { state: { id: response.data.data.id }})
        }
      }).catch(err => {
        Swal.fire(
          'Login failed',
          'Something went wrong! try again later.',
          'error'
        )
        console.log("error occured :: ",err)
      })
    }
    else{
      Swal.fire(
        'Login Failed',
        'Something went wrong! Try again later.',
        'warning'
      )
    }
    console.log("response :: ",response)
  }).catch((error) => {
    console.log(error)
    localStorage.setItem('isAuthenticated', false);
    Swal.fire(
      'Login Failed',
      'Email or Password incorrect !',
      'error'
    )
  })
}

    return(

        <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                  <form className="pt-3" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input type="email" onChange={(data) => handleEmail(data)} className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username" />
                    </div>
                    <div className="form-group">
                      <input type="password" onChange={(data) => handlePass(data)} className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" />
                    </div>
                    <div className="mt-3">
                      <input type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" value="LOG IN" />
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      {/* <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          Keep me signed in
                        </label>
                      </div> */}
                      <Link to="/forgot-password/confirm-email" className="auth-link text-info">Forgot password?</Link>
                    </div>
                    
                    <div className="text-center mt-4 font-weight-light">
                      Don't have an account? <Link to="/admin/signup" className="text-info">Create</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    )
}

export default LoginComponent;