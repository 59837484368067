import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../Common/Header';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import apiURL from '../../../../utils/apiURL';
import Swal from 'sweetalert2';
import { Spinner, Image } from 'react-bootstrap';
import ImageViewer from 'react-simple-image-viewer';
import backendAPI from '../../../../utils/backendapi';

const ApprovePaymentComponent = () => {
    let location = useLocation();
    let { state } = location;

    // let paydate = state.payReq.split('T')

  let[loading, setLoading] = useState(false);
  let[reqDetails, setReqDetails] = useState([]);
  let [imageVal, setImageVal] = useState([]);
  let[openImage, setOpenImage] = useState(false);
  let[currentImage, setCurrentImage] = useState(0)

  console.log("image value :: ",state.payReq)
  // console.log("state Image value :: ",imageVal)

  let handleClose = () => {
    setCurrentImage(0);
      setOpenImage(false);
  }

  let handleOpen = (data) => {
      setOpenImage(true);
      setCurrentImage(data)
  }
console.log("state :: ",state)
  let handleApprove = () => {

    let data = {
      lot: state.unit.id,
      paydate: state.payReq.paydate.split('T')[0],
      amount: state.payReq.amount_paid,
      note: state.payReq.note,
      invoice: state.invoice ? state.invoice.id : "",
      method: state.payReq.method,
      bank: state.payReq.bank,
      payreqId: state.payReq.id
    }

    axios.post(apiURL+"/api/admin/payment/record/admin",data).then(response => {
      Swal.fire(
        'Payment Approved',
        'This payment was approved.',
        'success'
      )
    }).catch(error => {
      console.log(error);
      Swal.fire(
        'Failed',
        'Failed to approve this payment!',
        'error'
      )
    })
  }

  let handleDecline = () => {
    let payReqData = {
      id: state.payReq.request_id
    }
    axios.post(apiURL+"/api/admin/payment/reject/request",payReqData).then(response => {
      Swal.fire(
        'Payment Declined',
        'This Payment was Declined.',
        'success'
      )
    }).catch(error => {
      console.log(error)
      Swal.fire(
        'Failed',
        'Failed to Decline this Payment !',
        'error'
      )
    })
  }

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setOpenImage(true);
  }, []);

//   let tableData = reqDetails.length && reqDetails.map((val, i) => {
//     return(
//       <tr key={i}>
//         <td>{i+1}</td>
//       <td>{val.unit_id}</td>
//       <td>{val.invoice_id}</td>
//       <td>{val.amount_paid}</td>
//       <td>{val.total_amount}</td>
//       <td><Image fluid={true} thumbnail={true} src={val.image} /></td>
//       <td>
//         <Link to
//       </td>
//     </tr>
//     )
//   })

//   useEffect(() => {
//     let CancelToken = axios.CancelToken;
//     let source = CancelToken.source();
//     setLoading(true);
//     axios.get(apiURL+"/api/admin/payment/all-requests").then(response => {
//       setReqDetails(response.data.data)
//       setLoading(false)
//     }).catch(error => {
//       console.log("error :: ",error);
//       Swal.fire(
//         'Payment Request Failed',
//         'Unable to get Payment Requests !',
//         'error'
//       )
//     })

//     return () => {
//       source.cancel()
//     }
//   },[])

useEffect(() => {
  setImageVal([state.payReq.image])
},[])

// let paydate = state.payReq.split('T');

    return (
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
                  <div className="card">
                  <div className="card-body">
                  <h4 className="card-title">Approve Owner Payment Request</h4>
                  <div className="col-md-12">
                      <p className="card-description">Payment Request Details</p>
                      <div className="text-end">
                              <Link to="/admin/payments/approve"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} className="btn btn-outline-info">Back</button></Link>
                          </div>
                      <div className="template-demo">
                          <br/>
                          <h5>
                            Payment Proof
                        </h5>
                        {imageVal.length && imageVal.map((src, ind) => {
                          return(
                            <a href={backendAPI+src} target={'_blank'}>
                              <Image style={{width:'300', height:'200px'}} 
                              src={backendAPI+src} key={ind}/>
                            </a>
                          )
                        })}

                        {/* {openImage && <ImageViewer
                        style={{height:'300px', width:'100%'}}
                          src={imageVal}
                          currentIndex={currentImage}
                          onClose={handleClose}
                          backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)"
                          }}
                        />} */}
                        <h5>
                            Lot
                        </h5>
                        <p className="card-description">{state.unit.title}</p>
                        { state.invoice ? Object.keys(state.invoice).length && <>
                          <h5>
                            Invoice
                        </h5>
                        <p className="card-description">{state.invoice.title}</p>
                        </> : <>
                        <h5>
                            Paid on account
                        </h5>
                        </> }

                        <h5>
                            Paydate
                        </h5>
                        <p className="card-description">{state.payReq.paydate.split('T')[0]}</p>
                        <h5>
                           Amount Paid
                        </h5>
                        <p className="card-description">${state.payReq.amount_paid}</p>
                        <h5>
                            Total amount
                        </h5>
                        <p className="card-description">${state.payReq.total_amount}</p>
                        <h5>Reference ID</h5>
                        <p>{(state.payReq.reference_id == "" || state.payReq.reference_id == null) ? <code>Unable to get Reference ID</code> : state.payReq.reference_id}</p>
                      </div>
                    
                    <div className="text-end">
                      {(state.payReq.status != '1') ? (state.payReq.status == '2') ? <h5>Payment Approved</h5> : <h5>Payment Declined</h5> : <><Link to="/admin/payments/approve"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} onClick={handleApprove} className="btn btn-outline-success">Approve</button>
                      </Link>
                              <Link to="/admin/payments/approve"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} onClick={handleDecline} className="btn btn-outline-danger">Decline</button></Link></>}
                          </div>
                  </div>
                  {/* <h4 className="card-title">Approve Payments</h4> */}
                  {/* {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
                  </div> : <div className="col-md-12">
        <div className="text-end">
            <Link to="/admin/payments">
                <button className="btn btn-outline-info" style={{borderRadius:'5px'}}>
                    Back
                </button>
            </Link>
        </div>
        <br/>
        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Lot
                          </th>
                          <th>
                            Owner
                          </th>
                          <th>
                            Paydate
                          </th>
                          <th>
                            Amount Paid
                          </th>
                          <th>
                            Total Amount
                          </th>
                          <th>
                            Invoice
                          </th>
                          <th>
                            Action
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        {tableData}
                        </tbody>
                        </table>
                        </div>

    </div>
    </div>} */}

    </div> 

    </div>
    </div>
    </div>
    </div>
        </Header>
        </div>
    )
}

export default ApprovePaymentComponent;