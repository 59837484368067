import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import { Link, useLocation } from 'react-router-dom';
// import Edit from './Edit';
import _ from 'lodash';

const StatementInvoiceComponent = () => {
    let location = useLocation();
    let { state } = location;

    console.log("state in view invoice component :: ",state)



    let total_arr2 = Object.keys(state.charge).length && state.charge.map((val,i) => {
      return val.amount_undev
    })

    let ids = JSON.parse(state.invoice.charge_id);

    let total_arr = [];
    let total_test = Object.keys(state.charge).length && state.charge.map((val,i) => {
      return ids.map((itm,ind) => {
        if(val.id == itm){
          total_arr.push(val.amount_dev)
        }
      })
  })





    let charge_details = Object.keys(state.charge).length && state.charge.map((val,ind) => {
        return Object.keys(state.pivot).length && state.pivot.map((value, index) => {
                if(val.id == value.charge_id && value.invoice_id == state.invoice.id && value.lot_id == state.lot.id){
                        return(
                            <tr key={ind}>
                                <td>
                                    {val.charge_title}
                                </td>
                                <td>
                                    {val.charge_description}
                                </td>
                                <td>
                                  ${value.charge_amount}
                                </td>
                                <td>
                                  ${value.balance}
                                </td>

                            </tr>
                        )
                }
                else{
                    return null;
                }
            
        })
    })

    console.log("state in view invoice component :: ",state)

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Invoice</h4>
                  <div className="col-md-12">

                      <p className="card-description">Invoice Details</p>
                      <div className="template-demo">
                          <div className="text-end">
                              <Link to="/user/statements"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} className="btn btn-outline-info">Back</button></Link>
                          </div>
                          <br/>
                          <h5>
                           Invoice Title
                        </h5>
                        <p className="card-description">{Object.keys(state.invoice).length ? state.invoice.title : "Unable to get invoice details !"}</p>
                        <h5>
                          Total Invoice Amount
                        </h5>
                        <p className="card-description">{Object.keys(state.invoice).length ? <>${state.invoice.total}</> : "Unable to get Invoice Amount !"}</p>
                        <h5>Lot Title</h5>
                        <p className="card-description">{Object.keys(state.lot).length ? state.lot.title : "Unable to get Lot details !"}</p>

                        <div className="template-demo">
                        <br />
                        <div className="col-md-12">
        <div className="table-responsive">
                          <h4 className="card-title">Charge Details</h4>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Title
                          </th>
                          <th>
                            Description
                          </th>
                          <th>
                            Amount
                          </th>
                          <th>
                            Outstanding
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        {Object.keys(state.charge).length ? <>{charge_details}</> : 
                        <tr>
                          <td>No Charge Available</td>
                          </tr>}

                        </tbody>
                        </table>
                        </div>
                        </div>

        </div>

                      </div>
                    <br />
                  </div>

                  </div>
                  </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default StatementInvoiceComponent;