import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../../../assets/img/light1.svg';
import axios from 'axios';
import api from '../../../../utils/apiURL';
import Swal from 'sweetalert2';

const ResetPassComponent = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  let { state } = location;
  console.log("state here :: ",state)
/*
 ----------------
 STATE DECLARATION 
 ----------------
*/
let [userDetails, setUserDetails] = useState({
  password: '',
  confirm: '',
  otp: ''
});

/*
-----------------
FUNCTION'S
-----------------
*/
// console.log("props here :: ",props)

let handlePass = (event) => {
  setUserDetails((data) => ({
    ...data,
    password: event.target.value
  }))
}

let handleConfirm = (e) => {
  setUserDetails((values) => ({
    ...values,
    confirm: e.target.value
  }))
}

let handleOtp = (e) => {
    setUserDetails((values) => ({
        ...values,
        otp: e.target.value
    }))
}

let handleSubmit = (event) => {
  event.preventDefault();
  if(userDetails.password !== userDetails.confirm){
    Swal.fire(
      'Error',
      "Password and Confirm Password didn't match!",
      'warning'
    )
  }
  else if(userDetails.otp == ""){
    Swal.fire(
      'Error',
      'Enter OTP to continue',
      'warning'
    )
  }
  else {
    let data = {
      id: state.id,
      password: userDetails.password,
      otp: userDetails.otp
    }
    axios.post(api+"/api/admin/reset/password",data).then(response => {
      console.log("password reset success :: ",response);
      Swal.fire(
        'Success',
        'Password Reset Success, Login to Continue',
        'success'
      )
      navigate('admin/login')
    }).catch(error => {
      console.log("error occured :: ",error);
      Swal.fire(
        'Error',
        'Unable to reset password, Try again later!',
        'error'
      )
    })
  }

}

    return(

        <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" />
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Enter Email to reset your password.</h6>
                  <form className="pt-3" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Password</label>
                      <input type="password" onChange={handlePass} className="form-control form-control-lg" placeholder="Password" />
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input type="password" onChange={handleConfirm} className="form-control form-control-lg" placeholder="Confirm Password" />
                    </div>
                    <div className="form-group">
                      <label>OTP</label>
                      <input type="number" onChange={handleOtp} className="form-control form-control-lg" placeholder="Enter OTP" />
                    </div>
                    <div className="mt-3">
                      <input type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" value="RESET" />
                    </div>
                    <div className="text-center mt-4 font-weight-light">
                      Don't have an account? <Link to="/admin/signup" className="text-info">Create</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    )
}

export default ResetPassComponent;