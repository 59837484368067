import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import api from '../../../../utils/apiURL';
import Swal from 'sweetalert2';
import Header from '../../Common/Header'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'

const RecordNewPayment = (props) => {

  let navigate = useNavigate();

    let [invoiceList, setInvoiceList] = useState([]);
    let [unitDetails, setUnitDetails] = useState([]);
    let [paymentDetails, setPaymentDetails] = useState({
      unitId: '',
      payDate: '',
      amount: '',
      method: '',
      note: '',
      balance: '',
      total: '',
      invoiceId: '',
      bank: '',
    })
    let [loading, setLoading] = useState(false);
    let [makeAccount, setMakeAccount] = useState(false);
    let [makeInvoice, setMakeInvoice] = useState(false);
    let [pivotDetails, setPivotDetails] = useState([]);
    let [effectInv, setEffectInv] = useState([]);

    console.log("payment Details :: ",paymentDetails);

    // let location = useLocation();
    // let { state } = location;
    // console.log("location in record new payment :: ",location)

  let optionsData = unitDetails.length && unitDetails.map((val,ind) => {
    return effectInv.length && effectInv.map((itm,i) => {
      if(itm.lot_id == val.id && itm.paid == 0){
        return(
          <option key={ind} value={val.id}>{val.title}</option>
        )
      }
      else{
        return null;
      }
    })
  })

  let invoiceOptions = invoiceList.map((val,i) => {
    return(
      <option key={i} value={val.id}>{val.title}</option>
    )
  })

//   console.log("invoices list of single unit :: ",invoiceList);

  let handleUnit = async (event) => {

    setPaymentDetails((values) => ({
      ...values,
      unitId: event.target.value
    }))
    let data = {
      unitId: event.target.value
    }
    await axios.post(api+"/api/admin/invoices/owner-invoice",data).then((response) => {
      setInvoiceList(response.data.data)

    }).catch((err) => {

      console.log(err);
    })
  }

  let handlePaydate = (event) => {
    setPaymentDetails((values) => ({
      ...values,
      payDate: event.target.value
    }))
  }

  let handleAmount = (event) => {
    setPaymentDetails((values) => ({
      ...values,
      amount: event.target.value
    }))
  }

  let handleBalance = (event) => {
    setPaymentDetails((values) => ({
      ...values,
      balance: event.target.value
    }))
  }

  let handleTotal = (event) => {
    setPaymentDetails((values) => ({
      ...values,
      total: event.target.value
    }))
  }

  let handleBank = (e) => {
    setPaymentDetails((values) => ({
      ...values,
      bank: e.target.value
    }))
  }

  let handleMethod = (event) => {
    setPaymentDetails((values) => ({
      ...values,
      method: event.target.value
    }))
  }

  let handleNote = (event) => {
    setPaymentDetails((values) => ({
      ...values,
      note: event.target.value
    }))
  }

  let handleInvoices = (event) => {

    if(event.target.value == 0){
      console.log("No invoice selected !!")
    }
    else{
      let data = {
        invoice: event.target.value,
        lot: paymentDetails.unitId
      }
      axios.post(api+"/api/admin/invoices/pivot/inv-lot",data).then(response => {
        setPivotDetails(response.data.data)
        let test = response.data.data.length && response.data.data.map((val,i) => {
          return val.balance
        })
        let total_bal = _.sum(test)
        setPaymentDetails((values) => ({
          ...values,
          invoiceId: event.target.value,
          total: total_bal
        }))

      }).catch(err => {

        console.log("error occured while getting pivot details :: ",err);
      })
    }
  }

  console.log("Invoice list :: ",invoiceList)

let handleMakeAccount = () => {
    setMakeAccount(!makeAccount)
    setMakeInvoice(false)


    let totalAmount = invoiceList.length && invoiceList.map((val,i) => {
      return val.amount
    })

    let data = {
      lot: paymentDetails.unitId
    }

    axios.post(api+"/api/admin/invoices/unpaid/pivot",data).then(response => {
      setPivotDetails(response.data.data);
      let test = response.data.data.length && response.data.data.map((val,i) => {
        return val.balance
      })
      let total_bal = _.sum(test)
      setPaymentDetails((values) => ({
        ...values,
        total: total_bal
      }))

    }).catch(err => {
      console.log("error occured in unpaid pivot api :: ",err)

    })

    let test = totalAmount.reduce((a,b) => a+b, 0)
    setPaymentDetails((values) => ({
      ...values,
      total: test
    }))
    
}

let handleMakeInvoice = () => {
    setMakeInvoice(!makeInvoice)
    setMakeAccount(false)
}

  let handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let data = {
      lot: paymentDetails.unitId,
      paydate: paymentDetails.payDate,
      amount: paymentDetails.amount,
      method: paymentDetails.method,
      note: paymentDetails.note,
      invoice: paymentDetails.invoiceId,
      bank: paymentDetails.bank
    }

    console.log("data sent is here :: ",data);



    if(paymentDetails.unitId == ''){
      Swal.fire(
        'Fields Incomplete!',
        'Please select a Lot.',
        'warning'
      )
    }

    else if(paymentDetails.payDate == ''){
      Swal.fire(
        'Fields Incomplete!',
        'Enter date of payment.',
        'warning'
      )
    }
    else if(paymentDetails.amount == ''){
      Swal.fire(
        'Fields Incomplete!',
        'Enter amount paid by owner.',
        'warning'
      )
    }
    else if(paymentDetails.method == '' || paymentDetails.method == 0){
      Swal.fire(
        'Fields Incomplete!',
        'Select mode of payment.',
        'warning'
      )
    }
    else if(paymentDetails.amount > paymentDetails.total){
      Swal.fire(
        'Amount too big',
        'Amount paid is greater than total amount',
        'error'
      )
    }
    else{
      console.log("inside api call")
      axios.post(api+"/api/admin/payment/record/admin",data).then(response => {
        console.log("Response from api :: ",response);
        Swal.fire(
          'Success',
          'Payment Successfull.',
          'success'
        )
        setPaymentDetails({
          unitId: '',
          payDate: '',
          amount: '',
          method: '',
          note: '',
          balance: '',
          total: '',
          invoiceId: '',
          bank: ''
        })
        setLoading(false);
        navigate('/admin/payments')
      }).catch(err => {
        Swal.fire(
          'Failed',
          'Payment Failed!',
          'error'
        )
        setLoading(false);
        console.log(err);
      })
    }
    }

    console.log("unitDetials :: ",unitDetails);

useEffect(() => {
    
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);

    axios.get(api+"/api/admin/invoices/allinvoices").then(result => {
      setEffectInv(result.data.data)
    }).catch(err => {
      console.log("Failed to get all invoices !! ",err)
    })

    axios.get(api+'/api/admin/units/allUnits').then(response => {
      console.log(response)
      setUnitDetails(response.data.data)
      setLoading(false);
    }).catch(err => {
      if(axios.isCancel(err)){
        console.log("Successfully aborted !!")
      }
      else{
        console.log("error occured in api :: ",err);
        setUnitDetails({
          id: 0,
          unit_id: 0,
          owner_id: 0,
          title_id: 'No Unit found.',
          address: '',
          city: '',
          state: '',
          zipcode: '',
          status: ''
        })
        setLoading(false);
      }
    })

    return () => {
      source.cancel()
    }
},[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
        <div className="row">
        <div className="row">
              <div className="col-md-12">
                    <div className="template-demo text-end">
                    <Link to="/admin/payments"><button type="button" style={{borderRadius:'5px', marginRight:'15px'}} className="btn btn-outline-info">Back</button></Link>
                    </div>
            </div>
                  </div>
        <div className="col-lg-12 grid-margin">
              { loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <div className="card">
              <div className="card-body">
            <h4 className="card-title">Record Payment</h4>
            <div className="row">
        <div className="col-md-6">
        <form className="forms-sample">
                    <div className="form-group">
                      <label>Payor<code>*</code></label>
                      <select required onChange={handleUnit} className="form-control form-control-sm">
                        <option value="">-- Select Unit --</option>
                        {optionsData}
                      </select>
                    </div>
                    <div className="form-group">
                        <button type="button" onClick={handleMakeAccount} className="btn btn-outline-info" style={{borderRadius:'5px', marginRight:'5px'}}>Make payment on Account</button>
                        <button type="button" onClick={handleMakeInvoice} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Make payment on Invoice</button>
                    </div>

                    {makeInvoice ? <><div className="form-group">
                      <label>Invoice</label>
                      <select required onChange={handleInvoices} className="form-control form-control-sm">
                        <option value="">-- Select Invoice --</option>
                        {invoiceOptions}
                      </select>
                    </div>
                    <div className="form-group">
                    <label>Payment Date</label>
                    <input required onChange={handlePaydate} type="date" className="form-control form-control-sm" />
                    </div>
                    <div className="form-group">
                    <label>Method</label>
                    <select required onChange={handleMethod} className="form-control form-control-sm">
                    <option value="0">-- Select Payment Method -- </option>
                    <option value="5">Bank Transfer</option>
                    <option value="3">Cash</option>
                    <option value="4">Cheque</option>
                    <option value="1">Online</option>
                    </select>
                    </div>
                    </> : null}

                    {makeAccount ? <>
                        <div className="form-group">
                        <label>Deposit Bank</label>
                        <input type="text" onChange={handleBank} className="form-control form-control-sm" placeholder="Enter Deposit Bank" />
                    </div>
                    <div className="form-group">
                    <label>Payment Date</label>
                    <input required onChange={handlePaydate} type="date" className="form-control form-control-sm" />
                    </div>
                    <div className="form-group">
                    <label>Method</label>
                    <select required onChange={handleMethod} className="form-control form-control-sm">
                    <option value="0">-- Select Payment Method -- </option>
                    <option value="5">Bank Transfer</option>
                    <option value="3">Cash</option>
                    <option value="4">Cheque</option>
                    <option value="1">Online</option>
                    </select>
                    </div>

                    </> : null}

                  </form>
        </div>

        <div className="col-md-6">
        <form className="forms-sample">
                    <div className="form-group">
                      <label>Total Amount</label>
                      <input disabled required value={"$ "+paymentDetails.total} type="text" className="form-control form-control-sm" placeholder="$0.00" />
                    </div>
                    <div className="form-group">
                      <label>Amount Paid</label>
                      <input required onChange={handleAmount} type="number" min={1}  className="form-control form-control-sm" placeholder="$0.00" />
                    </div>

                    <div className="form-group">
                      <label>Note</label>
                      <input type="text" onChange={handleNote} className="form-control form-control-sm" placeholder="Note" />
                    </div>
                  </form>
        </div>
                    <div className="form-group">
                        <button style={{borderRadius: '5px'}} onClick={handleSubmit} className="btn btn-outline-info">Record Payment</button>
                    </div>
        </div>
        </div>
        </div> }
        </div>
        </div>
              </div>
        </Header>
        </div>
    )
}

export default RecordNewPayment;