import React, { useState, useEffect } from 'react';
import Header from '../../../Common/Header';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import api from '../../../../../utils/apiURL';

const ViewReceivableComponent = () => {
    let location = useLocation();
    let { state } = location;

    console.log("state in view invoice component :: ",state)

    let [show, setShow] = useState(false);
    let [unitDetails, setUnitDetails] = useState([]);
    let [pivotDetails, setPivotDetails] = useState([]);

    // let total_arr = Object.keys(state.charge).length && state.charge.map((val,i) => {
    //     return val.charge_amount
    // })

    // let ids = JSON.parse(state.invoice.charge_id);

    let details = [];

    let total_balance = pivotDetails.length && pivotDetails.map((val,ind) => {
        return unitDetails.length && unitDetails.map((itm,i) => {
            if(state.invoice.lot_id == val.id && itm.lot_id == val.id){
                if(state.invoice.id == itm.invoice_id){
                    return val.balance
                }
            }
        })
    })



    let tableData = unitDetails.length && unitDetails.map((val,ind) => {
        return pivotDetails.length && pivotDetails.map((itm,i) => {
            if(state.invoice.lot_id == val.id && itm.lot_id == val.id){
                if(state.invoice.id == itm.invoice_id){
                    return details.push({
                        invoiceTitle: state.invoice.title,
                        lotTitle: val.title,
                        balance: itm.balance
                    })
                    // return(
                    //     <tr key={ind}>
                    //         <td>{state.invoice.title}</td>
                    //         <td>{val.title}</td>
                    //         <td>{itm.balance}</td>
                    //     </tr>
                    // )
                }
                else{
                    return null;
                }
            }
            else{
                return null;
            }
        })
    })

    let balTotal = total_balance.length && _.sum(total_balance)

    // let charge_details = Object.keys(state.charge).length && state.charge.map((val,ind) => {
    //     return ids.map((itm,i) => {
    //         if(val.id == itm){
    //             return(
    //                 <tr key={i}>
    //                     <td>
    //                         {val.charge_title}
    //                     </td>
    //                     <td>
    //                         {val.charge_description}
    //                     </td>
    //                     <td>
    //                         ${val.charge_amount}
    //                     </td>
    //                     <td>
    //                         {val.category }
    //                     </td>
    //                     <td>
    //                         {(val.schedule == 1 || val.schedule == '1') ? "Monthly Charge" : ((val.schedule == 2 || val.schedule == '2') ? "Quaterly Charge" : ((val.schedule == 3 || val.schedule == '3') ? "Semi-Annual Charge" : ((val.schedule == 4 || val.schedule == '4') ? "Annual" : "One Time")))}
    //                     </td>
    //                     <td>
    //                         {(val.recurring == 1 || val.recurring == '1') ? "True" : "False"}
    //                     </td>
    //                     <td>
    //                         {(val.limit_recurring == 0 || val.limit_recurring == '0') ? 'Infinite' : val.limit_recurring }
    //                     </td>
    //                 </tr>
    //             )
    //         }
    //     })
    // })

    useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();

        axios.get(api+"/api/admin/invoices/all-unpaid").then(result => {
            setPivotDetails(result.data.data);
        }).catch(error => {
            console.log("error occured while getting pivot details :: ",error)
        })

        axios.get(api+"/api/admin/units/allUnits").then(response => {
            setUnitDetails(response.data.data)

        }).catch(err => {
            console.log("error occured :: ", err);
        })

        return () => {
            source.cancel()
          }
    },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Outstanding Invoice</h4>
                  <div className="col-md-12">

                      <p className="card-description">Unit with Outstanding invoice</p>
                      <div className="template-demo">
                          <div className="text-end">
                              <Link to="/admin/report/receiveable"><button type="button" style={{borderRadius:'5px', marginRight: '5px'}} className="btn btn-outline-info">Back</button></Link>
                          </div>
                          <br/>
                          {/* <h5>
                           Invoice Title
                        </h5>
                        <p className="card-description">{Object.keys(state.invoice).length ? state.invoice.title : "Unable to get invoice details"}</p>
                        <h5>
                            Lot
                        </h5>
                        <p className="card-description">{Object.keys(state.invoice).length ? ((state.invoice.lot_status == 1) ? "Developed" : "Un-Developed") : "Unable to get invoice details"}</p>

                        <h5>
                            Deposit Bank
                        </h5>
                        <p className="card-description">{ Object.keys(state.invoice).length ? state.invoice.deposite_bank : "Unable to get invoice details"}</p>
                        <h5>
                            Total Amount of Invoice
                        </h5>
                        <p className="card-description">
                           ${total_amount}
                        </p> */}
                        <div className="col-md-4">

                        <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Invoice Title
                          </th>
                          <th>
                            Lot Title
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {unitDetails.length ? <tr>
                            <td>{details[0].invoiceTitle}</td>
                            <td>{details[0].lotTitle}</td>
                            <td>{total_balance.length ? balTotal : "$0"}</td>
                        </tr> : <tr>
                            <td>No Data Available</td>
                            </tr>}
                        </tbody>
                        </table>
                        </div>

        </div>
        </div>

                      </div>
                    <br />
                  </div>
                  </div>
                  </div>
                  </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default ViewReceivableComponent;