import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import { Link } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';
import axios from 'axios';
import api from '../../../utils/apiURL';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'

const InvoicesComponent = () => {
  //--------------
  //STATE DECLARATION
  //--------------

  let [invoicesData, setInvoicesData] = useState([]);
  let [chargeData, setChargeData] = useState([]);
  let[loading, setLoading] = useState(false);
  let [pageNum, setPageNum] = useState(2)
  let [totalPages, setTotalPages] = useState(0);
  let [units, setUnits] = useState([])

  //--------------
  //STATE DECLARATION ENDS
  //--------------
  //--------------
  //FUNCTION DECLARATION
  //--------------

  let tabledata = invoicesData.length && invoicesData.map((itm, i) => {
    // let dueDate = itm.due_date.split('T')
    return(
      <tr key={i}>
        <td>{itm.invoice_title}</td>
        <td>{itm.lot_title}</td>
        <td>${itm.total}</td>
        <td>{(itm.paid == 1) ? "True" : "False"}</td>
        <td>
          <Link to="/admin/invoices/view" state={{invoice: itm, charge: chargeData}}>
            <button type="button" className="btn btn-outline-info" style={{borderRadius:'5px'}}>View</button>
          </Link>
        </td>
      </tr>
    )
  })

  let testTotalPage = Math.ceil(totalPages/10)

  let handleNext = () => {
    setLoading(true);

    if(pageNum > testTotalPage){
      Swal.fire(
        'No more records',
        'No more records found!',
        'info'
      )
    }
    else if(pageNum <= testTotalPage){
      axios.get(api+`/api/admin/invoices/units?page=${pageNum}`).then(response => {

        let test = response.data.data.map((val,id) => {
          return val
        })

        setInvoicesData((values) => ([...values, ...test]))
        setLoading(false);
        setPageNum(pageNum + 1)
      }).catch(error => {
        console.log("error :: ",error);
        setInvoicesData({
          id: 0,
          title: '',
          description: '',
          deposite_bank: '',
          total_amount: '',
          category: '',
          charge_ids: [],
          lot_status: ''
        })
        setLoading(false);
      })
    }
  }

  // console.log("invoice data :: ",invoicesData)
  // let test = invoicesData.length && invoicesData.map((val,i) => {
  //   console.log("test for charge ids :: ",JSON.parse(val.charge_id))
  // })
  // console.log("charge data :: ",chargeData)

  console.log("invoice data :: ",invoicesData)
  console.log("charge data :: ",chargeData)
  console.log("unit data :: ",units)

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    setLoading(true);


    axios.get(api+"/api/admin/invocies/count").then(response => {
      setTotalPages(response.data.data[0].total)
    }).catch(err => {
      setTotalPages(0)
      console.error("Failed to get total admin invoices pages",err)
    })

    axios.get(api+"/api/admin/units/allUnits").then(res => {
      setUnits(res.data.data)
    }).catch(err => {
      console.log("failed to get unit details :: ",err)
    })

    axios.get(api+`/api/admin/invoices/units?page=${1}`).then(response => {
      setInvoicesData(response.data.data)

      axios.get(api+"/api/admin/invoices/charges").then(result => {
        setChargeData(result.data.data)
      }).catch(err => {
        console.log("error :: ",err)
      })

      setLoading(false);
    }).catch(error => {
      console.log("error :: ",error);
      setLoading(false);
    })

    return () => {
      source.cancel()
    }

  },[])

    return(
        <div className="container-scroller">
        <Header>
        <div className="content-wrapper">
          <div className="row">
          <div className="col-lg-12 grid-margin">

              <div className="card">

              <div className="card-body">
                  <h4 className="card-title">All Invoices</h4>
                  <div className="col-md-12">
                    <div className="text-end">
                  <Link to="/admin/new-invoice">
                      <button style={{borderRadius:'5px'}} className="btn btn-outline-info">
                  <BsPlusLg style={{marginRight:'5px'}} />Create New Invoice    
                      </button>
                  </Link>
                    </div>
                  </div>
                  </div>

                  </div>
                  <br />
                  <div className="card">
                  {loading ? <div className="text-center" style={{padding:'20px'}}>
                  <Spinner animation="grow" style={{color:"#2dca98", width:'50px', height:'50px'}}/>
              </div> : <div className="card-body">
    <h4 className="card-title">Invoices Details</h4>
    <div className="col-md-12">

            <div className="template-demo">
        <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>

                          <th>
                            Invoice Title
                          </th>
                          {/* <th>
                            Total Amount
                          </th> */}
                          <th>
                            Lot Title
                          </th>
                          <th>
                            Total Amount
                          </th>
                          <th>
                            Paid
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoicesData.length ? <>{tabledata}</> : <tr>
                          <td>No Invoice Available</td>
                          </tr>}
                        {/* {tabledata} */}
                        </tbody>
                        </table>
                        </div>

        </div>
        <br />
      {(pageNum <= testTotalPage) ? <div className="text-center">
        <button onClick={handleNext} className="btn btn-outline-info" style={{borderRadius:'5px'}}>Load more</button>
      </div> : null}
            </div>
            </div>}


            </div>
            </div>
              </div>
              </div>
        </Header>
        </div>
    )
}

export default InvoicesComponent;